import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

const WeeklyWiseCOTReports = ({ selectedMarket, leftDivRef, rightDivRef, scrollFunction, setLoading, mockFetchedData, mockState }) => {
  const { t } = useTranslation()
  const { robustaWeeklyWiseCotReports, arabicaWeeklyWiseCotReports } = coffeeReportJsonLinks
  const { dateFormatBasedOnUser, checkForUserAccess } = commonMethods
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [fetchedData, setFetchedData] = useState([])
  const [hoveredRows, setHoveredRows] = useState({})

  // testing purpose

  useEffect(() => {
    if (mockState) {
      setFetchedData(mockFetchedData)
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const reportName = selectedMarket === 'Robusta' ? robustaWeeklyWiseCotReports : arabicaWeeklyWiseCotReports

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedMarket])

  useEffect(() => {
    const cleanUp = scrollFunction() || (() => {})

    return () => {
      cleanUp()
    }
  }, [])

  const handleMouseEnter = (tableIndex, rowIndex) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [tableIndex]: rowIndex
    }))
  }

  const handleMouseLeave = (tableIndex) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [tableIndex]: null
    }))
  }

  return (
    <div className="table-content" id="weekly-wise-content" data-testid="weekly-cot-reports-table">
      <div className="table-content-left" ref={leftDivRef} data-testid="left-table-content">
        {fetchedData?.map((ele, index) => (
          <table key={index}>
            <thead>
              <tr>
                <th data-testid="date">{dateFormatBasedOnUser(ele?.date)}</th>
                <th data-testid="contract">{ele?.contract}</th>
              </tr>
              <tr className="blank-row"></tr>
            </thead>

            <tbody style={checkForUserAccess(menuLock)}>
              {[
                { label: 'OPEN_INTEREST', key: 'openInterest' },
                { label: 'NET_CHANGE', key: 'netChange' },
                { label: 'PERCENTAGE_OPEN_INTEREST', key: 'percentageOpenInterest' },
                { label: 'TOTAL_TRADERS', key: 'totalTraders' }
              ].map((item, rowIndex) => (
                <tr key={item.label} onMouseEnter={() => handleMouseEnter(index, rowIndex)} onMouseLeave={() => handleMouseLeave(index)} className={hoveredRows[index] === rowIndex ? 'highlighted' : ''}>
                  <td data-testid="table-value">{t(item.label)}</td>
                  <td className="data-value">{ele[item.key]?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
      <div className="table-content-right" ref={rightDivRef} data-testid="table-right-content">
        {fetchedData?.map((ele, tableIndex) => (
          <div className="table-data" key={tableIndex}>
            {ele.details.map((data, dataIdx) => (
              <table key={dataIdx}>
                <thead>
                  <tr>
                    <th data-testid="data-label" colSpan={data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS' ? 7 : 6}>
                      {data?.label}
                    </th>
                  </tr>
                  <tr>
                    <th className="positive-label">{t('LONG')}</th>
                    <th className="negative-label">{t('SHORT')}</th>
                    <th>{t('TOTAL')}</th>
                    <th className="positive-label">{t('PERCENTAGE_LONG')}</th>
                    <th className="negative-label">{t('PERCENTAGE_SHORT')}</th>
                    <th>{t('NET')}</th>
                    {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <th className="gold-label">{t('SPREADING')}</th>}
                  </tr>
                  <tr className="blank-row"></tr>
                </thead>
                <tbody style={checkForUserAccess(menuLock)}>
                  {data?.data?.map((item, rowIndex) => (
                    <tr key={rowIndex} onMouseEnter={() => handleMouseEnter(tableIndex, rowIndex)} onMouseLeave={() => handleMouseLeave(tableIndex)} className={hoveredRows[tableIndex] === rowIndex ? 'highlighted' : ''}>
                      <td className="positive-label" data-testid="long-value">
                        {item?.long.toLocaleString()}
                      </td>
                      <td className="negative-label" data-testid="short-value">
                        {item?.short.toLocaleString()}
                      </td>
                      <td data-testid="total-value">{item?.total.toLocaleString()}</td>
                      <td className="positive-label" data-testid="percent-long-value">
                        {item?.percentLong}
                      </td>
                      <td className="negative-label" data-testid="percent-short-value">
                        {item?.percentShort}
                      </td>
                      <td data-testid="net-value">{item?.net.toLocaleString()}</td>
                      {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <td className="gold-label">{item?.spreading.toLocaleString()}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default WeeklyWiseCOTReports
