import { updateUserDetails, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'

function razorpay({ gstInput, setLoading, showError, setIsCustomLoading }) {
  const { userName, organistationName, organistationAddress, gstNumber, email } = gstInput
  const userDetails = getLocalUserDetails()
  const { sentryLog } = commonMethods

  const razorpayCreateOrder = async (ele) => {
    const postData = {
      order: {
        userId: userDetails.id,
        billingAddress: 'NA',
        shippingAddress: 'NA',
        states: userDetails.states,
        city: userDetails.city,
        totalQuantity: 1,
        totalAmount: ele.basePrice,
        paymentDate: '2022-12-24T04:51:11.228Z',
        orderDate: '2022-12-24T04:51:11.228Z',
        payOrderId: 'NA',
        subscriptionId: ele.name,
        facilitatorAccessToken: 'NA',
        paymentId: ele.paymentId,
        paymentSource: ele.paymentName,
        paymentStatus: 'Pending',
        orderSatus: 1
      },
      orderDetails: [
        {
          idOrderDetail: 0,
          orderId: 0,
          planId: 'NA',
          serviceName: 'NA',
          subscriptionType: ele.paymentName,
          quantity: 0,
          rate: 0
        }
      ]
    }

    await apiAdapterCoffeeWeb
      .createOrder(postData)
      .then((response) => {
        if (response.data.isSuccess === true) {
          InitateRazorpayPayment(ele, response?.data?.returnLst)
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
      })
      .catch(() => {
        showError({
          title: 'Error !',
          message: `<p>${'An unknown error has been occurred while processing your request.'}</p><p>${'Please try again in sometime.'}</p>`,
          buttonLabel: 'OK'
        })
      })
  }

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement('script')

      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })

  const InitateRazorpayPayment = async (ele, orderData) => {
    const {
      order: { subscriptionId, paymentSource, idOrder }
    } = orderData
    const amount = userDetails.countryId === 102 ? ele.csgstTaxableAmount : ele.basePrice
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('you are in offline filed to load Razorpay sdk')

      return
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency: 'INR',
      amount: amount * 100,
      handler(response) {
        if (response.razorpay_payment_id) {
          OrderUpdate(response, orderData, amount)
        }
      },
      prefill: {
        name: `${userDetails.firstName}`,
        email: `${userDetails?.email}`,
        contact: `${userDetails.phone}`
      },
      theme: {
        color: '#50b3f6'
      }
    }

    const paymentObject = new window.Razorpay(options)

    paymentObject.on('payment.failed', (response) => {
      sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, subscriptionId, paymentSource, paymentId: response.error.metadata.payment_id, idOrder, description: response.error.description, errorIn: 'Razorpay Transaction Failed' })
    })
    paymentObject.open()
  }

  const OrderUpdate = async (razorPayPaymentId, orderData, amount) => {
    const {
      order: { subscriptionId, paymentSource, idOrder }
    } = orderData
    const getOrDefault = (value, defaultValue) => value || defaultValue
    const updateOrder = {
      updateOrderDTO: {
        idOrder,
        payOrderId: razorPayPaymentId.razorpay_payment_id,
        subscriptionId,
        facilitatorAccessToken: 'string',
        paymentSource,
        paymentStatus: 'Success',
        orderSatus: 2,
        amount
      },
      userGSTDetails: {
        idUserGstdetails: 0,
        userId: 0,
        organistationName: getOrDefault(organistationName || 'NA'),
        organistationAddress: getOrDefault(organistationAddress || 'NA'),
        stateId: 0,
        gstno: getOrDefault(gstNumber || 'NA'),
        entryDate: '2023-05-25T12:19:02.839Z',
        lastUsed: false,
        lastUsedDtm: '2023-05-25T12:19:02.839Z',
        userName: getOrDefault(userName || 'NA'),
        email: getOrDefault(email || 'NA')
      }
    }

    setIsCustomLoading('WE_ARE_UPGRADING_KINDLY_WAIT')

    await apiAdapterCoffeeWeb
      .UpdateOrder(updateOrder)
      .then((response) => {
        if (response.data.statusCode === 201) {
          setTimeout(async () => {
            const userSubscriptionUpdated = await updateUserDetails()

            if (userSubscriptionUpdated) {
              window.location.pathname = 'dashboard/success'
            } else {
              setIsCustomLoading('')
            }
          }, 3000)
        }
      })
      .catch(() => {
        showError({
          title: 'Error !',
          message: `<p>${'An unknown error has been occurred while processing your request.'}</p><p>${'Please try again in sometime.'}</p>`,
          buttonLabel: 'OK'
        })
        setIsCustomLoading('')
        sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, subscriptionId, paymentSource, idOrder, errorIn: 'Razorpay Order Update', paymentId: razorPayPaymentId.razorpay_payment_id, description: 'Order Update Failed for Razorpay' })
      })
  }

  return {
    razorpayCreateOrder
  }
}

export default razorpay
