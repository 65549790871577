import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const AccordionPopUp = ({ setLoading, mockCoffeePriceHistoryData, selectedQualityForHistoryData, setSelectedQualityForHistoryData, accordionPopUpTest }) => {
  const { t } = useTranslation()
  const toast = useRef(null)

  const [countryId, countryName, cropYear, qualityName, forexType, flagUrl] = selectedQualityForHistoryData

  const [coffeePriceHistoryData, setCoffeePriceHistoryData] = useState([])
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false)

  useEffect(() => {
    if (selectedQualityForHistoryData?.length) {
      fetchHistoryData()
      setIsDialogModalOpen(false)
    }
  }, [selectedQualityForHistoryData])

  useEffect(() => {
    if (accordionPopUpTest) {
      setIsDialogModalOpen(true)
      setCoffeePriceHistoryData(mockCoffeePriceHistoryData)
    }
  }, [])

  const fetchHistoryData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getGlobalCoffeePriceHistoryData({ idCountry: countryId, countryName, cropYear, qualityName })

      if (response.data.returnLst?.length > 0) {
        setCoffeePriceHistoryData(response.data.returnLst[0].data)
        setIsDialogModalOpen(true)
      } else {
        showErrorToast('NO_DATA_FOUND')
        setCoffeePriceHistoryData([])
      }
    } catch (error) {
      showErrorToast('FAILED_TO_GET_DATA')
      setCoffeePriceHistoryData([])
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setSelectedQualityForHistoryData([])
    setIsDialogModalOpen(false)
  }

  const showErrorToast = (message) => toast.current?.show({ severity: 'error', summary: 'Error', detail: t(message), life: 3000 })

  const globalRawCoffeesModalContent = () => (
    <div className="global-coffee-price-popup-modal" data-testid="global-raw-coffee-price-modal-popup">
      <div className="quality-name" data-testid="quality-name">
        {qualityName}
      </div>
      <div className="cropyear" data-testid="crop-year">
        <div className="cropyear_flag" data-testid="crop-year-flag">
          {t('COUNTRY')} : <img src={flagUrl} alt="Flag" className="flag-icon" data-testid="flag-icon" />
          {countryName}
        </div>
        <div data-testid="forex-type">
          {t('FOREX_TYPE')} : {forexType}
        </div>
      </div>
      <div className="global-raw-coffee-price-preview-table-wrapper" data-testid="global-raw-coffee-price-table-wrapper">
        <table className="global-raw-coffee-price-preview-table" data-testid="global-raw-coffee-price-table-preview">
          <thead className="global-raw-coffee-price-preview-table-header" data-testid="global-raw-coffee-price-preview-header">
            <tr>
              <th>{t('DATE')}</th>
              <th>{t('CROP_YEAR')}</th>
              <th>{t('TERMINAL')}</th>
              <th>{t('TERMINAL_LEVEL')}</th>
              <th colSpan={2}>{t('PRICES')}</th>
              <th>{t('FOREX_VALUE')}</th>
            </tr>
          </thead>
          {coffeePriceHistoryData.length > 0 ? (
            <tbody className="global-raw-coffee-price-preview-table-body" data-testid="global-raw-coffee-price-table-body">
              {coffeePriceHistoryData.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, forexValue }, index) => (
                <tr key={index}>
                  <td data-testid="uploadedDtm-value">{uploadedDtm}</td>
                  <td data-testid="cropYear-value">{cropYear}</td>
                  <td data-testid="terminal-value">{terminal}</td>
                  <td data-testid="terminal-level-value">{terminalLevel}</td>
                  <td data-testid="range-start-value">{rangeStart}</td>
                  <td data-testid="range-end-value">{rangeEnd}</td>
                  <td data-testid="forex-value">{forexValue}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className="global-raw-coffee-price-preview-table-body">
              <tr>
                <td className="no-data-found-td" colSpan={7}>
                  {t('NO_DATA_FOUND')}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  )

  return (
    <div className="global-raw-coffee-price-accordian-popup" data-testid="global-raw-coffee-price-accordion-popup">
      <DialogUIModal title={t('PREVIOUS_DATA')} titlePosition="center" size="lg" showModal={isDialogModalOpen} bodyContent={globalRawCoffeesModalContent} handleClose={() => handleCloseModal()} testId="accordion-popup" />
      {coffeePriceHistoryData.length === 0 && <Toast ref={toast} />}
    </div>
  )
}

export default AccordionPopUp
