import './styles.scss'
import React, { memo, useEffect, useReducer, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { createChart, CrosshairMode } from 'lightweight-charts'
import { useTranslation } from 'react-i18next'
import { GridOffIcon, GridOnIcon, WindowMinimizeIcon, WindowMaximizeIcon } from 'Assets/Icons'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'

/* eslint-disable react/display-name */
const ChartComponent = memo(({ terminalData, contractName, _52WeekHighValue, _52WeekLowValue, todayLiveTerminalData, WeekHighForCandleStick, WeekLowForCandleStick, isMarketOpenToday }) => {
  const decimalType = terminalData[0].idMarket === 1 ? 0 : 2
  const userDetails = getLocalUserDetails()
  const { dateFormatBasedOnUser } = commonMethods

  const initialState = {
    dayBasedCandle: [],
    monthBasedCandle: [],
    dayBasedVolume: [],
    monthBasedVolume: [],
    highLowMarkers: []
  }

  const reducer = (state, action) => {
    const newState = {}

    /* eslint-disable no-restricted-syntax */
    for (const key in action.payload) {
      /* eslint-disable no-prototype-builtins */
      if (action.payload.hasOwnProperty(key)) {
        newState[key] = action.payload[key]
      }
    }

    return { ...state, ...newState }
  }

  const { t } = useTranslation()
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const chartContainerRef = useRef()
  const chart = useRef(null)
  const series = useRef(null)
  const tooltipRef = useRef()
  const volumeSeries = useRef(null)
  const dayBasedCandleRef = useRef(state.dayBasedCandle)
  const monthBasedCandleRef = useRef(state.monthBasedCandle)
  const dayBasedVolumeRef = useRef(state.dayBasedVolume)
  const monthBasedVolumeRef = useRef(state.monthBasedVolume)
  const highLowMarkersRef = useRef(state.highLowMarkers)

  const [isDarkMode, setIsDarkMode] = useState(false)
  const [showGrid, setShowGrid] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [chartType, setChartType] = useState('Candlestick')
  const [durationType, setDurationType] = useState('1D')
  const [pageLoad, setPageLoad] = useState(false)
  const timeFrame = ['1D']

  const candleStyle = {
    priceFormat: {
      type: 'price',
      precision: 0,
      minMove: 1
    },
    upColor: '#4CAF50',
    downColor: '#F44336',
    borderUpColor: '#4CAF50',
    borderDownColor: '#F44336',
    wickUpColor: '#4CAF50',
    wickDownColor: '#F44336'
  }

  const lineStyle = {
    color: '#2196f3',
    priceFormat: {
      type: 'price',
      precision: 0,
      minMove: 1
    }
  }

  const priceScaleOption = {
    autoScale: false,
    scaleMargins: {
      top: 0.1,
      bottom: 0.2
    }
  }

  useEffect(() => {
    const getOneMonthData = (terminalData) => {
      const groupByTimeFrame = (data) => {
        const groupedData = {}

        data.forEach((entry) => {
          const date = new Date(entry.time * 1000)
          const key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`

          if (!groupedData[key]) {
            groupedData[key] = []
          }
          groupedData[key].push(entry)
        })

        return groupedData
      }

      const groupedData = groupByTimeFrame(terminalData)

      const createCandleList = (groupedData) =>
        Object.values(groupedData)?.map((group) => ({
          open: group[0].open,
          time: group[0].time,
          close: group[0].close,
          high: group[0].high,
          low: group[0].low,
          volume: group[0].volume,
          value: group[0].close
        }))

      return createCandleList(groupedData)
    }

    const monthBasedCandleData = getOneMonthData(terminalData)
    const dayBasedVolume = terminalData.map((ele) => ({
      time: ele.time,
      value: ele.volume,
      color: ele.close > ele.open ? '#4CAF5055' : '#F4433655'
    }))

    const monthBasedVolumeData = monthBasedCandleData.map((ele) => ({
      time: ele.time,
      value: ele.volume,
      color: ele.close > ele.open ? '#4CAF5055' : '#F4433655'
    }))

    const getMarkers = () => {
      const markers = []

      for (let i = 0; i < terminalData?.length; i++) {
        if (terminalData?.[i]?.is52WeekHigh) {
          const highMarker = {
            time: terminalData?.[i]?.time,
            position: 'aboveBar',
            color: '#4CAF50',
            shape: 'arrowUp',
            text: 'H'
          }

          markers.push(highMarker)
        }
        if (terminalData?.[i]?.is52WeekLow) {
          const lowMarker = {
            time: terminalData?.[i]?.time,
            position: 'belowBar',
            color: '#F44336',
            shape: 'arrowDown',
            text: 'L'
          }

          markers.push(lowMarker)
        }
      }

      return markers
    }

    const highLowMarkers = getMarkers()

    dayBasedCandleRef.current = terminalData
    monthBasedCandleRef.current = monthBasedCandleData
    dayBasedVolumeRef.current = dayBasedVolume
    monthBasedVolumeRef.current = monthBasedVolumeData
    highLowMarkersRef.current = highLowMarkers
    dispatch({ payload: { dayBasedCandle: terminalData, monthBasedCandle: monthBasedCandleData, dayBasedVolume, monthBasedVolume: monthBasedVolumeData, highLowMarkers } })
    setPageLoad(true)
  }, [])

  const initializeChart = () => {
    if (!chartContainerRef.current) return

    const chartOptions = {
      width: chartContainerRef.current.clientWidth,
      height: isFullscreen ? window.innerHeight : chartContainerRef.current.clientHeight,
      layout: {
        background: { color: isDarkMode ? '#253248' : '#ffffff' },
        textColor: isDarkMode ? '#DDD' : '#000'
      },
      grid: {
        /* eslint-disable-next-line no-nested-ternary */
        vertLines: { color: showGrid ? (isDarkMode ? '#1a2538' : '#e1ecf2') : '#ffffff00' },
        /* eslint-disable-next-line no-nested-ternary */
        horzLines: { color: showGrid ? (isDarkMode ? '#1a2538' : '#e1ecf2') : '#ffffff00' }
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: { color: '#75869655' },
        horzLine: { color: '#75869655' }
      },
      timeScale: { timeVisible: false, secondsVisible: false },
      priceScale: { borderColor: '#cccccc' },
      handleScale: { axisPressedMouseMove: true }
    }

    chart.current = createChart(chartContainerRef.current, chartOptions)
    series.current = chart.current.addCandlestickSeries(candleStyle)
    // series.current.priceScale().applyOptions(priceScaleOption)
    series.current.setData(dayBasedCandleRef?.current)
    volumeSeries.current = chart.current.addHistogramSeries({
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: ''
    })
    volumeSeries.current.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0
      }
    })

    volumeSeries.current.setData(dayBasedVolumeRef?.current)
    // chart.current.timeScale().fitContent();
    chart.current.timeScale().scrollToPosition(5)
    const toolTip = tooltipRef.current

    series.current.setMarkers(highLowMarkersRef?.current)
    chart.current.subscribeCrosshairMove((param) => {
      if (!param || !param.time) {
        toolTip.style.display = 'none'

        return
      }

      const { point } = param
      let price = dayBasedCandleRef?.current.find((item) => item.time === param.time)

      if (!price) {
        toolTip.style.display = 'none'
        price = todayLiveTerminalData
      }

      const dateStr = new Date(param.time * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).toUpperCase()
      // eslint-disable-next-line prefer-const
      let [day, month, year] = dateStr.split(' ')

      if (month === 'SEPT') {
        month = 'SEP'
      }

      const formattedDate = `${day}-${month}-${year}`
      /* eslint-disable-next-line no-nested-ternary */
      const highColor = Number(price?.high) > Number(price?.preClose) ? 'high-value-green' : Number(price?.high) < Number(price?.preClose) ? 'low-value-red' : ''
      /* eslint-disable-next-line no-nested-ternary */
      const lowColor = Number(price?.low) > Number(price?.preClose) ? 'high-value-green' : Number(price?.low) < Number(price?.preClose) ? 'low-value-red' : ''

      toolTip.innerHTML = `
        <div class="tooltip-content">
          <div class="date-format"><strong></strong> ${dateFormatBasedOnUser(formattedDate).toUpperCase()}</div>
          <div class="tooltip-info ${!userDetails && 'tooltip-filter-bg'}">
            <div class="tooltip-item"><span class="labeling">Open</span> <span>:</span> <span id="value-field">${price?.open?.toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">High</span> <span>:</span> <span class="${highColor}" id="value-field">${price?.high?.toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">Low</span> <span>:</span> <span class="${lowColor}" id="value-field">${price?.low?.toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">${price?.todayPrice ? 'Last' : 'Close'}</span> <span>:</span> <span id="value-field">${price?.close?.toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">Pre Close</span> <span>:</span> <span id="value-field">${parseFloat(price?.preClose).toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">Net Chg</span> <span>:</span> <span id="value-field" class="${price?.netChgTextColor}">${price?.netChg >= 0 ? '+' : ''}${parseFloat(price?.netChg).toFixed(decimalType)}</span></div>
            <div class="tooltip-item"><span class="labeling">Volume</span> <span>:</span> <span id="value-field">${price?.volume}</span></div>
            <div class="tooltip-item"><span class="labeling">Open Int</span> <span>:</span> <span id="value-field">${price?.openInterest?.toFixed(0)}</span></div>
            ${price?.is52WeekHigh ? `<div class="tooltip-item"><span class="labeling">52 Weeks High</span> <span>:</span> <span id="value-field" class="high-value-green">${price?.high?.toFixed(decimalType)}</span></div>` : ''}
            ${price?.is52WeekLow ? `<div class="tooltip-item"><span class="labeling">52 Weeks Low</span> <span>:</span> <span id="value-field" class="low-value-red">${price?.low?.toFixed(decimalType)}</span></div>` : ''}
          </div>
          ${!userDetails ? `<div class="create-account-message"><div class="message">${t('TO_ACCESS_MORE_FEATURE_CREATE_ACCOUNT')}</div></div>` : ''}
        </div>
        `

      toolTip.style.display = 'block'
      const chartRect = chartContainerRef.current.getBoundingClientRect()
      const toolTipWidth = toolTip.getBoundingClientRect().width
      const toolTipHeight = toolTip.getBoundingClientRect().height

      toolTip.style.left = `${Math.max(0, Math.min(chartRect.width - toolTipWidth, point.x - 270))}px`
      toolTip.style.top = `${Math.max(0, Math.min(chartRect.height - toolTipHeight, point.y - 140))}px`
    })
  }

  useEffect(() => {
    let timer

    clearTimeout(timer)
    if (isMarketOpenToday) {
      timer = setTimeout(() => {
        const { time, volume, close, open } = todayLiveTerminalData

        const liveVolumeCandle = {
          time,
          value: volume,
          color: close > open ? '#4CAF5055' : '#F4433655'
        }

        const update = (existingData, currentData) => {
          const updatedList = [...existingData]

          const index = existingData?.findIndex((ele) => ele.time === currentData.time)

          if (index !== -1) {
            updatedList[index] = currentData
          } else {
            updatedList.push(currentData)
          }

          return updatedList
        }

        const modifiedLiveCandleData = update(dayBasedCandleRef.current, todayLiveTerminalData)
        const modifiedLiveVolumeData = update(dayBasedVolumeRef.current, liveVolumeCandle)

        dayBasedCandleRef.current = modifiedLiveCandleData
        dayBasedVolumeRef.current = modifiedLiveVolumeData
        dispatch({ payload: { dayBasedCandle: modifiedLiveCandleData, dayBasedVolume: modifiedLiveVolumeData } })
        series.current.setData(modifiedLiveCandleData)
        volumeSeries.current.setData(modifiedLiveVolumeData)
      }, 200)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [todayLiveTerminalData])

  useEffect(() => {
    if (chart.current) {
      chart.current.applyOptions({
        layout: {
          background: { color: isDarkMode ? '#253248' : '#ffffff' },
          textColor: isDarkMode ? '#DDD' : '#000'
        },
        grid: {
          /* eslint-disable-next-line no-nested-ternary */
          vertLines: { color: showGrid ? (isDarkMode ? '#1a2538' : '#e1ecf2') : '#ffffff00' },
          /* eslint-disable-next-line no-nested-ternary */
          horzLines: { color: showGrid ? (isDarkMode ? '#1a2538' : '#e1ecf2') : '#ffffff00' }
        }
      })
    }
  }, [isDarkMode, showGrid])

  useEffect(() => {
    if (pageLoad) {
      initializeChart()

      const handleResize = () => {
        if (chartContainerRef.current && chart.current) {
          chart.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: isFullscreen ? window.innerHeight : 500
          })
        }
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        if (chart.current) {
          chart.current.remove()
          chart.current = null
          series.current = null
        }
      }
    }
  }, [isFullscreen, pageLoad])

  const handleTimeFrameChange = (type) => {
    setDurationType(type)
    if (type === '1M') {
      series.current.setData(monthBasedCandleRef?.current)
      volumeSeries.current.setData(monthBasedVolumeRef?.current)
      chart.current.timeScale().fitContent()
    } else if (type === '1D') {
      series.current.setData(dayBasedCandleRef?.current)
      volumeSeries.current.setData(dayBasedVolumeRef?.current)
      chart.current.timeScale().resetTimeScale()
      chart.current.timeScale().scrollToPosition(5)
    }
  }

  const handleSelecteChartType = (e) => {
    setChartType(e.target.value)
    chart.current.removeSeries(series.current)
    if (e.target.value === 'Candlestick') {
      series.current = chart.current.addCandlestickSeries(candleStyle)
      series.current.setData(durationType === '1D' ? dayBasedCandleRef?.current : monthBasedCandleRef?.current)
    } else {
      series.current = chart.current.addLineSeries(lineStyle)
      series.current.setData(durationType === '1D' ? dayBasedCandleRef?.current : monthBasedCandleRef?.current)
    }
    // series.current.priceScale().applyOptions(priceScaleOption)
    series.current.setMarkers(highLowMarkersRef?.current)
  }

  const toggleView = () => {
    if (!userDetails) {
      history.push(ROUTE_STRINGS.login)
    } else {
      setIsFullscreen(!isFullscreen)
    }
  }

  return (
    <div>
      <div className={`termial-difference-candle-chart-wrapper ${isFullscreen ? 'fullscreen' : ''}`} data-testid="terminal-difference-candle-chart-wrapper">
        <div id="terminalDifferenceCandleChart" ref={chartContainerRef} style={{ position: 'relative' }} data-testid="terminal-difference-candle-chart" />
        <div id="tooltip" className={`tooltips ${isDarkMode ? 'dark-theme-tooltip' : 'light-theme-tooltip'}`} ref={tooltipRef} data-testid="dark-light-mode" />
        <div className="candlestick-body-wrapper" data-testid="candle-stick-body-wrapper">
          <div className="candlestick-options" data-testid="candle-stick-options">
            <div className="date-selector" data-testid="date-selector">
              {timeFrame.map((ele, index) => (
                <button data-testid="button-time-frame-change" key={index} className={ele === durationType ? 'active-nav' : ''} onClick={ele !== durationType ? () => handleTimeFrameChange(ele) : null}>
                  {ele}
                </button>
              ))}
            </div>
            <div className="grid-on-off-container" data-testid="grid-on-off-icon-container" onClick={() => setShowGrid(!showGrid)}>
              {showGrid ? <GridOnIcon data-testid="grid-on-icon" /> : <GridOffIcon />}
            </div>
            <div className="darkmode-toggle-button" onClick={() => setIsDarkMode(!isDarkMode)} data-testid="dark-mode-toggle-button">
              {isDarkMode ? <span className="pi pi-moon" /> : <span className="pi pi-sun" data-testid="dark-mode-icon" />}
            </div>
            <div className="fullscreen-toggle" onClick={() => toggleView()} data-testid="fullscreen-toggle">
              {isFullscreen ? <img className="window-minimize-icon" src={WindowMinimizeIcon} alt="WindowMinimizeIcon" /> : <img className="window-maximize-icon" src={WindowMaximizeIcon} alt="WindowMinimizeIcon" data-testid="window-maximize-icon" />}
            </div>
            <div className="graph-type-select-option" data-testid="graph-type-selection-options">
              <select value={chartType} onChange={(e) => handleSelecteChartType(e)} data-testid="select-chart-type">
                <option value="Candlestick" data-testid="candle-stick-option">
                  Candlestick
                </option>
                <option value="Line" data-testid="line-option">
                  Line
                </option>
              </select>
            </div>
          </div>
          <div className={`contract-name ${isDarkMode ? 'dark-theme' : 'light-theme'}`} data-testid="contract-name-light-dark-theme">
            {contractName}
          </div>
          <div className="week-high-low-wrapper" data-testid="week-high-low-wrapper">
            <WeekHighForCandleStick />
            <WeekLowForCandleStick />
          </div>
        </div>
      </div>
    </div>
  )
})

export default ChartComponent
