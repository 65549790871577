import './styles.scss'
import React, { useEffect, useState } from 'react'
import { STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { useTranslation } from 'react-i18next'
import Package from '../../../../package.json'

const FooterDashboard = ({ mockScrollDirection }) => {
  const [scrollDirection, setScrollDirection] = useState('up')
  const { t } = useTranslation()

  // testing purpose

  useEffect(() => {
    setScrollDirection(mockScrollDirection)
  }, [])

  useEffect(() => {
    let prevScrollY = window.scrollY
    const handleScroll = () => {
      const windowWidth = window.innerWidth
      const currentScrollY = window.scrollY

      if (windowWidth < 767) {
        if (prevScrollY > currentScrollY) {
          if (currentScrollY < prevScrollY - 100) setScrollDirection('up')
          prevScrollY = currentScrollY
        } else {
          setScrollDirection('down')
          prevScrollY = currentScrollY
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={`footer${scrollDirection === 'down' ? ' slide-down' : ' down'}`}>
      <div className="container text-center" data-testid="scroll-direction">
        <span>
          {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>
        </span>
        <span className="terms_and_condition">
          <span>{t('ALL_RIGHTS_RESERVED')}</span>
          <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer" data-testid="terms-and-condition">
            {t('TERMS_CONDITIONS')}
          </a>
          {t('AND')}
          <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer" data-testid="privacy-policy">
            {t('PRIVACY_POLICY')}
          </a>
        </span>
      </div>
      <div className="version-number" data-testid="package-version">
        {Package.version}
      </div>
    </div>
  )
}

export default FooterDashboard
