import './styles.scss'
import React, { memo } from 'react'
import { Dialog } from 'primereact/dialog'
import { devEnvLogo } from 'Assets/Icons'
import { coffeewebLogo } from 'Utils/Constants'

/* eslint-disable react/display-name */
const DialogUIModal = memo(({ showModal, title, bodyContent, footerContent, handleClose, size, showLogo, titlePosition, testId }) => {
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production'

  const stylings = () => {
    /* eslint-disable no-else-return */
    if (size === 'lg') {
      return { width: '85%', minHeight: '400px' }
    } else if (size === 'md') {
      return { width: '780px', height: '520px' }
    } else {
      return { width: '450px', height: 'auto' }
    }
  }
  const getDialogModalClassName = (showLogo) => {
    if (showLogo) {
      return 'custom-header-styling-for-modal dialog-ui-modal'
    } else {
      return `dialog-ui-modal ${titlePosition}`
    }
  }

  const dialogModalClassName = getDialogModalClassName(showLogo)

  const header = () => (
    <>
      {showLogo ? (
        <div className="custom_header_logo_wrapper" data-testid="custom-header-logo-wrapper">
          {<img src={isProduction ? coffeewebLogo.transparentLogo : devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogoForModal" data-testid="coffeeWeb-dev-logo" />}
        </div>
      ) : (
        <div>{title}</div>
      )}
      {title && (
        <div className="close-button" data-testid="close-button">
          <i className="pi pi-times-circle times-circle-icon" onClick={() => handleClose()} data-testid="close-icon" />
        </div>
      )}
    </>
  )

  return (
    <Dialog visible={showModal} header={header()} footer={footerContent} onHide={() => handleClose()} className={dialogModalClassName} style={stylings()} closable={false} focusOnShow={false} data-testid={testId}>
      {bodyContent()}
    </Dialog>
  )
})

export default DialogUIModal
