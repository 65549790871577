import './styles.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore'
import ReactHtmlParser from 'react-html-parser'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { Skeleton } from 'primereact/skeleton'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { Toolbar } from 'primereact/toolbar'

import { useErrorBoundary } from 'react-error-boundary'
import { db } from 'firebase.js'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeewebLogo, ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebStorageKeys, updateUserDetails, getLocalUserDetails, getLocalLanguageIdForNews, setLocalWithSubscriptionNewsId, setLocalWithLatestNewsReadNotification, setLocalWithLanguageIdForNews } from 'Utils/LocalStorage_Handler'
import { UnFilledHeartIcon, FilledHeartIcon, devEnvLogo, LockIcon } from 'Assets/Icons'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { NoData } from 'Assets/Images'
import DropdownComponent from 'Components/DropdownComponent'
import { commonMethods } from 'Utils/commonMethods'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import NewsReadMore from './Components/NewsReadMore'

function CoffeeNewsFeeds({ ...props }) {
  const { mockData, popupProps, mockFirebaseLatestNewsId, setmockFirebase } = props
  const history = useHistory()
  const { t } = useTranslation()
  const { showBoundary } = useErrorBoundary()
  const params = new URLSearchParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { isHidden } = commonMethods.useScrollHandler()
  const { handleSearchInput, handleGoToTop, dateFormatBasedOnUser } = commonMethods
  const { setLoading } = useContext(CoffeeWebContext)

  localStorage.removeItem(coffeewebStorageKeys.sharednewsid)

  const userDetails = getLocalUserDetails()
  const userIdLanguage = getLocalLanguageIdForNews()
  const pageSize = 4
  const videoRefs = useRef([])

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [filteredCoffeeNewsData, setFilteredCoffeeNewsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [skeletonArray, setSkeletonArray] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const [dateForTheDay, setDateForTheDay] = useState([])
  const [newsId, setNewsId] = useState('')
  const [active, setActive] = useState(true)
  const [defaultNewsLanguage, setDefaultNewsLanguage] = useState({})
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [showSearchPopup, setShowSearchPopup] = useState(false)
  const [showSearchPopupForBack, setShowSearchPopupForBack] = useState(false)
  const [scrollPositionForData, setScrollPositionForData] = useState(0)
  const [stickyDateList, setStickyDateList] = useState([])
  const [showStickyDate, setShowStickyDate] = useState(null)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [newsFeedStatus, setNewsFeedStatus] = useState({
    noNewsFeedImage: false,
    errorMessage: ''
  })
  const [onRemoval, setOnRemoval] = useState(false)
  const [listNewsLoaded, setListNewsLoaded] = useState(false)
  const [getLanguage, setLanguage] = useState([])
  const [languageItem, setLanguageItem] = useState(null)
  const [errorState, setErrorState] = useState(false)
  const { noNewsFeedImage, errorMessage } = newsFeedStatus
  const { id: userId, countryId } = userDetails ?? {}

  const [favoriteFromReadMore, setFavoriteFromReadMore] = useState({
    newsId: 0,
    favoriteIdReadMore: 0
  })

  const init = () => {
    setSkeletonLoading(true)
    const array = Array.from({ length: pageSize }, () => 1)

    setSkeletonArray(array)
    updateUser()

    const handleScroll = () => {
      setScrollPositionForData(window.scrollY || document.documentElement.scrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    let mounted = false

    if (userDetails?.phone || userDetails?.email) {
      const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        if (mounted) {
          const firebaseResponse = doc.data()
          const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((number) => parseInt(number.trim(), 10))
          const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((number) => parseInt(number.trim(), 10))

          if (subscriptionList?.includes(userDetails?.sub?.subType) && countryList.includes(countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === userIdLanguage) {
            setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
          }
        } else {
          mounted = true
        }
      })

      return () => {
        unSub()
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    if (skeletonLoading) {
      setLoading(false)
    }
  }, [skeletonLoading])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      let found = false

      for (let i = 0; i < coffeeNewsData?.length; i++) {
        if (coffeeNewsData[i].id === firebaseLatestNewsId) {
          found = true
        }
      }
      if (!found) {
        if (active) {
          setTimeout(() => {
            getNewsAndMediaById()
          }, 5000)
        }
      }
    }
  }, [firebaseLatestNewsId])

  useEffect(() => {
    commonMethods.handleStickyDates(scrollPositionForData, setShowStickyDate, setStickyDateList)
  }, [scrollPositionForData, setShowStickyDate, setStickyDateList])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNewsFeedStatus((previousState) => ({ ...previousState, errorMessage: '' }))
    }, 5000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [errorMessage])

  useEffect(() => {
    const selectedNewsId = searchParams.get('newsId')
    const newsTypeQueryParam = searchParams.get('newsTab')

    let cleanUp = () => {}

    document.querySelector('body').style.overflow = selectedNewsId ? 'hidden' : 'auto'

    if (!selectedNewsId && location.search && !listNewsLoaded) {
      if (!languageItem) {
        cleanUp = init()
        getLanguageForNews()
      } else {
        handleChangeForLanguage(languageItem)
      }
    }

    if (selectedNewsId) {
      params.set('newsId', selectedNewsId)
      history.replace({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
    } else if ((newsTypeQueryParam === 'dailyNews' || !newsTypeQueryParam) && !listNewsLoaded) {
      setActive(true)
      params.set('newsTab', 'dailyNews')
      history.replace({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
    } else if (newsTypeQueryParam === 'favoriteNews' && !listNewsLoaded) {
      setActive(false)
      params.set('newsTab', 'favoriteNews')
      history.replace({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
    }

    return () => {
      cleanUp()
    }
  }, [location.search])

  useEffect(() => {
    setShowSearchPopup(popupProps)
    setCoffeeNewsData(mockData)
    setFirebaseLatestNewsId(mockFirebaseLatestNewsId)
  }, [])

  // To get user details
  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      showBoundary('unAuthorized')
    }
  }

  // To get list of languages and fetch news based on selected Language on Page Load
  const getLanguageForNews = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.languageForNews()

      setErrorState(false)

      setLanguage(response.data.returnLst)

      const defaultLanguageElement = response.data.returnLst.find(({ idLanguage }) => idLanguage === userIdLanguage)

      if (defaultLanguageElement) {
        setLanguageItem(defaultLanguageElement)
        handleSelectLanguage(defaultLanguageElement)
      } else {
        setLanguageItem(response.data.returnLst[0])
        handleSelectLanguage(response.data.returnLst[0])
      }
    } catch {
      setErrorState(true)
    }
  }

  // To get coffee news on Daily Feeds on PAGE LOAD
  const getCoffeeNewsData = async (userId, pNumber, pSize, languageId) => {
    setCoffeeNewsData([])
    setHasMore(true)
    setSkeletonLoading(true)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))
    try {
      const response = await apiAdapterCoffeeWeb.getNewsToDisplayForUserWithLock({ userId, pNumber, pSize, languageId: languageId || 1 })

      if (response.data?.returnLst) {
        setPageNumber(pNumber + 1)
        const dateList = []
        const updatedNewsData = response.data?.returnLst.map((ele) => {
          const { customDateWithDay, newsHtml } = ele
          const data = addBlankTargetToAnchors(newsHtml)

          if (!dateList.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay, newsHtml: data }
          }

          return ele
        })

        setCoffeeNewsData(updatedNewsData)
        setFilteredCoffeeNewsData(updatedNewsData)
        setDateForTheDay(dateList)
        window.scrollTo({ top: 0 })
      } else {
        setSkeletonLoading(false)
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, stationaryMessage: t('CURRENTLY_NEWS_FEED_NO_DATA') }))
        setOnRemoval(true)
      }
    } catch (err) {
      handleCatchError(err, 'default')
      setHasMore(false)
    } finally {
      setSkeletonLoading(false)
    }
  }

  // To get coffee news on Daily Feeds on SCROLL
  const getNewsOnInfiniteScroll = async () => {
    setHasMore(true)

    if (!searchValue) {
      if (active) {
        setSkeletonLoading(true)

        try {
          const response = await apiAdapterCoffeeWeb.getNewsToDisplayForUserWithLock({ userId, pNumber: pageNumber, pSize: pageSize, languageId: defaultNewsLanguage.idLanguage || 1 })

          if (response?.data?.returnLst?.length) {
            const dateList = []
            const theDayData = response?.data?.returnLst.map((ele) => {
              const { newsHtml, customDateWithDay } = ele
              const data = addBlankTargetToAnchors(newsHtml)

              if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
                dateList.push(customDateWithDay)

                return { ...ele, customDate: customDateWithDay, newsHtml: data }
              }

              return ele
            })

            const modifiedData = theDayData.filter(({ id }) => !filteredCoffeeNewsData?.some((item) => item.id === id))

            setDateForTheDay((dateForTheDay) => [...dateForTheDay, ...dateList])
            setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
            setPageNumber((prevPageNumber) => prevPageNumber + 1)
            setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
          } else {
            setHasMore(false)
            setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
          }
        } catch (err) {
          setHasMore(false)
          handleCatchError(err, 'scroll')
        } finally {
          setSkeletonLoading(false)
        }
      } else {
        setHasMore(true)
        getFavouriteNewsDataOnScroll(userId, pageNumber, pageSize, defaultNewsLanguage.idLanguage)
      }
    } else {
      globalSearchDataScroll(userId, pageNumber)
    }
  }

  // To handle API Errors while Fetching News
  const handleCatchError = (err, type, searchStatus) => {
    if (err.response?.data.statusCode === 404 || err?.data.statusCode === 404) {
      if (searchStatus) {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NO_RESULTS_FOR_SEARCH') }))
      } else if (type === 'scroll') {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
      } else if (type === 'default') {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))
      }
    } else {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_API_FAILED') }))
    }
  }

  // To get Favorite Coffee News on PAGE LOAD
  const getNewsDataFavouriteForLanguage = async (userId, pNumber, pSize, language) => {
    setHasMore(true)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))
    setSkeletonLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getMyFavoriteNewsForUserLanguage({ userId, pNumber, pSize, language })

      if (response.data.returnLst) {
        const dateList = []
        const updatedNewsData = response.data?.returnLst?.map((ele) => {
          const { customDateWithDay } = ele

          if (!dateList.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay }
          }

          return ele
        })

        setCoffeeNewsData(updatedNewsData)
        setFilteredCoffeeNewsData(updatedNewsData)
        setDateForTheDay(dateList)
        setPageNumber(pNumber + 1)
        window.scrollTo({ top: 0 })
      } else {
        handleCatchError(response, 'default')
      }
    } catch (err) {
      handleCatchError(err, 'default')
      setCoffeeNewsData([])
      setFilteredCoffeeNewsData([])
      setHasMore(false)
    } finally {
      setSkeletonLoading(false)
    }
  }

  // To get Favorite Coffee News on SCROLL
  const getFavouriteNewsDataOnScroll = async (userId, pNumber, pSize, language) => {
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))
    setSkeletonLoading(true)
    setOnRemoval(false)
    await apiAdapterCoffeeWeb
      .getMyFavoriteNewsForUserLanguage({ userId, pNumber, pSize, language })
      .then((response) => {
        if (response.data?.returnLst?.length) {
          const dateList = []
          const updatedNewsData = response.data?.returnLst.map((ele) => {
            const { customDateWithDay } = ele

            if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
              dateList.push(customDateWithDay)

              return { ...ele, customDate: customDateWithDay }
            }

            return ele
          })

          const modifiedData = updatedNewsData.filter(({ id }) => !filteredCoffeeNewsData?.some((item) => item.id === id))

          setDateForTheDay((dateForTheDay) => [...dateForTheDay, ...dateList])
          setPageNumber(pNumber + 1)
          setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
          setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
        } else {
          setHasMore(false)
          setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: searchValue === '' ? t('NEWS_FEED_ALL_NEWS_FETCHED') : t('NO_RESULTS_FOR_SEARCH') }))
        }
      })
      .catch((err) => {
        setHasMore(false)
        handleCatchError(err, 'scroll')
      })
      .finally(() => setSkeletonLoading(false))
  }

  // To get News Results on First Search
  const globalSearchData = async () => {
    await apiAdapterCoffeeWeb
      .searchNews({ userId, searchValue, pNumber: 1, pSize: window.innerWidth <= 767 ? 20 : 10, active: !active, languageId: defaultNewsLanguage.idLanguage })
      .then((response) => {
        const dateList = []
        const newData = response.data?.returnLst.map((ele) => {
          const { customDateWithDay } = ele

          if (!dateList.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay }
          }

          return ele
        })

        setCoffeeNewsData(newData)
        setDateForTheDay(dateList)
      })
      .catch((err) => {
        handleCatchError(err, 'default', 'search')
      })
  }

  // To get News Results for Search on SCROLL
  const globalSearchDataScroll = async (userId, pNumber) => {
    await apiAdapterCoffeeWeb
      .searchNews({ userId, searchValue, pNumber: pageNumber, pSize: 10, active: !active, languageId: defaultNewsLanguage.idLanguage })
      .then((response) => {
        if (response.data?.returnLst?.length) {
          const dateList = []
          const newData = response.data.returnLst.map((ele) => {
            const { customDateWithDay } = ele

            if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
              dateList.push(customDateWithDay)

              return { ...ele, customDate: customDateWithDay }
            }

            return ele
          })

          setDateForTheDay(dateList)
          setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...newData])
          setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...newData])
          setPageNumber(pNumber + 1)
        } else {
          setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
        }
      })
      .catch((err) => {
        handleCatchError(err, 'scroll')
      })
  }

  // To update CoffeeNews Data on UI after favorite Unfavorite Action
  const updateCoffeeNewsData = (item, updates) => {
    setCoffeeNewsData((prevState) => {
      const updatedCards = prevState.map((ele) => {
        if (ele.id === item.id) {
          return { ...ele, ...updates }
        }

        return ele
      })

      return updatedCards
    })
    setFilteredCoffeeNewsData((previousState) => previousState.map((ele) => (ele.id === item.id ? { ...ele, ...updates } : ele)))
  }

  // To handle Favorite/Un-Favorite API fail for UI
  const handleApiError = (item, favouriteNewsId) => {
    updateCoffeeNewsData(item, {
      idNewsFavourite: favouriteNewsId || 0,
      newsFavourite: favouriteNewsId ? null : item.newsFavourite,
      isLoading: false
    })
  }

  // To handle API request to favorite a News
  const addToFavourite = async (item) => {
    updateCoffeeNewsData(item, {
      newsFavourite: {
        id: 0,
        idUser: userId,
        idNews: item.id,
        isFavourite: true
      },
      isLoading: true
    })

    const postData = {
      id: 0,
      idUser: userId,
      idNews: item.id,
      isFavourite: true
    }

    try {
      const response = await apiAdapterCoffeeWeb.postNewsUsersFavorite(postData)

      updateCoffeeNewsData(item, {
        idNewsFavourite: response.data.returnLst.id,
        newsFavourite: {
          id: response.data.returnLst.id,
          idUser: userId,
          idNews: item.id,
          isFavourite: true
        },
        isLoading: false
      })
    } catch {
      handleApiError(item, 0)
    }
  }

  // To handle API request to unfavorite a news.
  const removeFromFavourite = async (item) => {
    if (!item.isLoading) {
      updateCoffeeNewsData(item, {
        idNewsFavourite: 0,
        newsFavourite: null
      })

      try {
        let newsToRemove

        if (item.id === +favoriteFromReadMore.newsId) {
          newsToRemove = favoriteFromReadMore.favoriteIdReadMore
        }

        const {
          data: { totalRecords }
        } = await apiAdapterCoffeeWeb.makeNewsUnfavorite(newsToRemove || item.idNewsFavourite)

        setOnRemoval(true)
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))

        if (!active) {
          setCoffeeNewsData((prevState) => {
            const newFavouriteData = prevState.filter((ele) => ele.id !== item.id)
            const dateList = []
            const newData = newFavouriteData.map((ele) => {
              const { customDateWithDay } = ele

              if (!dateList.includes(customDateWithDay)) {
                dateList.push(customDateWithDay)

                return { ...ele, customDate: customDateWithDay }
              }

              return ele
            })

            return newData
          })
          setFilteredCoffeeNewsData((prevState) => {
            const newFavouriteData = prevState.filter((ele) => ele.id !== item.id)
            const dateList = []
            const newData = newFavouriteData.map((ele) => {
              const { customDateWithDay } = ele

              if (!dateList.includes(customDateWithDay)) {
                dateList.push(customDateWithDay)

                return { ...ele, customDate: customDateWithDay }
              }

              return ele
            })

            return newData
          })

          const defaultSelectedLanguageId = getLocalLanguageIdForNews()

          if (coffeeNewsData.length === pageSize && Number(totalRecords) > 4) {
            getFavouriteNewsDataOnScroll(userId, 2, pageSize, defaultSelectedLanguageId)
          } else if (coffeeNewsData.length === pageSize && Number(totalRecords) > 3) {
            getNewsDataFavouriteForLanguage(userId, 1, pageSize, defaultSelectedLanguageId)
          }
        }
      } catch {
        handleApiError(item, item.idNewsFavourite)
      }
    }
  }

  // To make API request to get the latest News added and update it on UI
  const getNewsAndMediaById = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getNewsById({ userId, newsId: firebaseLatestNewsId, isRead: false })
      const newObject = [{ ...response.data?.returnLst, customDateWithDay: 'Today' }]
      const newDataList = newObject.concat(coffeeNewsData)
      const dateList = []
      const newData = newDataList.map((ele) => {
        const { customDateWithDay } = ele

        if (!dateList.includes(customDateWithDay)) {
          dateList.push(customDateWithDay)

          return { ...ele, customDate: customDateWithDay }
        }

        return { ...ele, customDate: null }
      })

      setCoffeeNewsData(newData)
      setFilteredCoffeeNewsData(newData)
      setDateForTheDay(dateList)
    } catch (error) {
      console.error('Failed to fetch news and media')
    }
  }

  // To Load coffee news on DAILY FEEDS Tab Click action
  const getCoffeeNewsDataOnClick = () => {
    setActive(true)
    setCoffeeNewsData([])
    setPageNumber(1)
    setHasMore(true)
    setListNewsLoaded(false)
    setSearchValue('')
    params.set('newsTab', 'dailyNews')
    history.replace({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
  }

  // To Load coffee news on MY FAVOURITE Tab Click action
  const getFavouriteNews = () => {
    setActive(false)
    setSearchValue('')
    setCoffeeNewsData([])
    setPageNumber(1)
    setHasMore(true)
    setListNewsLoaded(false)
    params.set('newsTab', 'favoriteNews')
    history.replace({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
  }

  // To navigate to Read More News Modal on Read More Action
  const navigateToNewsReadMoreScreen = (item, index) => {
    const { newsLock, id: newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views } = item

    setShowSearchPopup(false)
    setListNewsLoaded(true)

    const videoContainers = document.querySelectorAll('.video-container')

    videoContainers.forEach((container) => {
      const video = container.querySelector('video')

      video.pause()
    })

    if (newsLock) {
      history.push({ pathname: ROUTE_STRINGS.subscription, state: { newsId } })
      setLocalWithSubscriptionNewsId(newsId)
    } else {
      setNewsId(newsId)
      setLocalWithLatestNewsReadNotification(newsId)
      params.set('newsId', newsId)

      const newsData = {
        newsId,
        author,
        customdate,
        customtime,
        newsHtml,
        filePath,
        fileType,
        nwsFeedMedia,
        encryptedId,
        newsForSubscription,
        subject,
        newsFavourite,
        idNewsFavourite,
        views
      }

      const navigationData = {
        newItems: item,
        newIndex: index
      }

      history.push({
        search: params.toString(),
        state: {
          ...newsData,
          ...navigationData
        }
      })
    }
  }

  // To load news based on Language change
  const handleChangeForLanguage = (e) => {
    setDefaultNewsLanguage(e)
    setPageNumber(1)
    setSearchValue('')
    window.scrollTo(0, 0)

    const newsTypeQueryParam = searchParams.get('newsTab')

    if (!newsTypeQueryParam || newsTypeQueryParam === 'dailyNews') {
      getCoffeeNewsData(userId, 1, pageSize, e.idLanguage)
      setLocalWithLanguageIdForNews(e.idLanguage)
    } else if (newsTypeQueryParam === 'favoriteNews') {
      getNewsDataFavouriteForLanguage(userId, 1, pageSize, e.idLanguage)
      setLocalWithLanguageIdForNews(e.idLanguage)
    }
  }

  // To handle GO BACK Button action
  const handleNewsFalseModal = (item) => {
    const modifiedData = coffeeNewsData.map((ele) => (ele.id === item?.id ? { ...ele, views: item?.views } : ele))

    setCoffeeNewsData(modifiedData)
    history.replace(ROUTE_STRINGS.coffeeNewsFeeds)

    if (showSearchPopupForBack === true) {
      setShowSearchPopup(true)
    }

    document.querySelector('body').style.overflow = 'auto'
  }

  // To handle search operation in Locally Loaded News.
  const handleSearchExistingData = (e) => {
    setSearchValue(e.target.value)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))

    const searchedDataFromExistingData = filteredCoffeeNewsData.filter((item) => {
      if (item.subject.toLowerCase().includes(e.target.value.toLowerCase()) || item.author.toLowerCase().includes(e.target.value.toLowerCase()) || item.customDateWithDay.toLowerCase().includes(e.target.value.toLowerCase()) || item.shareText.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item
      }
    })
    const customDateList = []
    const customDateNewsList = searchedDataFromExistingData.map((element) => {
      const { newsHtml, customDateWithDay } = element

      if (!customDateList.includes(customDateWithDay)) {
        customDateList.push(customDateWithDay)

        return { ...element, customDate: customDateWithDay, newsHtml: addBlankTargetToAnchors(newsHtml) }
      }

      return element
    })

    if (coffeeNewsData.length === 0) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('CURRENTLY_NEWS_FEED_NO_DATA') }))
    }

    setCoffeeNewsData(customDateNewsList)
  }

  const handleFilterOptions = (options, { inputValue }) => options.filter((option) => option.languageName.toLowerCase().startsWith(inputValue.toLowerCase()))

  // To Update My Favorite Tab in the UI.
  const updateFavoriteNewsFeedTab = (newsId) => {
    const updatedCards = coffeeNewsData.filter((ele) => ele?.id !== +newsId)

    document.querySelector('body').style.overflow = 'auto'

    const dateList = []
    const newData = updatedCards.map((ele) => {
      const { customDateWithDay } = ele

      if (!dateList.includes(customDateWithDay)) {
        dateList.push(customDateWithDay)

        return { ...ele, customDate: customDateWithDay }
      }

      return ele
    })

    if (newData.length <= pageSize) {
      getNewsDataFavouriteForLanguage(userId, 1, pageSize, defaultNewsLanguage.idLanguage)
    }
    setCoffeeNewsData(newData)

    setFilteredCoffeeNewsData(filteredCoffeeNewsData.filter((ele) => ele?.id !== +newsId))
  }

  // Function to handle Favorite Unfavorite Action from News Read More Page
  const handleFavoriteAction = (newsId, favouriteNewsId, isFavourite, newsIndex, newsItem) => {
    const updatedCards = coffeeNewsData.map((ele) => {
      if (ele.id === +newsId) {
        return {
          ...ele,
          newsFavourite: isFavourite ? { id: 0, idUser: userId, idNews: newsId, isFavourite: true } : null,
          idNewsFavourite: isFavourite ? favouriteNewsId : 0,
          isLoading: false
        }
      }

      return ele
    })

    setCoffeeNewsData(updatedCards)

    const updatedNewsDataForFilter = filteredCoffeeNewsData.map((item) => {
      if (item.id === +newsId) {
        return {
          ...item,
          newsFavourite: isFavourite ? { id: 0, idUser: userId, idNews: newsId, isFavourite: true } : null,
          idNewsFavourite: isFavourite ? favouriteNewsId : 0,
          isLoading: false
        }
      }

      return item
    })

    setFilteredCoffeeNewsData(updatedNewsDataForFilter)

    if (!active && !isFavourite) {
      updateFavoriteNewsFeedTab(newsId)
    } else if (!active && isFavourite) {
      const insertAtIndex = (newsList, newsIndex, newNewsItem) => {
        const existingItemIndex = newsList.findIndex((news) => news.id === newNewsItem.id)

        if (existingItemIndex !== -1) {
          const updatedNewsList = [...newsList]

          updatedNewsList[existingItemIndex] = newNewsItem

          return updatedNewsList
        }

        const updatedNewsList = [...newsList]

        updatedNewsList.splice(newsIndex, 0, newNewsItem)

        return updatedNewsList
      }

      setCoffeeNewsData((prevData) => insertAtIndex(prevData, newsIndex, newsItem))

      setFilteredCoffeeNewsData((prevData) => insertAtIndex(prevData, newsIndex, newsItem))

      setFavoriteFromReadMore({
        newsId,
        favoriteIdReadMore: favouriteNewsId
      })
    }
  }

  // To handle video play in news feed card
  const handlePlay = (index) => {
    commonMethods.handlePlay(index, currentPlayer, setCurrentPlayer, videoRefs)
  }

  // To handle Close Icon click action in search field
  const handleSearchClear = () => {
    commonMethods.handleSearchClear(setSearchValue, setCoffeeNewsData, filteredCoffeeNewsData, setNewsFeedStatus, t)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: filteredCoffeeNewsData.length === 0, errorMessage: '' }))
  }

  // To handle Back Icon Button Action in Search Modal Mobile Screen
  const handleCloseSearchModal = () => {
    setShowSearchPopup(false)
    setShowSearchPopupForBack(false)

    document.querySelector('body').style.overflow = 'auto'
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      globalSearchData()
    }
  }

  const getSubscriptionLabelColor = (ele) => {
    const colorCodes = ele?.subscriptionColor?.split(',')

    return `linear-gradient(100deg, ${colorCodes[0]}, ${colorCodes[1]}, ${colorCodes[2]})`
  }

  const dateFormatter = (dayDateString) => {
    if (!dayDateString) {
      return
    }

    const [day, date] = dayDateString.split(',').map((el) => el.trim())

    return ['Today', 'Yesterday'].includes(dayDateString) ? dayDateString : `${day}, ${dateFormatBasedOnUser(date, 'spaceFormat')}`
  }

  // Render Method to Update Error Message on News API Fail
  const ContentRenderer = ({ noNewsFeedImage, errorMessage }) => (
    <>
      <div className="search_on_page_load">{noNewsFeedImage && <img src={NoData} alt="NoDataFound" className="no_data_found_image" />}</div>
      {errorMessage && <div className="all-data-fetched">{errorMessage}</div>}
    </>
  )

  const handleSelectLanguage = (languageItem) => {
    handleChangeForLanguage(languageItem)
    setHasMore(true)
  }

  const toolbarContents = (
    <div className="toolbar-container">
      <i className="pi pi-arrow-left arrow-icon" onClick={handleCloseSearchModal} />
      <h6 className="coffee-news-feed-heading">{t('COFFEE_NEWS_FEEDS')}</h6>
    </div>
  )

  return (
    <div>
      <div className={`coffee_news_feeds content-wrapper restrictEverything ${location.search.includes('newsId') ? 'in-read-more' : ''}`} onContextMenu={(e) => e.preventDefault()}>
        <div className={`feeds_container ${location.search.includes('newsId') ? 'in-read-more' : ''}`}>
          <div className="news_feeds_card" style={{ margin: '0px' }} data-testid="news-feed-main-card">
            {!location.search.includes('newsId') && (
              <>
                <div
                  className="fixed_coffee_news_feed stickyLanguageDropDown"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: '2',
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 2px',
                    position: 'fixed'
                  }}
                >
                  <div className="coffee_news_title">{t('COFFEE_NEWS_FEEDS')}</div>
                  <div className="headerSec2">
                    <div className="search_for_big_screen ">
                      <div className="p-inputgroup">
                        <span className="p-input-icon-right">
                          {searchValue?.length > 0 ? <i className="pi pi-times p-input-clear-icon" onClick={handleSearchClear} /> : ''}
                          <i className="pi pi-search" onClick={globalSearchData} data-testid="web-search-icon" />
                          <InputText value={searchValue} onChange={(e) => handleSearchExistingData(e)} placeholder={t('SEARCH_FOR_NEWS')} onKeyDown={handleKeyPress} />
                        </span>
                      </div>
                    </div>
                    <div className="language_and_search">
                      <DropdownComponent isNewsFeedPage={true} handleSelectCountry={handleSelectLanguage} countryList={getLanguage} currentItem={languageItem} setLanguageItem={setLanguageItem} errorState={errorState} testId="drop-down" isLanguageDropdown={true} width="100%" height="43px" />
                      <div className="buy_subscription_div">
                        <div className="buy_subscription_sub_div">
                          <NavLink activeClassName="active_nav-link" exact to={ROUTE_STRINGS.subscription} className="buy_subscription_button">
                            {t('BUY_SUBSCRIPTION')}
                          </NavLink>
                        </div>

                        <div
                          className="small_screen_search_div d-none"
                          onClick={() => {
                            handleSearchInput(setShowSearchPopup, setShowSearchPopupForBack, setSearchValue)
                          }}
                          data-testid="mobile-screen-search-container"
                        >
                          <i className="pi pi-search mobile-screen-search-icon" data-testid="mobile-screen-search-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`fixed_coffee_news_feed${isHidden ? ' hidden' : ' visible'}`} id="forScroll" style={{ zIndex: '1' }}>
                  {active ? (
                    <nav className="tabs_for_news_feed_screen">
                      <div className="tabs_nav_link">
                        <div className="tabs_nav_link_sub_div">
                          <i className="pi pi-calendar dailyFeed-icon" data-testid="daily-feed-icon" />
                          {t('DAILY_FEED')}
                        </div>
                        <div onClick={() => getFavouriteNews()} className="tab_daily_feed">
                          <FilledHeartIcon className="filled_heart" data-testid="my-fav-icon" />
                          {t('MY_FAVOURITE')}
                        </div>
                      </div>
                    </nav>
                  ) : (
                    <nav className="tabs_for_news_feed_screen">
                      <div className="non_active_nav_link">
                        <div onClick={() => getCoffeeNewsDataOnClick()} className="non_active_link_sub_div">
                          <i className="pi pi-calendar non-active-dailyFeed-icon" />
                          {t('DAILY_FEED')}
                        </div>
                        <div className="non_active_my_favourite_div">
                          <FilledHeartIcon className="non_active_filled_heart" />
                          {t('MY_FAVOURITE')}
                        </div>
                      </div>
                    </nav>
                  )}
                </div>
              </>
            )}

            {location.search.includes('newsId') && <NewsReadMore handleFavoriteAction={handleFavoriteAction} newsId={newsId} handleNewsFalseModal={handleNewsFalseModal} setOnRemoval={setOnRemoval} setNewsFeedStatus={setNewsFeedStatus} isPage="yes" />}

            <InfiniteScroll
              dataLength={coffeeNewsData?.length}
              next={coffeeNewsData?.length >= pageSize && getNewsOnInfiniteScroll}
              hasMore={hasMore}
              data-testid="infinite-Scroll"
              loader={
                <>
                  {skeletonLoading && (
                    <div>
                      {skeletonArray.map((ele, index) => (
                        <div className="skeleton_for_full_screen" key={index} data-testid="skeleton">
                          <div data-testid="skeleton-tag">
                            <Skeleton shape="rectangle" className="skeleton_tag" height="225px" />
                          </div>
                          <div>
                            <Skeleton shape="rectangle" className="skeleton_tag" height="30px" />
                            <Skeleton shape="rectangle" className="skeleton_tag" height="150px" />
                            <div className="skeleton_div">
                              <Skeleton shape="rectangle" className="skeleton_tag" height="30px" />
                              <Skeleton shape="rectangle" className="skeleton_tag" height="30px" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              }
            >
              <div className="coffee_news_feeds content-wrapper restrictEverything" style={{ marginTop: '0px' }}>
                <div className="feeds_container">
                  <div className="news_feeds_card" data-testid="mobile-view-news-feed-card">
                    <div className="outer-card">
                      {!showSearchPopup && (
                        <div className={`bottom_button bottomButtonScroll${isHidden ? ' slide-down' : ' down'}`}>
                          <div className="bottom_button_sub_div" onClick={handleGoToTop}>
                            <div className="bottom_button_icon_div">
                              <i className="pi pi-home home-icon" />
                            </div>
                          </div>
                          <div
                            className="bottom_button_search_bar_div"
                            onClick={() => {
                              handleSearchInput(setShowSearchPopup, setShowSearchPopupForBack, setSearchValue)
                            }}
                          >
                            <div className="search_text">{t('SEARCH')}</div>
                            <div className="search_icon_div">
                              <i className="pi pi-search search-icon" />
                            </div>
                          </div>
                        </div>
                      )}
                      {coffeeNewsData?.length > 0 && (
                        <div>
                          <div>
                            <div className="paginated" style={{ marginBottom: '10px' }}>
                              {coffeeNewsData?.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.customDate && (
                                    <div className="date_and_time_stamp col-md-12">
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                      <div className="date">{dateFormatter(item?.customDate)}</div>
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                    </div>
                                  )}
                                  {
                                    <>
                                      {window.innerWidth > 767 && index < 1 && scrollPositionForData > 117 && (
                                        <div className={'date_tag_design col-md-12 '}>
                                          <div className="date_horizontal_line">.</div>
                                          <div className="date">{dateFormatter(showStickyDate)}</div>
                                          <div className="date_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {
                                    <>
                                      {window.innerWidth <= 767 && index < 1 && scrollPositionForData > 50 && (
                                        <div className={isHidden ? 'date_tag_for_big_screen col-md-12' : 'date_tag_design col-md-12'}>
                                          <div className="date_tag_horizontal_line">.</div>
                                          <div className="date">{showStickyDate}</div>
                                          <div className="date_tag_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {item.idNewsFavourite === null ? (
                                    ''
                                  ) : (
                                    <>
                                      <div key={index} className={`object-date sticky_date ${stickyDateList.includes('object-id') ? 'sticky' : ''}`} data-id={`${item.customDateWithDay}`}>
                                        <div className="image_div" data-testid="image-container">
                                          <div className="data_for_mobile_screen" style={{ padding: '4px 9px 2px' }}>
                                            <div className="calendar-icon-container" data-testid="mobile-view-calender-icon-container">
                                              <i className="pi pi-calendar icon-container calendar-icon" /> {dateFormatBasedOnUser(item?.customdate, 'spaceFormat')}
                                            </div>
                                            <div className="time-icon-container" data-testid="mobile-view-time-icon-container">
                                              <i className="pi pi-clock icon-container time-icon" /> {item?.customtime}
                                            </div>
                                          </div>
                                          {item?.nwsFeedMedia?.length !== 0 ? (
                                            <div className="video_div" data-testid="video-container">
                                              {item?.nwsFeedMediaVideo?.length > 0 ? (
                                                <div className="video-container" style={{ margin: 'auto' }}>
                                                  <div key={item.id}>
                                                    <video
                                                      className="video_display"
                                                      src={item?.nwsFeedMediaVideo[0]?.pathOfMedia}
                                                      controls
                                                      ref={(ref) => {
                                                        videoRefs.current[index] = ref
                                                      }}
                                                      onPlay={() => handlePlay(index)}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="sliding_image_div" data-testid="sliding-image-container">
                                                  {item?.nwsFeedMediaImage?.length > 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_five_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>{index < 5 && <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />}</div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_four_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" key={index} />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 3 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_three_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 2 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_two_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 1 && (
                                                    <div className="single_image_div" id="run">
                                                      {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                        <div key={index}>
                                                          <img
                                                            className="imgSize single_image"
                                                            style={{
                                                              width: '100%',
                                                              objectFit: 'contain',
                                                              padding: '8px',
                                                              borderRadius: '10px'
                                                            }}
                                                            src={ele.pathOfMedia}
                                                            alt="thumbnail"
                                                          />
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="news_media_div">
                                              <img src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.coloredLogo : devEnvLogo} className="static_image" alt="coffee-web-logo" />
                                            </div>
                                          )}
                                          <div className="data_for_mobile_screen " style={{ padding: '0px 9px 6px' }}>
                                            <div className="user-account-icon-container">
                                              <i className="pi pi-user icon-container account_icon" />
                                              <span className="author-text">{item?.author}</span>
                                            </div>

                                            <div className="visibility-icon-container">
                                              <div className="view-icon-container">
                                                <i className="pi pi-eye icon-container visibility-icon" /> {item?.views}
                                              </div>
                                              <div>
                                                {active && <div>{item?.newsFavourite !== null ? <FilledHeartIcon className="cursor_pointer" onClick={() => removeFromFavourite(item)} data-testid="unFav-icon" /> : <UnFilledHeartIcon onClick={() => addToFavourite(item, index)} className="heartLogoBlob cursor_pointer" />}</div>}
                                                {!active && <div>{item?.idNewsFavourite !== null ? <FilledHeartIcon className="cursor_pointer" onClick={() => removeFromFavourite(item)} /> : <UnFilledHeartIcon onClick={() => addToFavourite(item, index)} className="heartLogoBlob cursor_pointer" />}</div>}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="subSecForNews" data-testid="news-feed-smaller-description">
                                          <div>
                                            <div className="d-flex justify-content-between ">
                                              <div className="newsHeading cursor_pointer">
                                                <div onClick={() => navigateToNewsReadMoreScreen(item, index)} data-testid="news-subject">
                                                  {ReactHtmlParser(item?.subject)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="newsHtml" data-testid="news-share-text">
                                              {ReactHtmlParser(item?.shareText)}
                                            </div>
                                          </div>
                                          <div className="newsDetails" data-testid="news-details-container">
                                            <div className="details">
                                              <div className="calendar-icon-container" data-testid="custom-date-icon">
                                                <i className="pi pi-calendar icon-container" />
                                                {dateFormatBasedOnUser(item?.customdate, 'spaceFormat')}
                                              </div>
                                              <div className="time-icon-container">
                                                <i className="pi pi-clock icon-container" data-testid="custom-time-icon" />
                                                {item?.customtime}
                                              </div>
                                              <div className="visibility-icon-container">
                                                <i className="pi pi-eye icon-container" data-testid="custom-views-icon" />
                                                {item?.views}
                                              </div>
                                              <div className="user-account-icon-container">
                                                <i className="pi pi-user icon-container account-icon" data-testid="author-icon" />
                                                <div className="news-content">
                                                  {item?.author?.substr(0, 9)}
                                                  {item?.author?.length > 9 && <span>...</span>}
                                                </div>
                                              </div>
                                              <div className="published_for_div">
                                                <div className="published_for_sub_div" data-testid="subscription-code">
                                                  <span className="mr-2 d-none">Published for </span>
                                                  {item?.newsForSubName?.map((ele, index) => (
                                                    <React.Fragment key={index}>
                                                      {ele?.subscriptioncode && (
                                                        <span
                                                          className="eachLabeling"
                                                          style={{
                                                            backgroundSize: '400% 400%',
                                                            backgroundImage: getSubscriptionLabelColor(ele),
                                                            animation: 'gradient 7s ease infinite',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            padding: '1px 7px',
                                                            margin: '1px 2px'
                                                          }}
                                                        >
                                                          {ele?.subscriptioncode}
                                                        </span>
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="sub_labeling_main_div">
                                              <div className="sub_labeling">
                                                <span className="mr-2 d-none">Published for </span>
                                                {item?.newsForSubName?.map((ele, index) => (
                                                  <React.Fragment key={index}>
                                                    {ele?.subscriptioncode && (
                                                      <span
                                                        className="eachLabeling"
                                                        style={{
                                                          backgroundSize: '400% 400%',
                                                          backgroundImage: `linear-gradient(100deg, ${ele?.subscriptionColor?.slice(0, 7)}, ${ele?.subscriptionColor?.slice(9, 16)}, ${ele?.subscriptionColor?.slice(18, 27)})`,
                                                          animation: 'gradient 7s ease infinite',
                                                          color: 'white',
                                                          borderRadius: '4px'
                                                        }}
                                                      >
                                                        {ele?.subscriptioncode}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </div>
                                              <div className="heart_pulse_logo_for_read_more" data-testid="heart-icon">
                                                {active && <>{item?.newsFavourite != null ? <FilledHeartIcon className="cursor_pointer" onClick={() => removeFromFavourite(item)} /> : <UnFilledHeartIcon onClick={() => addToFavourite(item, index)} className="heartLogoBlob cursor_pointer" data-testid="fav-icon" />}</>}
                                                {!active && <>{item?.idNewsFavourite != null ? <FilledHeartIcon className="cursor_pointer" onClick={() => removeFromFavourite(item)} /> : <UnFilledHeartIcon onClick={() => addToFavourite(item, index)} className="heartLogoBlob cursor_pointer" />}</>}
                                              </div>
                                              <div>
                                                {!item?.newsLock ? (
                                                  <Button buttonType={'CUSTOM'} buttonLabel={t('READ_MORE')} buttonSize={'medium'} onButtonClick={() => navigateToNewsReadMoreScreen(item, index)} data-testid="read-more-button" />
                                                ) : (
                                                  <div className="read_more_button_with_lock" onClick={() => navigateToNewsReadMoreScreen(item, index)}>
                                                    <div className="read_more_button_div">
                                                      <LockIcon />
                                                    </div>
                                                    <Button buttonType={'CUSTOM'} buttonLabel={t('READ_MORE')} buttonSize={'xx-small'} />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
            <div className="full_Stop">.</div>

            {/* searchPopup for news */}
            {showSearchPopup && (
              <div className="search_popup_for_news" data-testid="search-popup-news">
                <Toolbar className="app-bar" start={toolbarContents} />

                <div className="input_field">
                  <div className="p-inputgroup">
                    <span className="p-input-icon-right">
                      {searchValue?.length ? <i className="pi pi-times p-input-clear-icon" onClick={handleSearchClear} /> : ''}
                      <i className="pi pi-search" onClick={globalSearchData} />
                      <InputText value={searchValue} onChange={(e) => handleSearchExistingData(e)} placeholder={t('SEARCH_FOR_NEWS')} onKeyDown={handleKeyPress} />
                    </span>
                  </div>
                </div>
                <Divider className="divider-style" />

                <div className="search_trending_data" id="scrollableDiv" data-testid="mobile-view-search-trending-data">
                  <InfiniteScroll dataLength={coffeeNewsData?.length} scrollableTarget="scrollableDiv" next={coffeeNewsData?.length >= pageSize && getNewsOnInfiniteScroll} hasMore={hasMore} data-testid="infinite-Scroll" loader={''}>
                    {coffeeNewsData.map((item, index) => (
                      <div key={index}>
                        <div className="search_trending_data_sub_div" data-testid="search-trending-news-container">
                          <div>{ReactHtmlParser(item.subject)}</div>
                          <i className="pi pi-arrow-right search-forward-arrow" onClick={() => navigateToNewsReadMoreScreen(item, index)} data-testid="search-forward-icon" />
                        </div>
                        <Divider className="divider-style" data-testid="divider-mobile-view" />
                      </div>
                    ))}
                    <div className="search_trending_data_full_stop">.ssw</div>
                  </InfiniteScroll>
                </div>
              </div>
            )}
          </div>

          {coffeeNewsData?.length === 0 && searchValue && !errorMessage && !noNewsFeedImage && (
            <div className="date_and_time_stamp search_news_not_found col-md-12">
              <div className="date search_news_not_found_sub_Div" onClick={globalSearchData}>
                {t('SEE_MORE')}
              </div>
            </div>
          )}

          {!onRemoval && <ContentRenderer noNewsFeedImage={noNewsFeedImage} errorMessage={errorMessage} />}
          {coffeeNewsData?.length === 0 && onRemoval && <ContentRenderer noNewsFeedImage={noNewsFeedImage} errorMessage={errorMessage} />}
        </div>
      </div>
    </div>
  )
}

export default CoffeeNewsFeeds
