import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore'
import ReactHtmlParser from 'react-html-parser'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { Skeleton } from 'primereact/skeleton'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'

import { db } from 'firebase.js'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeewebLogo, ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalUserDetails, getLocalLoggedInStatus, getLocalFreeNewsLanguageId, setLocalWithAppSettings, setLocalWithFreeNewsLanguageId } from 'Utils/LocalStorage_Handler'
import { UnFilledHeartIcon, FilledHeartIcon, devEnvLogo } from 'Assets/Icons'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { NoData } from 'Assets/Images'
import DropdownComponent from 'Components/DropdownComponent'
import { commonMethods } from 'Utils/commonMethods'
import Button from 'Components/UIComponent/Button'
import MetaTagsComponent from 'Components/MetaTagsComponent'
import NewsReadMore from '../NewsReadMore'

function GuestNewsFeed({ ...props }) {
  const { mockData, searchData, mockGuestFirebaseLatestNewsId } = props
  const history = useHistory()
  const params = new URLSearchParams()
  const location = useLocation()
  const videoRefs = useRef([])
  const { t } = useTranslation()
  const userDetails = getLocalUserDetails()
  const userLoginStatus = getLocalLoggedInStatus()
  const pageSize = 10
  const pageRef = location.state?.pageRef
  const { isHidden } = commonMethods.useScrollHandler()
  const { handleSearchInput } = commonMethods
  const { handleGoToTop } = commonMethods

  const [isPage, setIsPage] = useState(pageRef)
  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [filteredCoffeeNewsData, setFilteredCoffeeNewsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [skeletonArray, setSkeletonArray] = useState([])
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const [dateForTheDay, setDateForTheDay] = useState([])
  const [active, setActive] = useState(true)
  const [defaultNewsLanguage, setDefaultNewsLanguage] = useState({})
  const [allLanguages, setAllLanguages] = useState([])
  const [popupForSmallScreenOnly, setPopupForSmallScreenOnly] = useState(false)
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [showSearchPopup, setShowSearchPopup] = useState(false)
  const [scrollPositionForData, setScrollPositionForData] = useState(0)
  const [stickyDateList, setStickyDateList] = useState([])
  const [showStickyDate, setShowStickyDate] = useState(null)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [selectedLanguageId, setSelectedLanguageId] = useState(null)
  const [newsFeedStatus, setNewsFeedStatus] = useState({
    noNewsFeedImage: false,
    errorMessage: '',
    noLanguageOptions: false
  })
  const [newsId, setNewsId] = useState('')
  const [showSearchPopupForBack, setShowSearchPopupForBack] = useState(false)
  const [newsClosed, setNewsClosed] = useState(false)
  const [listNewsLoaded, setListNewsLoaded] = useState(false)

  // TODO if Required
  // const [showModal, setShowModal] = useState(false)
  // const [newsDataOnClick, setNewsDataOnClick] = useState({
  //   encryptedId: '',
  //   newsForSubscription: ''
  // })

  const hasMore = true
  const { noNewsFeedImage, errorMessage } = newsFeedStatus

  if (userLoginStatus) {
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeenewsfeeds)
    }
  }

  const init = () => {
    const array = Array.from({ length: pageSize }, () => 1)

    setSkeletonLoading(true)
    setSkeletonArray(array)

    getCoffeeNewsData(1, 'first')

    let mounted = false

    const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
      if (mounted) {
        const firebaseResponse = doc.data()
        const freeNewsLanguageId = getLocalFreeNewsLanguageId()

        if (!firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === freeNewsLanguageId && firebaseResponse?.IsFreeNews) {
          setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
        }
      } else {
        mounted = true
      }
    })

    return () => {
      unSub()
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPositionForData(window.scrollY || document.documentElement.scrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      let found = false

      for (let i = 0; i < coffeeNewsData?.length; i++) {
        if (coffeeNewsData[i].id === firebaseLatestNewsId) {
          found = true
        }
      }

      if (!found) {
        if (active) {
          setTimeout(() => {
            getNewsAndMediaById()
          }, 5000)
        }
      }
    }
  }, [firebaseLatestNewsId])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const selectedNewsId = searchParams.get('newsId')

    document.querySelector('body').style.overflow = selectedNewsId ? 'hidden' : 'auto'

    let cleanUp = () => {}

    if (!location.search && !newsClosed && !listNewsLoaded) {
      setNewsClosed(false)
      setListNewsLoaded(false)
      cleanUp = init()
    }

    return () => {
      cleanUp()
    }
  }, [location.search])

  useEffect(() => {
    commonMethods.handleStickyDates(scrollPositionForData, setShowStickyDate, setStickyDateList)
  }, [scrollPositionForData, setShowStickyDate, setStickyDateList])

  useEffect(() => {
    if (errorMessage) {
      const timeoutId = setTimeout(() => {
        setNewsFeedStatus((previousState) => ({ ...previousState, errorMessage: '' }))
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [errorMessage])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    setShowSearchPopup(searchData)
    setFirebaseLatestNewsId(mockGuestFirebaseLatestNewsId)
    setSearchValue(mockGuestFirebaseLatestNewsId)
    setCoffeeNewsData(mockData)
    setAllLanguages(mockData)
  }, [])

  // To get coffee news on Daily Feeds on PAGE LOAD
  const getCoffeeNewsData = async (languageId, firstLoad) => {
    setActive(true)
    setCoffeeNewsData([])
    setFilteredCoffeeNewsData([])
    setDateForTheDay([])
    setSkeletonLoading(true)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))
    try {
      const response = await apiAdapterCoffeeWeb.getAllFreeNewsByLanguage({ languageId: languageId || 1 })

      if (response.data.returnLst.usersNewsFeedModel.length) {
        setAllLanguages(response.data?.returnLst?.languageMasters)
        firstLoad && setDefaultNewsLanguage(response.data?.returnLst.languageMasters[0])

        setPageNumber((prevState) => prevState + 1)
        const dateList = []
        const updatedNewsData = response.data?.returnLst.usersNewsFeedModel.map((ele) => {
          const { customDateWithDay, newsHtml } = ele
          const data = addBlankTargetToAnchors(newsHtml)

          if (!dateList.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay, newsHtml: data }
          }

          return ele
        })

        setCoffeeNewsData(updatedNewsData)
        setFilteredCoffeeNewsData(updatedNewsData)
        setDateForTheDay(dateList)
      } else {
        setSkeletonLoading(false)
        setCoffeeNewsData([])
        setFilteredCoffeeNewsData([])
        setDateForTheDay([])
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('CURRENTLY_NEWS_FEED_NO_DATA') }))
      }
    } catch (err) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, noLanguageOptions: true, errorMessage: t('NEWS_FEED_API_FAILED') }))
    } finally {
      setSkeletonLoading(false)
    }
  }

  // To get coffee news on Daily Feeds on SCROLL
  const getNewsOnInfiniteScroll = async () => {
    if (!searchValue) {
      setSkeletonLoading(true)

      try {
        const response = await apiAdapterCoffeeWeb.getFreeNewsToDisplayForUser({ pageNumber, pageSize, languageId: defaultNewsLanguage.idLanguage || 1 })

        if (response?.data?.returnLst?.length) {
          const dateList = []
          const theDayData = response?.data?.returnLst.map((ele) => {
            const { newsHtml, customDateWithDay } = ele
            const data = addBlankTargetToAnchors(newsHtml)

            if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
              dateList.push(customDateWithDay)

              return { ...ele, customDate: customDateWithDay, newsHtml: data }
            }

            return ele
          })

          const modifiedData = theDayData.filter(({ id }) => !filteredCoffeeNewsData?.some((item) => item.id === id))

          setDateForTheDay((dateForTheDay) => [...dateForTheDay, ...dateList])
          setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
        } else {
          setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
        }
      } catch (err) {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_API_FAILED') }))
      } finally {
        setSkeletonLoading(false)
      }
    } else {
      // Search News On Scroll
      getSearchNewsOnScroll(pageNumber)
    }
  }

  // To search news globally
  const getGlobalSearchNews = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getFreeSearchNews({ searchValue, pNumber: 1, pSize: window.innerWidth <= 767 ? 20 : 10, languageId: defaultNewsLanguage.idLanguage || 1 })

      const dateList = []
      const newData = response.data?.returnLst.map((ele) => {
        const { customDateWithDay } = ele

        if (!dateList.includes(customDateWithDay)) {
          dateList.push(customDateWithDay)

          return { ...ele, customDate: customDateWithDay }
        }

        return ele
      })

      setCoffeeNewsData(newData)
      setDateForTheDay(dateList)
    } catch {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_API_FAILED') }))
    }
  }

  // To get search news on scroll
  const getSearchNewsOnScroll = async (pageNumber) => {
    try {
      const response = await apiAdapterCoffeeWeb.getFreeSearchNews({ searchValue, pNumber: pageNumber, pSize: 10, languageId: defaultNewsLanguage.idLanguage || 1 })

      if (response.data?.returnLst?.length) {
        const dateList = []
        const newData = response.data.returnLst.map((ele) => {
          const { customDateWithDay } = ele

          if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay }
          }

          return ele
        })

        setDateForTheDay(dateList)
        setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...newData])
        setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...newData])
        setPageNumber(pageNumber + 1)
      } else {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
      }
    } catch {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_API_FAILED') }))
    }
  }

  // To make API request to get the latest News added and update it on UI
  const getNewsAndMediaById = async () => {
    await apiAdapterCoffeeWeb.getNewsById({ userId: 0, newsId: firebaseLatestNewsId, isRead: false }).then((response) => {
      const newObject = [{ ...response.data?.returnLst, customDateWithDay: 'Today' }]
      const newDataList = newObject.concat(coffeeNewsData)
      const dateList = []
      const newData = newDataList.map((ele) => {
        const { customDateWithDay } = ele

        if (!dateList.includes(customDateWithDay)) {
          dateList.push(customDateWithDay)

          return { ...ele, customDate: customDateWithDay }
        }

        return { ...ele, customDate: null }
      })

      setCoffeeNewsData(newData)
      setFilteredCoffeeNewsData(newData)
      setDateForTheDay(dateList)
    })
  }

  // Navigate To News Read More
  const navigateToNewsReadMoreScreen = ({ showFullfreeNews, id: newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views }) => {
    setShowSearchPopup(false)
    setListNewsLoaded(true)
    const videoContainers = document.querySelectorAll('.video-container')

    videoContainers.forEach((container) => {
      const video = container.querySelector('video')

      video.pause()
    })

    if (!showFullfreeNews) {
      redirectForAuthentication(encryptedId, newsForSubscription, newsId)
    } else {
      setNewsId(newsId)
      params.set('showFullNews', true)
      params.set('newsId', newsId)
      history.push({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views } })
    }
  }

  // Close News Read More Component
  const handleNewsFalseModal = (item, newsClosed) => {
    const modifiedData = coffeeNewsData?.map((ele) => (ele.id === item?.id ? { ...ele, views: item?.views } : ele))

    setNewsClosed(newsClosed)
    setCoffeeNewsData(modifiedData)
    history.replace(ROUTE_STRINGS.guestNewsFeed)
    if (showSearchPopupForBack === true) {
      setShowSearchPopup(true)
    }
    commonMethods.setBodyOverflowToAuto
  }

  // To load news based on Language change
  const handleChangeForLanguage = (e) => {
    if (selectedLanguageId !== e.idLanguage) {
      setSearchValue('')
      setDefaultNewsLanguage(e)
      setLocalWithFreeNewsLanguageId(e.idLanguage)
      setPopupForSmallScreenOnly(false)
      setPageNumber(1)
      window.scrollTo(0, 0)
      if (active) {
        getCoffeeNewsData(e.idLanguage)
      }
    }
  }

  const handleSelectLanguage = (languageItem) => {
    if (languageItem && languageItem.idLanguage) {
      handleChangeForLanguage(languageItem)
    }
  }

  // To handle search operation in Locally Loaded News.
  const handleSearchExistingData = (e) => {
    setSearchValue(e.target.value)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '' }))

    const searchedDataFromExistingData = filteredCoffeeNewsData.filter((item) => {
      if (item.subject.toLowerCase().includes(e.target.value.toLowerCase()) || item.author.toLowerCase().includes(e.target.value.toLowerCase()) || item.customDateWithDay.toLowerCase().includes(e.target.value.toLowerCase()) || item.shareText.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item
      }
    })

    const dateList = []
    const dateCustom = searchedDataFromExistingData.map((element) => {
      const { newsHtml, customDateWithDay } = element

      if (!dateList.includes(customDateWithDay)) {
        dateList.push(customDateWithDay)

        return { ...element, customDate: customDateWithDay, newsHtml: addBlankTargetToAnchors(newsHtml) }
      }

      return element
    })

    if (coffeeNewsData.length === 0) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('CURRENTLY_NEWS_FEED_NO_DATA') }))
    }

    setCoffeeNewsData(dateCustom)
  }

  // To handle video play in news feed card
  const handlePlay = (index) => {
    commonMethods.handlePlay(index, currentPlayer, setCurrentPlayer, videoRefs)
  }

  // To handle Close Icon click action in search field
  const handleSearchClear = () => {
    commonMethods.handleSearchClear(setSearchValue, setCoffeeNewsData, filteredCoffeeNewsData, setNewsFeedStatus, t)
  }

  // To handle Back Icon Button Action in Search Modal Mobile Screen
  const handleCloseSearchModal = () => {
    setShowSearchPopup(false)
    setShowSearchPopupForBack(true)
    commonMethods.setBodyOverflowToAuto
  }

  const getSubscriptionLabelColor = (ele) => {
    const colorCodes = ele?.subscriptionColor?.split(',')

    return `linear-gradient(100deg, ${colorCodes[0]}, ${colorCodes[1]}, ${colorCodes[2]})`
  }

  const redirectForAuthentication = (encryptedId, newsForSubscription, newsId) => {
    if (userLoginStatus === false) {
      history.push({ pathname: ROUTE_STRINGS.login, state: { encryptedId, newsForSubscription, newsId, forGuestUser: true } })
    } else {
      history.push({ pathname: ROUTE_STRINGS.createAccount, state: { encryptedId, newsForSubscription, newsId, forGuestUser: true } })
    }
  }

  // Render Method to Update Error Message on News API Fail
  const MessageContentRenderer = ({ noNewsFeedImage, errorMessage }) => (
    <>
      <div className="search_on_page_load">{noNewsFeedImage && <img src={NoData} alt="NoDataFound" className="no_data_found_image" />}</div>
      {errorMessage && <div className="all-data-fetched">{errorMessage}</div>}
    </>
  )

  // TODO if Required

  // const handleModalDismiss = () => {
  //   setShowModal(false)
  //   setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: '', newsForSubscription: '' }))
  // }

  // const ForbiddenContentModal = () => (
  //   <Modal show={showModal}>
  //     <Modal.Header className="login-confirmation-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>

  //     <Modal.Body>
  //       <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
  //         <div>
  //           <h6 style={{ color: 'black' }}>{t('CONTENT_FORBIDDEN_MESSAGE')}</h6>
  //         </div>
  //         <div>
  //           <h6 style={{ color: 'black' }}>{t('CONTENT_FORBIDDEN_MESSAGE_CONFIRMATION')}</h6>
  //         </div>
  //       </div>
  //     </Modal.Body>
  //     <Modal.Footer>
  //       <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '20px' }}>
  //         <button id="noBtn" onClick={() => handleModalDismiss()} style={{ width: '100px', height: '36px', backgroundColor: 'red' }}>
  //           {t('CANCEL')}
  //         </button>
  //         <button id="yesBtn" onClick={() => redirectForAuthentication()} style={{ width: '100px', height: '36px', backgroundColor: '#50b3f6' }}>
  //           {t('CONTINUE')}
  //         </button>
  //       </div>
  //     </Modal.Footer>
  //   </Modal>
  // )

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      getGlobalSearchNews()
    }
  }

  const toolbarContents = (
    <div className="toolbar-container">
      <i className="pi pi-arrow-left arrow-icon" onClick={handleCloseSearchModal} />
      <h6 className="coffee-news-feed-heading">{t('COFFEE_NEWS_FEEDS')}</h6>
    </div>
  )

  return (
    <div>
      <MetaTagsComponent title={t('COFFEE_NEWS_FEED_TITLE')} description={t('COFFEE_NEWS_FEED_DESCRIPTION')} keywords={t('COFFEE_NEWS_FEED_KEYWORDS')} url="https://coffeeweb.com/coffeenewsfeeds" h1Content={t('COFFEE_NEWS_FEED_H1_CONTENT')} />
      <div className={`coffee_news_feeds content-wrapper restrictEverything ${location.search.includes('newsId') ? 'in-read-more' : ''}`} onContextMenu={(e) => e.preventDefault()}>
        <div className="feeds_container">
          <div className="news_feeds_card" style={{ margin: '0px' }} data-testid="guest-news-feed-main-card">
            {!location.search && (
              <>
                <div
                  className="fixed_coffee_news_feed stickyLanguageDropDown"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: '2',
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 2px',
                    position: 'fixed'
                  }}
                >
                  <div className="coffee_news_title">{t('COFFEE_NEWS_FEEDS')}</div>
                  <div className="headerSec2">
                    <div className="search_for_big_screen">
                      <div className="p-inputgroup">
                        <span className="p-input-icon-right">
                          {searchValue?.length > 0 ? <i className="pi pi-times p-input-clear-icon" onClick={handleSearchClear} /> : ''}
                          <i className="pi pi-search" data-testid="guest-web-search-icon" onClick={getGlobalSearchNews} />
                          <InputText value={searchValue} onChange={(e) => handleSearchExistingData(e)} onKeyDown={handleKeyPress} placeholder={t('SEARCH_FOR_NEWS')} />
                        </span>
                      </div>
                    </div>
                    <div className="language_and_search">
                      {allLanguages?.length > 0 ? <DropdownComponent isGuestUserPage={true} handleSelectCountry={handleSelectLanguage} countryList={allLanguages} currentItem={defaultNewsLanguage} setLanguageItem={setDefaultNewsLanguage} testId="guest-drop-down" isLanguageDropdown={true} width="100%" height="42px" className="guest-user" /> : <div className={`${userDetails ? 'loading-dropdown' : 'loading-dropdown guest-loading'}`}>{t('LOADING')}</div>}
                      <div className="buy_subscription_div">
                        <div className="buy_subscription_sub_div guest-user-background">
                          <NavLink activeClassName="active_nav-link" exact to={ROUTE_STRINGS.createAccount} className="buy_subscription_button">
                            {t('CREATE_ACCOUNT_BUTTON_LABEL')}
                          </NavLink>
                        </div>

                        <div
                          className="small_screen_search_div d-none"
                          onClick={() => {
                            handleSearchInput(setShowSearchPopup, setShowSearchPopupForBack, setSearchValue)
                          }}
                          data-testid="guest-mobile-screen-search-container"
                        >
                          <i className="pi pi-search mobile-screen-search-icon" data-testid="guest-mobile-screen-search-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`fixed_coffee_news_feed${isHidden ? ' hidden' : ' visible'}`} id="forScroll" style={{ zIndex: '1' }}>
                  <nav className="tabs_for_news_feed_screen">
                    <div className="tabs_nav_link">
                      <div className="tabs_nav_link_sub_div guest-user-background">
                        <i className="pi pi-calendar dailyFeed-icon" data-testid="guest-daily-feed-icon" />
                        {t('DAILY_FEED')}
                      </div>

                      <div onClick={() => history.push(ROUTE_STRINGS.login)} className="tab_daily_feed guest-mobile-view">
                        {t('LOGIN_BUTTON')}
                      </div>

                      <div onClick={() => redirectForAuthentication()} className="tab_daily_feed guest-large-view" data-testid="guest-my-fav-container">
                        <FilledHeartIcon className="filled_heart" data-testid="guest-my-fav-icon" />
                        {t('MY_FAVOURITE')}
                      </div>
                    </div>
                  </nav>
                </div>
              </>
            )}
            {location.search && <NewsReadMore isPage={isPage} redirectForAuthentication={redirectForAuthentication} newsId={newsId} handleNewsFalseModal={handleNewsFalseModal} setNewsFeedStatus={setNewsFeedStatus} />}

            <InfiniteScroll
              dataLength={coffeeNewsData?.length}
              next={getNewsOnInfiniteScroll}
              hasMore={hasMore}
              data-testid="infiniteScroll"
              loader={
                <>
                  {skeletonLoading && (
                    <div>
                      {skeletonArray.map((ele, index) => (
                        <div className="skeleton_for_full_screen" key={index} data-testid="skeleton">
                          <div data-testid="skeleton-tag">
                            <Skeleton shape="rectangle" className="skeleton_tag" height="225px" />
                          </div>
                          <div>
                            <Skeleton shape="rectangle" className="skeleton_tag" height="30px" />
                            <Skeleton shape="rectangle" className="skeleton_tag" height="150px" />
                            <div className="skeleton_div">
                              <Skeleton variant="rounded" className="skeleton_tag" height="30px" />
                              <Skeleton variant="rounded" className="skeleton_tag" height="30px" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              }
            >
              <div className="coffee_news_feeds content-wrapper restrictEverything" style={{ marginTop: '0px' }}>
                <div className="feeds_container">
                  <div className="news_feeds_card" data-testid="guest-mobile-view-news-feed-card">
                    <div className="outer-card">
                      {!showSearchPopup && (
                        <div className={`bottom_button bottomButtonScroll${isHidden ? ' slide-down' : ' down'}`}>
                          <div className="bottom_button_sub_div" onClick={handleGoToTop}>
                            <div className="bottom_button_icon_div">
                              <i className="pi pi-home home-icon" />
                            </div>
                          </div>
                          <div
                            className="bottom_button_search_bar_div"
                            onClick={() => {
                              handleSearchInput(setShowSearchPopup, setShowSearchPopupForBack, setSearchValue)
                            }}
                          >
                            <div className="search_text">{t('SEARCH')}</div>
                            <div className="search_icon_div">
                              <i className="pi pi-search search-icon" />
                            </div>
                          </div>
                        </div>
                      )}
                      {coffeeNewsData?.length > 0 && (
                        <div>
                          <div>
                            <div className="paginated" style={{ marginBottom: '10px' }}>
                              {coffeeNewsData?.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.customDate && (
                                    <div className="date_and_time_stamp col-md-12">
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                      <div className="date">{item.customDate}</div>
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                    </div>
                                  )}
                                  {
                                    <>
                                      {window.innerWidth > 767 && index < 1 && scrollPositionForData > 117 && (
                                        <div className={'date_tag_design col-md-12 '}>
                                          <div className="date_horizontal_line">.</div>
                                          <div className="date">{showStickyDate}</div>
                                          <div className="date_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {
                                    <>
                                      {window.innerWidth <= 767 && index < 1 && scrollPositionForData > 50 && (
                                        <div className={isHidden ? 'date_tag_for_big_screen col-md-12' : 'date_tag_design col-md-12'}>
                                          <div className="date_tag_horizontal_line">.</div>
                                          <div className="date">{showStickyDate}</div>
                                          <div className="date_tag_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {item.idNewsFavourite === null ? (
                                    ''
                                  ) : (
                                    <>
                                      <div key={index} className={`object-date sticky_date ${stickyDateList.includes('object-id') ? 'sticky' : ''}`} data-id={`${item.customDateWithDay}`}>
                                        <div className="image_div" data-testid="guest-image-container">
                                          <div className="data_for_mobile_screen" style={{ padding: '4px 9px 2px' }}>
                                            <div className="calendar-icon-container" data-testid="guest-mobile-view-calender-icon-container">
                                              <i className="pi pi-calendar calendar-icon" />
                                              {item?.customdate}
                                            </div>
                                            <div className="time-icon-container" data-testid="guest-mobile-view-time-icon-container">
                                              <i className="pi pi-clock icon-container time-icon" />
                                              {item?.customtime}
                                            </div>
                                          </div>
                                          {item?.nwsFeedMedia?.length !== 0 ? (
                                            <div className="video_div" data-testid="guest-video-container">
                                              {item?.nwsFeedMediaVideo?.length > 0 ? (
                                                <div className="video-container" style={{ margin: 'auto' }}>
                                                  <div key={item.id}>
                                                    <video
                                                      className="video_display"
                                                      src={item?.nwsFeedMediaVideo[0]?.pathOfMedia}
                                                      controls
                                                      ref={(ref) => {
                                                        videoRefs.current[index] = ref
                                                      }}
                                                      onPlay={() => handlePlay(index)}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="sliding_image_div" data-testid="guest-sliding-image-container">
                                                  {item?.nwsFeedMediaImage?.length > 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_five_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>{index < 5 && <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />}</div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_four_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" key={index} />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 3 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_three_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 2 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_two_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 1 && (
                                                    <div className="single_image_div" id="run">
                                                      {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                        <div key={index}>
                                                          <img
                                                            className="imgSize single_image"
                                                            style={{
                                                              width: '100%',
                                                              objectFit: 'contain',
                                                              padding: '8px',
                                                              borderRadius: '10px'
                                                            }}
                                                            src={ele.pathOfMedia}
                                                            alt="thumbnail"
                                                          />
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="news_media_div">
                                              <img src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.coloredLogo : devEnvLogo} className="static_image" alt="coffee-web-logo" />
                                            </div>
                                          )}
                                          <div className="data_for_mobile_screen " style={{ padding: '0px 9px 6px' }}>
                                            <div className="user-account-icon-container">
                                              <i className="pi pi-user icon-container account_icon" />
                                              <span className="author-text">{item?.author}</span>
                                            </div>

                                            <div className="visibility-icon-container">
                                              <div className="view-icon-container">
                                                <i className="pi pi-eye icon-container visibility-icon" /> {item?.views}
                                              </div>
                                              <div>
                                                <UnFilledHeartIcon className="heartLogoBlob cursor_pointer" onClick={() => redirectForAuthentication(item.encryptedId, item.newsForSubscription, item.id)} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="subSecForNews">
                                          <div>
                                            <div className="d-flex justify-content-between ">
                                              <div className="newsHeading cursor_pointer" onClick={() => navigateToNewsReadMoreScreen(item)} data-testid="guest-news-subject">
                                                <div>{ReactHtmlParser(item?.subject)}</div>
                                              </div>
                                            </div>
                                            <div className="newsHtml" data-testid="guest-news-share-text">
                                              {ReactHtmlParser(item?.shareText)}
                                            </div>
                                          </div>
                                          <div className="newsDetails" data-testid="guest-news-details-container">
                                            <div className="details">
                                              <div className="calendar-icon-container">
                                                <i className="pi pi-calendar icon-container" data-testid="guest-custom-date-icon" />
                                                {item?.customdate}
                                              </div>
                                              <div className="time-icon-container">
                                                <i className="pi pi-clock icon-container" data-testid="guest-custom-time-icon" />
                                                {item?.customtime}
                                              </div>
                                              <div className="visibility-icon-container">
                                                <i className="pi pi-eye icon-container" data-testid="guest-view-icon" />
                                                {item?.views}
                                              </div>
                                              <div className="user-account-icon-container">
                                                <i className="pi pi-user icon-container account-icon" data-testid="guest-author-icon" />
                                                <div className="news-content">
                                                  {item?.author?.substr(0, 9)}
                                                  {item?.author?.length > 9 && <span>...</span>}
                                                </div>
                                              </div>
                                              <div className="published_for_div">
                                                <div className="published_for_sub_div" data-testid="guest-subscription-code">
                                                  <span className="mr-2 d-none">Published for </span>
                                                  {item?.newsForSubName?.map((ele, index) => (
                                                    <React.Fragment key={index}>
                                                      {ele?.subscriptioncode && (
                                                        <span
                                                          className="eachLabeling"
                                                          style={{
                                                            backgroundSize: '400% 400%',
                                                            backgroundImage: getSubscriptionLabelColor(ele),
                                                            animation: 'gradient 7s ease infinite',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            padding: '1px 7px',
                                                            margin: '1px 2px'
                                                          }}
                                                        >
                                                          {ele?.subscriptioncode}
                                                        </span>
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="sub_labeling_main_div">
                                              <div className="sub_labeling">
                                                <span className="mr-2 d-none">Published for </span>
                                                {item?.newsForSubName?.map((ele, index) => (
                                                  <React.Fragment key={index}>
                                                    {ele?.subscriptioncode && (
                                                      <span
                                                        className="eachLabeling"
                                                        style={{
                                                          backgroundSize: '400% 400%',
                                                          backgroundImage: `linear-gradient(100deg, ${ele?.subscriptionColor?.slice(0, 7)}, ${ele?.subscriptionColor?.slice(9, 16)}, ${ele?.subscriptionColor?.slice(18, 27)})`,
                                                          animation: 'gradient 7s ease infinite',
                                                          color: 'white',
                                                          borderRadius: '4px'
                                                        }}
                                                      >
                                                        {ele?.subscriptioncode}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </div>
                                              <div className="heart_pulse_logo_for_read_more">
                                                <UnFilledHeartIcon className="heartLogoBlob cursor_pointer" onClick={() => redirectForAuthentication(item.encryptedId, item.newsForSubscription, item.id)} data-testid="guest-heart-icon" />
                                              </div>
                                              <div>
                                                <Button buttonType={'CUSTOM'} buttonLabel={t('READ_MORE')} buttonSize={'x-small'} buttonWidth={'full'} onButtonClick={() => navigateToNewsReadMoreScreen(item)} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
            <div className="full_Stop">.</div>

            {/* searchPopup for news */}
            {showSearchPopup && (
              <div className="search_popup_for_news" data-testid="guest-search-popup-news">
                <Toolbar className="app-bar" start={toolbarContents} />

                <div className="input_field">
                  <div className="p-inputgroup">
                    <span className="p-input-icon-right">
                      {searchValue ? <i className="pi pi-times p-input-clear-icon" onClick={handleSearchClear} /> : ''}
                      <i className="pi pi-search" onClick={getGlobalSearchNews} />
                      <InputText value={searchValue} onChange={(e) => handleSearchExistingData(e)} placeholder={t('SEARCH_FOR_NEWS')} onKeyDown={handleKeyPress} />
                    </span>
                  </div>
                </div>
                <Divider className="divider-style" />

                <div className="search_trending_data" data-testid="guest-mobile-view-search-trending-data" id="scrollableDiv">
                  <InfiniteScroll dataLength={coffeeNewsData?.length} scrollableTarget="scrollableDiv" next={coffeeNewsData?.length >= pageSize && getNewsOnInfiniteScroll} hasMore={hasMore} data-testid="infinite-Scroll" loader={''}>
                    {coffeeNewsData?.map((item, index) => (
                      <div key={index}>
                        <div className="search_trending_data_sub_div" data-testid="search-trending-news-container-for-guest">
                          <div>{ReactHtmlParser(item.subject)}</div>
                          <i className="pi pi-arrow-right search-forward-arrow" onClick={() => redirectForAuthentication(item.encryptedId, item.newsForSubscription, item.id)} data-testid="guest-search-forward-icon" />
                        </div>
                        <Divider className="divider-style" data-testid="divider-mobile-view" />
                      </div>
                    ))}
                    <div className="search_trending_data_full_stop">.ssw</div>
                  </InfiniteScroll>
                </div>
              </div>
            )}
          </div>

          {coffeeNewsData?.length === 0 && searchValue && !errorMessage && !noNewsFeedImage && (
            <div className="date_and_time_stamp search_news_not_found col-md-12">
              <div className="date search_news_not_found_sub_Div" onClick={getGlobalSearchNews}>
                {t('SEE_MORE')}
              </div>
            </div>
          )}

          <MessageContentRenderer noNewsFeedImage={noNewsFeedImage} errorMessage={errorMessage} />

          {/* <ForbiddenContentModal /> TODO if Required */}
        </div>
      </div>
    </div>
  )
}

export default GuestNewsFeed
