import './styles.scss'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export default function MetaTagsComponent({ title, description, keywords, url, h1Content }) {
  const { t } = useTranslation()

  return (
    <div>
      <Helmet defaultTitle={t('DEFAULT_COFFEE_WEB_TITLE')}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={url} />
      </Helmet>
      <h1 className="meta-tag-h1">{h1Content}</h1>
    </div>
  )
}
