import React, { createContext, useState } from 'react'

export const CoffeeWebContext = createContext()
export const CoffeeWebContextProvider = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [isCustomLoading, setIsCustomLoading] = useState('')
  const [key, setKey] = useState(0)

  const values = { isLoading, setLoading, key, setKey, isCustomLoading, setIsCustomLoading }

  return <CoffeeWebContext.Provider value={values}>{props.children}</CoffeeWebContext.Provider>
}
