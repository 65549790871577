import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import { popupIcon } from 'Assets/Icons'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

const Brazil = ({ swapReport, setLoading, mockBrazilData, mockBrazilModal, createMonthRange, checkForUserAccess }) => {
  const { t } = useTranslation()
  const toast = useRef(null)
  const { brazilCountryWiseExportsReport } = coffeeReportJsonLinks

  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)

  useEffect(() => {
    fetchData()

    if (mockBrazilData) {
      setFetchedData(mockBrazilData)
      setShowModal(mockBrazilModal)
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)

    const reportName = brazilCountryWiseExportsReport

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      arabica: 0,
      robusta: 0,
      soluble: 0,
      roasted: 0
    }

    countryCertifiedData.forEach(({ arabica, robusta, soluble, roasted }) => {
      totals.arabica += Number(arabica) || 0
      totals.robusta += Number(robusta) || 0
      totals.soluble += Number(soluble) || 0
      totals.roasted += Number(roasted) || 0
    })

    return totals
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      arabica: 0,
      robusta: 0,
      soluble: 0,
      roasted: 0,
      month: ''
    }

    data.forEach(({ arabica, robusta, soluble, roasted, month }) => {
      totals.arabica += Number(arabica) || 0
      totals.robusta += Number(robusta) || 0
      totals.soluble += Number(soluble) || 0
      totals.roasted += Number(roasted) || 0

      if (month) {
        totals.month = month
      }
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result) || ''
  }

  const convertToMillion = (value) => {
    if (!value || Number.isNaN(Number(value))) {
      return ''
    }

    const result = value / 60000

    return result.toFixed(4)
  }

  const valueForBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }

    return Math.round(valueInMT) || ''
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (yearIndex, rowIndex) => {
    const currentYearData = fetchedData[yearIndex]
    const prevYearData = fetchedData[yearIndex + 1] || null

    const hasCurrentYearValue = currentYearData?.report[rowIndex].arabica
    const hasPrevYearValue = prevYearData?.report[rowIndex].arabica

    if (!prevYearData || !hasCurrentYearValue || !hasPrevYearValue) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })
    } else {
      const currentYearMonthData = currentYearData?.report[rowIndex]
      const prevYearMonthData = prevYearData?.report[rowIndex]

      const currentYearDate = currentYearData?.year.split(' : ')[1]
      const previousYearDate = prevYearData?.year.split(' : ')[1]

      setCurrentData(currentYearMonthData)
      setPreviousData(prevYearMonthData)
      setCurrentYear(currentYearDate)
      setPreviousYear(previousYearDate)
      setModalType('month')
      setShowModal(true)
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.robusta && ele.arabica)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredRowIndex(null)
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const displayValue = (value) => {
      if (swapReport === 'Metric Tons') {
        return valueForBags(value).toLocaleString()
      }

      if (swapReport === '60 KG Bags') {
        return convertMTToBags(value).toLocaleString()
      }

      if (swapReport === 'Millions Bags') {
        return convertToMillion(value)
      }

      return valueForBags(value).toLocaleString()
    }

    const sumOfCurrentReports = currentData ? Number(currentData.arabica) + Number(currentData.robusta) + Number(currentData.soluble) + Number(currentData.roasted) : 0
    const sumOfPreviousReports = previousData ? Number(previousData.arabica) + Number(previousData.robusta) + Number(previousData.soluble) + Number(previousData.roasted) : 0

    const difference = {
      arabica: Number(currentData.arabica) - Number(previousData.arabica) || 0,
      robusta: Number(currentData.robusta) - Number(previousData.robusta) || 0,
      soluble: Number(currentData.soluble) - Number(previousData.soluble) || 0,
      roasted: Number(currentData.roasted) - Number(previousData.roasted) || 0,
      total: sumOfCurrentReports - sumOfPreviousReports
    }

    const percentageDifference = {
      arabica: previousData.arabica ? ((difference.arabica / Number(previousData.arabica)) * 100).toFixed(2) : 'N/A',
      robusta: previousData.robusta ? ((difference.robusta / Number(previousData.robusta)) * 100).toFixed(2) : 'N/A',
      soluble: previousData.soluble ? ((difference.soluble / Number(previousData.soluble)) * 100).toFixed(2) : 'N/A',
      roasted: previousData.roasted ? ((difference.roasted / Number(previousData.roasted)) * 100).toFixed(2) : 'N/A',
      total: sumOfPreviousReports ? ((difference.total / sumOfPreviousReports) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.month?.split('-')[0]
    const previousMonth = previousData.month?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `Upto ${currentMonthYear} To ${previousMonthYear} Comparison` : `${currentMonthYear} To ${previousMonthYear} Comparison`

    return (
      <div className="comparison-table" data-testid="month-comparison-dialog-modal">
        <div className="content-title" data-testid="comparison-title">
          <h5>{dynamicTitle}</h5>
        </div>

        <div className="table-container">
          <div className="left-table-content">
            <table>
              <thead>
                <tr>
                  <th>{t('MONTH')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="empty-table-row"></tr>

                <tr onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 0 ? 'highlight' : ''} data-testid="current-data-month">
                  <td>{modalType === 'total' ? createMonthRange(currentData.month) : currentData.month}</td>
                </tr>

                <tr onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 1 ? 'highlight' : ''} data-testid="previous-data-month">
                  <td> {modalType === 'total' ? createMonthRange(previousData.month) : previousData.month}</td>
                </tr>

                <tr className="empty-table-row"></tr>

                <tr className="difference-row">
                  <td className="table-border">{t('DIFFERENCE')}</td>
                </tr>
                <tr className="percentage-row">
                  <td className="table-border">{t('PERCENTAGE_WISE')}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="right-table-content" data-testid="comparison-content-table">
            <table>
              <thead>
                <tr>
                  <th>{t('ARABICA_COFFEE_REPORTS')}</th>
                  <th>{t('ROBUSTA_COFFEE_REPORTS')}</th>
                  <th>{t('SOLUBLE')}</th>
                  <th>{t('ROASTED')}</th>
                  <th>{t('TOTAL')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="empty-table-row"></tr>

                <tr onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 0 ? 'highlight' : ''} data-testid="current-comparison-content-value-container">
                  <td data-testid="current-rounded-arabica-value">{displayValue(currentData.arabica)}</td>
                  <td data-testid="current-rounded-robusta-value">{displayValue(currentData.robusta)}</td>
                  <td data-testid="current-rounded-soluble-value">{displayValue(currentData.soluble)}</td>
                  <td data-testid="current-rounded-roasted-value">{displayValue(currentData.roasted)}</td>
                  <td data-testid="current-rounded-reports-value">{displayValue(sumOfCurrentReports)}</td>
                </tr>

                <tr onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 1 ? 'highlight' : ''} data-testid="previous-comparison-content-value-container">
                  <td data-testid="previous-rounded-arabica-value">{displayValue(previousData.arabica)}</td>
                  <td data-testid="previous-rounded-robusta-value">{displayValue(previousData.robusta)}</td>
                  <td data-testid="previous-rounded-soluble-value">{displayValue(previousData.soluble)}</td>
                  <td data-testid="previous-rounded-roasted-value">{displayValue(previousData.roasted)}</td>
                  <td data-testid="previous-rounded-reports-value">{displayValue(sumOfPreviousReports)}</td>
                </tr>

                <tr className="empty-table-row"></tr>
                <tr className="difference-row" data-testid="difference-row-container">
                  <td className={`${getTextColor(difference.arabica)} table-border`}>{displayValue(difference.arabica)}</td>
                  <td className={`${getTextColor(difference.robusta)} table-border`}>{displayValue(difference.robusta)}</td>
                  <td className={`${getTextColor(difference.soluble)} table-border`}>{displayValue(difference.soluble)}</td>
                  <td className={`${getTextColor(difference.roasted)} table-border`}>{displayValue(difference.roasted)}</td>
                  <td className={`${getTextColor(difference.total)} table-border`}>{displayValue(difference.total)}</td>
                </tr>
                <tr className="percentage-row" data-testid="percentage-row-container">
                  <td className={`${getTextColor(percentageDifference.arabica)} table-border`}>{percentageDifference.arabica !== 'N/A' ? `${percentageDifference.arabica}%` : 'N/A'}</td>
                  <td className={`${getTextColor(percentageDifference.robusta)} table-border`}>{percentageDifference.robusta !== 'N/A' ? `${percentageDifference.robusta}%` : 'N/A'}</td>
                  <td className={`${getTextColor(percentageDifference.soluble)} table-border`}>{percentageDifference.soluble !== 'N/A' ? `${percentageDifference.soluble}%` : 'N/A'}</td>
                  <td className={`${getTextColor(percentageDifference.roasted)} table-border`}>{percentageDifference.roasted !== 'N/A' ? `${percentageDifference.roasted}%` : 'N/A'}</td>
                  <td className={`${getTextColor(percentageDifference.total)} table-border`}>{percentageDifference.total !== 'N/A' ? `${percentageDifference.total}%` : 'N/A'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report" data-testid="country-wise-data-brazil-component">
      {fetchedData?.map(({ report, year }, yearIndex) => {
        const { arabica, robusta, soluble, roasted } = calculateTotals(report)
        const roundedArabica = arabica
        const roundedRobusta = robusta
        const roundedSoluble = soluble
        const roundedRoasted = roasted

        const sumOfTotalReports = roundedArabica + roundedRobusta + roundedSoluble + roundedRoasted

        const displayTotalValue = (value) => {
          if (swapReport === 'Metric Tons') {
            return Math.round(value).toLocaleString()
          }
          if (swapReport === '60 KG Bags') {
            return convertMTToBags(value).toLocaleString()
          }
          if (swapReport === 'Millions Bags') {
            return convertToMillion(value)
          }
        }

        return (
          <div key={yearIndex} className="table-wrapper" data-testid="brazil-table-wrapper">
            <table>
              <thead>
                <tr className="year-row" data-testid="year-row-container">
                  <td colSpan={6}>
                    <div>{year}</div>
                  </td>
                </tr>

                <tr className="sticky-row" data-testid="sticky-row-container">
                  <th className="month">{t('MONTH')}</th>
                  <th className="arabica">{t('ARABICA_COFFEE_REPORTS')}</th>
                  <th className="roubsta">{t('ROBUSTA_COFFEE_REPORTS')}</th>
                  <th className="soluble">{t('SOLUBLE')}</th>
                  <th className="roasted">{t('ROASTED')}</th>
                  <th className="total">{t('TOTAL')}</th>
                </tr>

                <tr className="table-row-empty"></tr>
              </thead>
              <tbody style={checkForUserAccess(menuLock)}>
                {report.map(({ month, arabica, robusta, soluble, roasted }, rowIndex) => {
                  const roundedArabica = arabica
                  const roundedRobusta = robusta
                  const roundedSoluble = soluble
                  const roundedRoasted = roasted

                  const sumOfReports = roundedArabica + roundedRobusta + roundedSoluble + roundedRoasted

                  const displayValue = (value) => {
                    if (swapReport === 'Metric Tons') {
                      return valueForBags(Math.round(value)).toLocaleString()
                    }

                    if (swapReport === '60 KG Bags') {
                      return convertMTToBags(value).toLocaleString()
                    }

                    if (swapReport === 'Millions Bags') {
                      return convertToMillion(value)
                    }

                    return valueForBags(value).toLocaleString()
                  }

                  return (
                    <React.Fragment key={rowIndex}>
                      <tr>
                        <td>
                          <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showMonthValueModal(yearIndex, rowIndex)} data-testid="popup-link-container">
                            <div data-testid="month-container">{month}</div>
                            <div className="icon-wrapper" data-testid="icon-container">
                              <img src={popupIcon} alt={month} className="popup-icon" title="popup-icon-image" />
                            </div>
                          </div>
                        </td>
                        <td data-testid="rounded-arabica-value">{displayValue(roundedArabica) || ''} </td>
                        <td data-testid="rounded-robusta-value">{displayValue(roundedRobusta) || ''}</td>
                        <td data-testid="rounded-soluble-value">{displayValue(roundedSoluble) || ''}</td>
                        <td data-testid="rounded-roasted-value">{displayValue(roundedRoasted) || ''}</td>
                        <td data-testid="rounded-reports-value">{displayValue(sumOfReports) || ''}</td>
                      </tr>
                    </React.Fragment>
                  )
                })}
                <tr className="no-data-row"></tr>
                <tr className="tr-color" data-testid="total-row-container">
                  <td>
                    <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showTotalValueModal(year)} data-testid="total-popup-container">
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper" data-testid="total-icon-container">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" title="total-icon-image" />
                      </div>
                    </div>
                  </td>
                  <td data-testid="total-rounded-arabica-value">{displayTotalValue(roundedArabica)}</td>
                  <td data-testid="total-rounded-robusta-value">{displayTotalValue(roundedRobusta)}</td>
                  <td data-testid="total-rounded-soluble-value">{displayTotalValue(roundedSoluble)}</td>
                  <td data-testid="total-rounded-roasted-value">{displayTotalValue(roundedRoasted)}</td>
                  <td data-testid="total-rounded-reports-value">{displayTotalValue(sumOfTotalReports)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" testId="brazil-country" />
    </div>
  )
}

export default Brazil
