import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'Components/UIComponent/Button'
import { IMAGE_SOURCE_URLS } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'

const SubscriptionCard = ({ subscription, selectPlan }) => {
  const { t } = useTranslation()
  const colorSlice = subscription.subscriptionColor.slice(0, 7)
  const userDetails = getLocalUserDetails()

  const { dateFormatBasedOnUser } = commonMethods

  const validityFormatter = (date) => {
    const [validityString, validTill] = date.split(':').map((el) => el.trim())

    return `${validityString}: ${dateFormatBasedOnUser(validTill, 'spaceFormat')}`
  }

  const subscriptionCard = {
    '--subscription-color': colorSlice
  }
  const gradientBackground = {
    backgroundSize: '400% 400%',
    backgroundImage: `linear-gradient(100deg, ${subscription?.subscriptionColor.slice(0, 7)}, ${subscription?.subscriptionColor.slice(9, 16)}, ${subscription?.subscriptionColor.slice(18, 27)})`
  }

  return (
    <div className="subscription-main-card" id={subscription.isHighlight ? 'highlightedSubscriptionCard' : ''} data-testid="subscription-main-container">
      <div className="subscription-card" style={subscriptionCard} data-testid="subscription-card-container">
        <div className="Subscription-card-header" style={gradientBackground} data-testid="subscription-header-container">
          <>
            {userDetails.sub.subType === subscription.id && (
              <div className="card-box" data-testid="subscription-card-badge">
                <span></span>
              </div>
            )}
          </>
          <div>
            {' '}
            <h3 className="subscription-name" data-testid="subscription-name-container">
              {subscription.name}
            </h3>
            {
              <div className=" subscription-basePrice">
                {subscription.basePrice === 0 ? (
                  <h5>{t('FREE')}</h5>
                ) : (
                  <>
                    {userDetails?.sub?.subType === subscription.id ? (
                      <div>
                        <div className="validity-day" data-testid="validity-container">
                          <div className="per-month" data-testid="per-month-container">
                            <>
                              {userDetails.countryId === 102 ? (
                                <>
                                  {subscription.currencyType} {subscription.basePrice}/- {t('PER_MONTH')}{' '}
                                </>
                              ) : (
                                <>
                                  $ {subscription.amountInDollars}/- {t('PER_MONTH')}
                                </>
                              )}
                            </>
                          </div>
                          <div className="validity-Detail" data-testid="validity-days-and-date-container">
                            <div data-testid="validity-days-container">{userDetails.sub?.subscriptionValidTill}</div>
                            <div data-testid="validity-date-container">{validityFormatter(userDetails.sub?.subscriptionValidTillDate)}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h5 className=" subscription-currency" data-testid="available-subscription-card-container">
                        {subscription.basePrice !== 0 && (
                          <>
                            {userDetails.countryId === 102 ? (
                              <>
                                {subscription.currencyType} {subscription.basePrice}/- {t('PER_MONTH')}{' '}
                              </>
                            ) : (
                              <>
                                $ {subscription.amountInDollars}/- {t('PER_MONTH')}
                              </>
                            )}
                          </>
                        )}
                      </h5>
                    )}
                  </>
                )}
              </div>
            }
          </div>
        </div>
        <div className=" upgrade-main" data-testid="button-containers">
          {subscription.basePrice === 0 ? (
            <div className="upgrade-container" data-testid="subscription-upgrade-container">
              <Button buttonType={'CUSTOM'} buttonLabel={t('YOU_CAN_UPGRADE')} variant={'soft-action'} buttonSize={'small'} />
            </div>
          ) : (
            <div className="renew-container" data-testid="subscription-renew-and-buy-container">
              {userDetails.sub.subType === subscription.id ? <Button buttonType={'CUSTOM'} buttonLabel={t('RENEW')} buttonSize={'small'} buttonWidth={'full'} onButtonClick={() => selectPlan(subscription)} /> : <Button buttonType={'CUSTOM'} buttonLabel={t('BUY_NOW')} buttonSize={'small'} buttonWidth={'full'} onButtonClick={() => selectPlan(subscription)} />}
            </div>
          )}
        </div>
      </div>
      <div className="subscription-card subscription-features" style={subscriptionCard} data-testid="subscription-card-features-container">
        <div className="features-list" data-testid="features-list-container">
          <div className="features-container" data-testid="features-container">
            {subscription.subscriptionFeatures.map((subItems, index) => (
              <div key={index} className="feature-item" data-testid="feature-item">
                <div className="subscription-line" style={gradientBackground} data-testid="subscription-feature-container">
                  {subItems.isAvailable === true ? <img src={IMAGE_SOURCE_URLS.checkImage} alt="Check" className="subscription-icon check" title="feature-isAvailable-image-tag" /> : <img src={IMAGE_SOURCE_URLS.cancelImage} alt="Cancel" className="subscription-icon cancel" title="feature-isAvailable-false-image-tag" />}
                  <div className="feature-Description" data-testid="feature-Description">
                    {subItems.featureDescription}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard
