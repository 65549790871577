import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const FreeMenubar = ({ guestUserMenus }) => {
  const { isLoading } = useContext(CoffeeWebContext)

  return (
    <>
      <div className={`post_header ${isLoading ? 'is-loading' : ''}`} data-testid="menu-main-container">
        <div className="" id="navbar-main" data-testid="navbar-main-container">
          <div>
            <div aria-controls="basic-navbar-nav" />
            <div id="basic-navbar-nav" data-testid="basic-navbar-container">
              <ul className="navbar-nav" data-testid="navbar-ul-container">
                {guestUserMenus?.length &&
                  guestUserMenus?.map((ele, index) => (
                    <div key={index}>
                      {ele.isFreeMenuWebDisplayEnable === true && ele.isLaunch === true && ele.menuUrl !== null && (
                        <li className="nav-item1 menucolor1" id="navitem" data-testid="navbar-li-container">
                          <NavLink activeClassName="active_nav-link1" exact to={ele.menuUrl === 'CoffeeQuotes' ? ROUTE_STRINGS.guestCoffeeQuotes : ele.menuUrl} className="nav-link1 nav-link-ltr">
                            <div style={{ padding: '0px 5px' }} data-testid="menu-name-container">
                              {ele.menuName}
                            </div>
                          </NavLink>
                        </li>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreeMenubar
