import '../styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import { updateUserDetails, getLocalUserDetails, setLocalWithAppLanguage } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { Closebtn, EyeFilled, EyeFilledOff } from 'Assets/Icons'
import Validation from 'Utils/Validation'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import Button from 'Components/UIComponent/Button'
import languageCode from 'Utils/languageCode'
import { useTranslation } from 'react-i18next'
import { commonMethods } from 'Utils/commonMethods'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

function ProfileModal({ showSuccess, showError, showConfirmation, ...props }) {
  const history = useHistory()
  const { setModalShow, mockPhoneNumber, mockDateOfBirth, mockFirstName, mockLastName, mockEmail, mockApi, mockActiveTab } = props
  const userDetails = getLocalUserDetails()
  const tabID = window.sessionStorage.getItem('tabid')
  const dateFormatData = userDetails?.userDateFormat
  let timerId

  const { handleUserLogout, refreshLocalStorage } = commonMethods
  const { setLoading, setKey } = useContext(CoffeeWebContext)
  const { i18n, t } = useTranslation()
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [landingPage, setLandingPage] = useState([])
  const [dateFormat, setDateformat] = useState([])
  const [timeFormat, setTimeFormat] = useState([])
  const [timeZone, setTimeZone] = useState([])
  const [allLanguages, setAllLanguages] = useState([])
  const [languageState, setLanguageState] = useState('')
  const [inputValues, setInputValues] = useState({
    dateFormat: userDetails?.userDateFormat,
    timeFormat: userDetails?.userTimeFormat,
    Languages: userDetails?.languageId,
    landingPage: userDetails?.userLandingPageUrl,
    timeZone: userDetails?.idtimezone,
    showWebNotification: userDetails?.showWebNotification
  })

  const [errorStateDateFormat, setErrorStateForDateFormat] = useState(false)
  const [errorStateForTimeFormat, setErrorStateForTimeFormat] = useState(false)
  const [errorStateForLanguages, setErrorStateForLanguages] = useState(false)
  const [errorStateForLandingPage, setErrorStateForLandingPage] = useState(false)
  const [errorStateForTimeZone, setErrorStateForTimeZone] = useState(false)

  const [userInputValues, setUserInputValue] = useState({
    currentPassword: 'NA',
    password: '',
    confirmPassword: ''
  })
  const [ErrorState, setErrorState] = useState({
    wrongPassword: false,
    currentPassword: false,
    password: false,
    confirmPassword: false
  })
  const [activeTab, setActiveTab] = useState('myInfo')
  const [stateList, setStateList] = useState([])
  const [errorMessage, setErrorMessage] = useState({
    firstName: false,
    lastName: false,
    dob: false,
    occupation: false,
    country: false,
    phone: false,
    email: false
  })

  const [allUserDetails, setAllUserDetails] = useState([])
  const [dataExistOrNot, setDataExistOrNot] = useState({})
  const [occupation, setOccupation] = useState([])
  const [allCountries, setAllCountries] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([])
  const [countryPhoneCode, setCountryPhoneCode] = useState('')

  const isPhoneDisabled = userDetails?.googlesignid === 0
  const isEmailDisabled = userDetails?.googlesignid >= 1

  useEffect(() => {
    init()
    // this if condition is initialize for testing purpose
    if (mockPhoneNumber) {
      setErrorMessage({ ...errorMessage, phone: mockPhoneNumber, dob: mockDateOfBirth, firstName: mockFirstName, lastName: mockLastName, email: mockEmail })
      setActiveTab(mockActiveTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    setLoading(true)
    const getAllCountries = apiAdapterCoffeeWeb.getAllCountries()
    const getAllOccupation = apiAdapterCoffeeWeb.occupationType()
    const getUserByUserId = apiAdapterCoffeeWeb.getUserByUserId(userDetails?.id)
    const userSettings = apiAdapterCoffeeWeb.getProfileSettingMasters()

    try {
      const [getAllCountriesResponse, allOccupationResponse, getUserDetailsResponse, userSettingsResponse] = await Promise.all([getAllCountries, getAllOccupation, getUserByUserId, userSettings])

      setAllCountries(getAllCountriesResponse.data)

      setOccupation(allOccupationResponse.data.returnLst)

      getStateLists(getUserDetailsResponse.data.countryId)
      setAllUserDetails(getUserDetailsResponse.data)
      setDataExistOrNot(getUserDetailsResponse.data)
      setCountryPhoneCode(getUserDetailsResponse.data.countryPhoneCode)

      const data = getUserDetailsResponse.data.languageId
      const languageCodeValue = languageCode[data]

      setLocalWithAppLanguage(languageCodeValue)
      i18n.changeLanguage(languageCodeValue)

      setLandingPage(userSettingsResponse.data?.landingpage)
      setDateformat(userSettingsResponse.data?.dateFormat)
      setTimeFormat(userSettingsResponse.data?.timeFormat)
      setTimeZone(userSettingsResponse.data?.timeZone)
      setAllLanguages(userSettingsResponse.data?.languages)
      setPaymentHistory(getUserDetailsResponse.data?.paymentList)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = async () => {
    setLoading(true)

    try {
      await handleUserLogout()
      setModalShow(false)
      history.push({ pathname: ROUTE_STRINGS.guestCoffeeQuotes })
    } finally {
      setLoading(false)
    }
  }

  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown)
  }
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown)
  }
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'currentPassword') {
      setUserInputValue({
        ...userInputValues,
        currentPassword: value
      })
    } else if (name === 'password') {
      setUserInputValue({
        ...userInputValues,
        password: value
      })
      if (value.length >= 8) {
        setErrorState({
          ...ErrorState,
          password: false
        })
        if (value === userInputValues.confirmPassword) {
          setErrorState({
            ...ErrorState,
            confirmPassword: false
          })
        }
      } else {
        setErrorState({
          ...ErrorState,
          password: true
        })
      }
    } else if (name === 'confirmPassword') {
      setUserInputValue({
        ...userInputValues,
        confirmPassword: value
      })
      if (value === userInputValues.password) {
        setErrorState({
          ...ErrorState,
          confirmPassword: false
        })
      }
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'password') {
      if (value.length < 8) {
        setErrorState({
          ...ErrorState,
          password: true
        })
      } else {
        setErrorState({
          ...ErrorState,
          password: false
        })
      }
    }
    if (name === 'phoneNo') {
      if (value.length === 10) {
        setErrorState({
          ...ErrorState,
          phoneNo: false
        })
      } else {
        setErrorState({
          ...ErrorState,
          phoneNo: true
        })
      }
    }
  }

  const handleValidation = () => {
    const { currentPassword, password, confirmPassword } = userInputValues

    if (Validation.checkEmptySomeInputField(currentPassword, password, confirmPassword)) {
      showError({
        title: t('ERROR_TITLE'),
        message: t('PLEASE_FILL_ALL_THE_DETAILS'),
        buttonLabel: t('OK')
      })
    } else if (Validation.checkAllInputFieldsNotEmpty(currentPassword, password, confirmPassword) && password !== confirmPassword) {
      showError({
        title: t('ERROR_TITLE'),
        message: t('PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME'),
        buttonLabel: t('OK')
      })
    } else if (Validation.checkAllInputFieldsNotEmpty(currentPassword, password) && password === confirmPassword) {
      setLoading(true)
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    const { password } = userInputValues

    try {
      const response = await apiAdapterCoffeeWeb.changePassword({
        id: userDetails?.id,
        password,
        currentPassword: 'NA',
        isGoogleSignIn: true
      })

      if (response.status === 200) {
        if (response.data.isError === true) {
          setLoading(false)

          showError({
            title: t('ERROR_TITLE'),
            message: response.data.error,
            buttonLabel: t('OK')
          })
        } else {
          setLoading(false)

          showSuccess({
            title: t('SUCCESS'),
            message: t('PASSWORD_CHANGED_SUCCESSFULLY'),
            buttonLabel: t('OK'),
            onConfirm: logoutInitiated,
            onCancel: logoutInitiated
          })

          timerId = setTimeout(() => {
            handleLogout()
          }, 5000)
        }
      }
    } catch (error) {
      setLoading(false)

      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    }
  }

  const handleChanges = (e) => {
    const { name, value } = e.target

    if (name === 'dateFormat') {
      setInputValues({
        ...inputValues,
        dateFormat: value
      })
      setErrorStateForDateFormat(false)
    } else if (name === 'timeFormat') {
      setInputValues({
        ...inputValues,
        timeFormat: value
      })
      setErrorStateForTimeFormat(false)
    } else if (name === 'Languages') {
      const languageCodeValue = languageCode[value]

      if (languageCodeValue) {
        setLanguageState(languageCodeValue)
        i18n.changeLanguage(languageCodeValue)
      }

      setInputValues({
        ...inputValues,
        Languages: value
      })
      setErrorStateForLanguages(false)
    } else if (name === 'landingPage') {
      setInputValues({
        ...inputValues,
        landingPage: value
      })
      setErrorStateForLandingPage(false)
    } else if (name === 'timeZone') {
      setInputValues({
        ...inputValues,
        timeZone: value
      })
      setErrorStateForTimeZone(false)
    }
  }

  const toggle = () => {
    if (inputValues.showWebNotification === true) {
      setInputValues({
        ...inputValues,
        showWebNotification: false
      })
    } else {
      setInputValues({
        ...inputValues,
        showWebNotification: true
      })
    }
  }
  const handleSaveButton = () => {
    setLocalWithAppLanguage(languageState)
    if (inputValues.dateFormat === '') {
      setErrorStateForDateFormat(true)
    } else if (inputValues.timeFormat === '') {
      setErrorStateForTimeFormat(true)
    } else if (inputValues.Languages === '') {
      setErrorStateForLanguages(true)
    } else if (inputValues.landingPage === '') {
      setErrorStateForLandingPage(true)
    } else {
      setLoading(true)
      updateUserSettings()
    }
  }

  const updateUserSettings = async () => {
    try {
      await apiAdapterCoffeeWeb.updateUserProfileSettings(userDetails.id, inputValues)

      setLoading(false)

      showSuccess({
        title: t('SUCCESS'),
        message: t('DATA_UPDATED_SUCCESSFULLY'),
        buttonLabel: t('OK'),
        onConfirm: () => setKey((prev) => prev + 1),
        onCancel: () => setKey((prev) => prev + 1)
      })
      updateUserDetails()
    } catch (error) {
      setLoading(false)

      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    }
  }

  const changeMyInformation = (event) => {
    const { name, value, id } = event.target
    const eachOccupationName = occupation.filter((ele) => ele.idOccupationType === +value)

    if (name === 'firstName') {
      setAllUserDetails({
        ...allUserDetails,
        firstName: value
      })
      setErrorMessage(false)
    }

    if (name === 'lastName') {
      setAllUserDetails({
        ...allUserDetails,
        LastName: value
      })
      setErrorMessage(false)
    }

    if (name === 'email') {
      setAllUserDetails({
        ...allUserDetails,
        email: value
      })

      if (Validation.isInputFieldEmpty(value)) {
        setErrorMessage({
          ...errorMessage,
          email: false,
          emailnotvalid: false
        })
      } else {
        setErrorMessage({
          ...errorMessage,
          email: false,
          emailnotvalid: false
        })
      }
    }

    if (name === 'phone') {
      if (Validation.isInputFieldEmpty(value)) {
        setAllUserDetails({
          ...allUserDetails,
          phone: value
        })
        setErrorMessage({
          ...errorMessage,
          phone: false
        })
      } else if (Validation.phoneNumberValidation(value) && value.length <= 15) {
        setAllUserDetails({
          ...allUserDetails,
          phone: value
        })

        if (value.length < 7 || value.length > 15) {
          setErrorMessage({
            ...errorMessage,
            phone: true
          })
        } else {
          setErrorMessage({
            ...errorMessage,
            phone: false
          })
        }
      } else {
        setErrorMessage({
          ...errorMessage,
          phone: true
        })
      }
    }

    if (name === 'dob') {
      setAllUserDetails({
        ...allUserDetails,
        doB: value
      })
      if (Validation.isInputFieldEmpty(value)) {
        setErrorMessage({
          ...errorMessage,
          dob: true
        })
      } else {
        setErrorMessage({
          ...errorMessage,
          dob: false
        })
      }
    }
    if (id === 'occupationId') {
      setAllUserDetails({
        ...allUserDetails,
        [id]: value,
        occupationName: eachOccupationName[0].occupationTypeName
      })
      setErrorMessage(false)
    }
    if (name === 'country') {
      setAllUserDetails({
        ...allUserDetails,
        countryId: value
      })
      setErrorMessage(false)
    }

    if (name === 'state') {
      setAllUserDetails({
        ...allUserDetails,
        idState: value
      })
    }
  }

  const myInformationSend = () => {
    if (Validation.isInputFieldEmpty(allUserDetails.firstName)) {
      setErrorMessage({
        ...errorMessage,
        firstName: true
      })
    } else if (!Validation.isInputFieldEmpty(allUserDetails.phone) && (!Validation.phoneNumberRegex(allUserDetails.phone) || allUserDetails.phone.length < 7 || allUserDetails.phone.length > 15)) {
      setErrorMessage({
        ...errorMessage,
        phone: true
      })
    } else if (Validation.isInputFieldEmpty(allUserDetails.email)) {
      setErrorMessage({
        ...errorMessage,
        email: true
      })
    } else if (Validation.isInputFieldEmpty(allUserDetails.doB)) {
      setErrorMessage({
        ...errorMessage,
        dob: true
      })
    } else if (occupation.length < 0) {
      setErrorMessage({
        ...errorMessage,
        occupation: true
      })
    } else if (allCountries?.nicename === '') {
      setErrorMessage({
        ...errorMessage,
        country: true
      })
    } else if (allUserDetails.email !== '' && allUserDetails.email !== null) {
      if (!Validation.emailValidation(allUserDetails.email)) {
        setErrorMessage({
          ...errorMessage,
          emailnotvalid: true
        })
      } else {
        setLoading(true)
        emailExist()
      }
    } else {
      setLoading(true)
      emailExist()
    }
  }

  const emailExist = async () => {
    try {
      if (allUserDetails.email !== dataExistOrNot.email && allUserDetails.email !== '') {
        const response = await apiAdapterCoffeeWeb.isEmailExist({ email: allUserDetails.email, id: userDetails.id })

        if (response.data === true) {
          showError({
            title: t('ERROR_TITLE'),
            message: `<p>${t('EMAIL_ALREADY_EXISTS')}</p><p>${t('ENTER_ANOTHER_EMAIL')}</p>`,
            buttonLabel: t('OK')
          })
          setLoading(false)
        } else {
          phoneExist()
        }
      } else {
        phoneExist()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const phoneExist = async () => {
    try {
      if (allUserDetails.phone !== dataExistOrNot.phone && allUserDetails.phone !== '') {
        const response = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo: allUserDetails.phone, countryPhoneCode })

        if (response.data === true) {
          showError({
            title: t('ERROR_TITLE'),
            message: `<p>${t('PHONE_NUMBER_ALREADY_EXISTS')}</p><p>${t('ENTER_ANOTHER_MOBILE_NUMBER')}</p>`,
            buttonLabel: t('OK')
          })

          setLoading(false)
        } else {
          updateUserInformation()
        }
      } else {
        updateUserInformation()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateUserInformation = async () => {
    const { id, firstName, lastName, countryId, occupationId, occupationName, updatedBy, city, states, doB, showMobileNo, showEmailId, idtimezone, idState, isAllowedToConnect, headLine, userRole, email: userEmail, phone } = allUserDetails

    const updateUserData = {
      id,
      firstName,
      lastName,
      countryId,
      occupationId,
      occupationName,
      updatedBy,
      city,
      states,
      doB,
      showMobileNo,
      showEmailId,
      idtimezone,
      idState,
      isAllowedToConnect,
      headLine,
      userRole,
      userEmail,
      phone
    }

    try {
      setLoading(true)

      await apiAdapterCoffeeWeb.updateOnlyUser(updateUserData)

      setLoading(false)
      showSuccess({
        title: t('SUCCESS'),
        message: t('DATA_UPDATED_SUCCESSFULLY'),
        buttonLabel: t('OK')
      })
    } catch {
      setLoading(false)

      showError({
        title: t('FAILED'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    }
  }

  const handleDeleteUserAccount = () => {
    showConfirmation({
      title: t('DELETE_USER_ACCOUNT'),
      message: t('DELETE_YOUR_ACCOUNT'),
      buttonLabel: t('DELETE'),
      dismissButtonLabel: t('CANCEL'),
      buttonAction: 'danger-action',
      onConfirm: () => {
        deleteUserAccount()
      }
    })
  }
  const deleteUserAccount = async () => {
    setLoading(true)

    await apiAdapterCoffeeWeb
      .deleteAccount(userDetails.id)
      .then((response) => {
        if (response.data === true) {
          setLoading(false)

          showSuccess({
            title: t('SUCCESS'),
            message: t('ACCOUNT_DELETED_SUCCESSFULLY'),
            buttonLabel: t('OK'),
            onConfirm: deleteAccountInitiated,
            onCancel: deleteAccountInitiated
          })

          setTimeout(() => {
            deleteAccountInitiated()
          }, 5000)
        } else {
          setLoading(false)
          showError({
            title: t('ERROR'),
            message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
            buttonLabel: t('OK')
          })
        }
      })
      .catch(() => {
        setLoading(false)
        showError({
          title: t('ERROR_TITLE'),
          message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
          buttonLabel: t('OK')
        })
      })
  }

  const deActivateAccount = () => {
    showConfirmation({
      title: t('DEACTIVATE_USER_ACCOUNT'),
      message: `<p>${t('DEACTIVATE_ACCOUNT_CONFIRMATION')}</p><p>${t('DEACTIVATE_YOUR_ACCOUNT')}</p>`,
      buttonLabel: t('DEACTIVATE'),
      dismissButtonLabel: t('CANCEL'),
      buttonAction: 'danger-action',
      onConfirm: () => {
        deActivateUserAccount()
      }
    })
  }

  const logoutInitiated = () => {
    clearTimeout(timerId)
    handleLogout()
  }

  const deleteAccountInitiated = () => {
    setModalShow(false)
    refreshLocalStorage()
    history.push({ pathname: ROUTE_STRINGS.guestCoffeeQuotes })
  }

  const deActivateUserAccount = async () => {
    const { id, updatedBy } = userDetails

    await apiAdapterCoffeeWeb
      .deactivateAccount({ id, updatedBy })
      .then(() => {
        showSuccess({
          title: t('SUCCESS'),
          message: t('ACCOUNT_DEACTIVATED'),
          buttonLabel: t('OK'),
          onConfirm: logoutInitiated,
          onCancel: logoutInitiated
        })

        timerId = setTimeout(() => {
          handleLogout()
        }, 5000)
      })
      .catch(() => {
        showError({
          title: t('ERROR_TITLE'),
          message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
          buttonLabel: t('OK')
        })
      })
  }

  const getStateLists = async (countryid) => {
    await apiAdapterCoffeeWeb
      .getStateList(countryid)
      .then((response) => {
        setStateList(response.data)
      })
      .catch((error) => console.log(error))
  }

  const handleSaveFunction = (activeTab) => {
    if (mockApi) {
      // this if condition is initialized for testing purpose
      phoneExist()
    } else if (activeTab === 'myInfo') {
      myInformationSend()
    } else if (activeTab === 'profileSettings') {
      handleSaveButton()
    } else if (activeTab === 'changePassword') {
      handleValidation()
    }
  }

  const amountBodyTemplate = (rowData) => `${rowData.currencytype} ${rowData.totalAmount}`
  const purchaseDateBodyTemplate = (rowData) => `${moment(rowData.durationFrom).format(dateFormatData)}`
  const expiryDateBodyTemplate = (rowData) => `${moment(rowData.durationTo).format(dateFormatData)}`

  const profileSettingModalContent = () => (
    <div className="profile-modal">
      <div className="profile-modal-body">
        <div className="profile-settings-tab-header">
          <div className="each-tab-main-container">
            <div className="each-tabs" onClick={() => setActiveTab('myInfo')} id={activeTab === 'myInfo' ? 'activeTabClass' : 'NA'}>
              {t('MY_INFO')}
            </div>
            <div className="each-tabs" onClick={() => setActiveTab('profileSettings')} id={activeTab === 'profileSettings' ? 'activeTabClass' : 'NA'}>
              {t('PROFILE_SETTINGS')}
            </div>
          </div>
          <div className="each-tab-main-container">
            <div className="each-tabs" onClick={() => setActiveTab('changePassword')} id={activeTab === 'changePassword' ? 'activeTabClass' : 'NA'}>
              {t('CHANGE_PASSWORD')}
            </div>
            <div className="each-tabs" onClick={() => setActiveTab('accountSettings')} id={activeTab === 'accountSettings' ? 'activeTabClass' : 'NA'}>
              {t('ACCOUNT_ACTIVITY')}
            </div>
          </div>
        </div>
        {activeTab === 'myInfo' && (
          <div className="my-info-card">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="firstName" className="labelInHeader">
                    {t('FIRST_NAME')}
                  </label>
                  <input type="text" name="firstName" value={allUserDetails.firstName} onChange={changeMyInformation} className="signup_form-control pass_flow profile_modal_input" autoComplete="off" disabled data-testid="first-name-input-field" />
                  {errorMessage.firstName === true && <span className="text-danger">{t('FIRST_NAME_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="labelInHeader">
                    {t('LAST_NAME')}
                  </label>
                  <input type="text" name="lastName" placeholder={t('LAST_NAME')} value={allUserDetails.lastName} className="signup_form-control pass_flow profile_modal_input" autoComplete="off" disabled data-testid="last-name-input-field" />
                  {errorMessage.lastName === true && <span className="text-danger">{t('LAST_NAME_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="labelInHeader">
                    {t('EMAIL')}
                  </label>
                  <input type="text" name="email" placeholder={t('EMAIL')} value={allUserDetails.email} onChange={changeMyInformation} className={`signup_form-control pass_flow ${isEmailDisabled ? 'profile_modal_input' : ''}`} autoComplete="off" disabled={isEmailDisabled} data-testid="email-input-field" />
                  {errorMessage.email === true && <span className="text-danger">{t('EMAIL_IS_REQUIRED')}</span>}
                  {errorMessage.emailnotvalid === true && <span className="text-danger">{t('ENTER_VALID_EMAIL')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="labelInHeader">
                    {t('PHONE')}
                  </label>
                  <input type="string" name="phone" placeholder={t('PHONE_NUMBER')} value={allUserDetails.phone} onChange={changeMyInformation} className={`signup_form-control pass_flow ${isPhoneDisabled ? 'profile_modal_input' : ''}`} autoComplete="off" maxLength={15} disabled={isPhoneDisabled} data-testid="phone-number-input-field" />
                  {errorMessage.phone === true && <span className="text-danger">{t('PHONE_NUMBER_MUST_BE_6_TO_15_DIGITS')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="labelInHeader">
                    {t('COUNTRY')}
                  </label>
                  <select disabled name="country" value={allUserDetails.countryId} onChange={changeMyInformation} id="countries" className="signup_form-control profile_modal_select_dropdown" data-testid="country-input-field">
                    {allCountries.map((country, index) => (
                      <option value={country.id} key={index}>
                        {country.nicename}
                      </option>
                    ))}
                  </select>
                  {errorMessage.country === true && <span className="text-danger">{t('COUNTRY_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="labelInHeader">
                    {t('STATE_LIST')}
                  </label>
                  <select name="state" value={allUserDetails.idState} onChange={changeMyInformation} className="signup_form-control profile_modal_select_dropdown" data-testid="state-list-input-field">
                    <option value="0" data-testid="state-list-selectID">
                      -- {t('SELECT')} --
                    </option>
                    {stateList.map((state, index) => (
                      <option value={state.idState} key={index}>
                        {state.stateName}
                      </option>
                    ))}
                  </select>
                  {/* {errorMessage.state === true && <span className="text-danger">{t('SELECT_STATE')}</span>} */}
                </div>
                <div className="col-md-6">
                  <label htmlFor="occupation" className="labelInHeader">
                    {t('OCCUPATION')}
                  </label>
                  <select value={allUserDetails.occupationId} id="occupationId" onChange={changeMyInformation} className="signup_form-control profile_modal_select_dropdown" data-testid="occupation-input-field">
                    <option value="0" data-testid="occupation-selectID">
                      -- {t('SELECT')} --
                    </option>
                    {occupation.map((occupation, index) => (
                      <option value={occupation.idOccupationType} key={index}>
                        {occupation.occupationTypeName}
                      </option>
                    ))}
                  </select>
                  {errorMessage.occupation === true && <span className="text-danger">{t('OCCUPATION_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="dob" className="labelInHeader">
                    {t('DATE_OF_BIRTH')}
                  </label>
                  <input type="date" name="dob" value={allUserDetails.doB && moment(allUserDetails.doB).format('YYYY-MM-DD')} onChange={changeMyInformation} max={new Date().toISOString().split('T')[0]} className="signup_form-control pass_flow" autoComplete="off" data-testid="date-of-birth-input-field" />
                  {errorMessage.dob === true && <span className="text-danger">{t('DATE_OF_BIRTH_IS_REQUIRED')}</span>}
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'profileSettings' && (
          <div className="profileSettingsCard">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('DATE_FORMAT')}
                  </label>
                  <select name="dateFormat" onBlur={handleBlur} value={inputValues.dateFormat} onChange={handleChanges} id="ddldateFormat" className="signup_form-control profile_modal_select_dropdown" data-testid="date-format-input-field">
                    {dateFormat?.map((ele, index) => (
                      <option key={index} value={ele.dateFormat}>
                        {ele.dateFormat}
                      </option>
                    ))}
                  </select>
                  {errorStateDateFormat && <span className="text-danger">{t('DATE_FORMAT_IS_REQUIRED ')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('TIME_FORMAT')}
                  </label>
                  <select name="timeFormat" onBlur={handleBlur} value={inputValues.timeFormat} onChange={handleChanges} id="ddltimeFormat" className="signup_form-control profile_modal_select_dropdown" data-testid="time-format-input-field">
                    {timeFormat?.map((ele, index) => (
                      <option key={index}>{ele.timeFormat}</option>
                    ))}
                  </select>
                  {errorStateForTimeFormat && <span className="text-danger">{t('TIME_FORMAT_IS_REQUIRED')}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('LANGUAGE')}
                  </label>
                  <select name="Languages" onBlur={handleBlur} value={inputValues.Languages} onChange={handleChanges} id="ddlLanguages" className="signup_form-control profile_modal_select_dropdown" data-testid="language-input-field">
                    {allLanguages.map((ele, index) => (
                      <option key={index} value={ele.idLanguage}>
                        {ele.languageName}
                      </option>
                    ))}
                  </select>
                  {errorStateForLanguages && <span className="text-danger">languages is required</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('LANDING_PAGE')}
                  </label>
                  <select name="landingPage" onBlur={handleBlur} value={inputValues.landingPage} onChange={handleChanges} id="ddllandingPage" className="signup_form-control profile_modal_select_dropdown" data-testid="landing-page-input-field">
                    {landingPage?.map((ele, index) => (
                      <option key={index} value={ele.webAppurl}>
                        {ele.landingpage}
                      </option>
                    ))}
                  </select>
                  {errorStateForLandingPage && <span className="text-danger">{t('LANDING_PAGE_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('TIME_ZONE')}
                  </label>
                  <select name="timeZone" onBlur={handleBlur} value={inputValues.timeZone} onChange={handleChanges} id="ddltimeZone" className="signup_form-control profile_modal_select_dropdown" data-testid="time-zone-input-field">
                    {timeZone?.map((ele, index) => (
                      <option key={index} value={ele.idTimeZone}>
                        {ele.timeZone}
                      </option>
                    ))}
                  </select>
                  {errorStateForTimeZone && <span className="text-danger">{t('TIME_ZONE_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('SHOW_WEB_NOTIFICATION')}
                  </label>
                  <br></br>
                  <div className="admin_subscription_toggleBtn_container">
                    <div className="admin_subscription_toggleBtn">
                      {inputValues.showWebNotification === true ? (
                        <div className="admin_subscription_toggle_action" onClick={toggle}>
                          <div className="toggle_action_active">{t('ON')}</div>
                          <div className="toggle_action_inactive">.</div>
                        </div>
                      ) : (
                        <div className="admin_subscription_toggle_action" onClick={toggle}>
                          <div className="toggle_action_inactive">.</div>
                          <div className="toggle_action_active">{t('OFF')}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'changePassword' && (
          <div className="changePasswordCard">
            <div className="container">
              <div className="change-password-container-wrapper">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="txtPassword" className="form-label labelInHeader">
                      {t('NEW_PASSWORD')}
                      <span className="mandate" data-testid="mandatory-new-password-symbol">
                        *
                      </span>
                    </label>
                    <div className="pass-wrapper">
                      <input type={passwordShown ? 'text' : 'password'} name="password" value={userInputValues.password} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" placeholder={t('PASSWORD')} autoComplete="new-password" data-testid="new-password-input-field" />
                      <span onClick={togglePassword} className="eye_icon" aria-label="Toggle new password visibility">
                        {passwordShown ? <EyeFilled data-testid="eye-filled-icon1" /> : <EyeFilledOff className="eye-filled-off-icon" data-testid="eye-filled-off-icon1" />}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="txtConfirmPassword" className="form-label labelInHeader">
                      {t('CONFIRM_NEW_PASSWORD')}
                      <span className="mandate" data-testid="mandatory-confirm-password-symbol">
                        *
                      </span>
                    </label>
                    <div className="pass-wrapper">
                      <input type={confirmPasswordShown ? 'text' : 'password'} placeholder={t('CONFIRM_PASSWORD')} name="confirmPassword" value={userInputValues.confirmPassword} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" autoComplete="off" data-testid="confirm-password-input-field" />
                      <span onClick={toggleConfirmPassword} className="eye_icon" aria-label="Toggle confirm password visibility">
                        {confirmPasswordShown ? <EyeFilled data-testid="eye-filled-icon2" /> : <EyeFilledOff className="eye-filled-off-icon" data-testid="eye-filled-off-icon2" />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'accountSettings' && (
          <div className="accountActivityCard">
            <div className="mainCard">
              {paymentHistory && (
                <div className="card subCard">
                  <div className="payment-history-screen">
                    <div className="payment-history">
                      <div className="subHeading">{t('PAYMENT_HISTORY')}</div>
                      <div className="prime-data-table">
                        <DataTable value={paymentHistory} scrollable scrollHeight="200px" style={{ width: '100%' }}>
                          <Column field="subscriptionId" header={t('SUBSCRIPTION_TYPE')}></Column>
                          <Column body={amountBodyTemplate} header={t('AMOUNT')}></Column>
                          <Column body={purchaseDateBodyTemplate} header={t('PURCHASE_DATE')}></Column>
                          <Column body={expiryDateBodyTemplate} header={t('EXPIRY_DATE')}></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card subCard">
                <div className="subHeading">{t('DEACTIVATE_ACCOUNT')}</div>
                <div className="text">{t('DEACTIVATE_YOUR_ACCOUNT')}</div>
                <div className="activityCardActionButtonDiv">
                  <Button buttonType="CUSTOM" buttonLabel={t('YES_DEACTIVATE')} onButtonClick={deActivateAccount} variant="hard-danger-action " data-testid="deactivate-account-button" />
                </div>
              </div>
              <div className="card subCard">
                <div className="subHeading">{t('DELETE_ACCOUNT')}</div>
                <div className="text">{t('DELETE_YOUR_ACCOUNT')}</div>
                <div className="activityCardActionButtonDiv">
                  <Button buttonType="CUSTOM" buttonLabel={t('YES_DELETE')} onButtonClick={handleDeleteUserAccount} variant="hard-danger-action " />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const footer = () => (
    <div className="user-profile-action-container">
      <Button buttonType="CLOSE" onButtonClick={props.onHide} />
      {activeTab !== 'accountSettings' && <Button buttonType="SAVE" onButtonClick={() => handleSaveFunction(activeTab)} />}
    </div>
  )

  return <DialogUIModal title={t('ACCOUNT_SETTINGS')} size={'md'} showModal={props.show} handleClose={props.onHide} bodyContent={profileSettingModalContent} footerContent={footer} />
}

export default ErrorMessageModal(ProfileModal)
