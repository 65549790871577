import './style.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { useErrorBoundary } from 'react-error-boundary'
import { getLocalLoggedInStatus, getLocalUserDetails, updateUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { popupIcon } from 'Assets/Icons'
import { NoData } from 'Assets/Images'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods, useResizeHandler } from 'Utils/commonMethods'
import DropdownComponent from 'Components/DropdownComponent'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import MetaTagsComponent from 'Components/MetaTagsComponent'
import AccordionPopUp from './Components/AccordionPopUp'
import AccordianForMobile from './Components/AccordianForMobile'

const GlobalDifferentials = ({ ...props }) => {
  // These props are for testing purpose
  const { mockData, mockEmptyState, mockDialogVisibility, mockAccountCreationPopup, mockSelectedCountry } = props

  const { checkForUserAccess, dateFormatBasedOnUser } = commonMethods

  const { t } = useTranslation()
  const { showBoundary } = useErrorBoundary()
  const toast = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const { setLoading } = useContext(CoffeeWebContext)

  const userDetails = getLocalUserDetails()
  const isUserLoggedIn = getLocalLoggedInStatus()
  const { sub: { subType } = {} } = userDetails || {}

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [globalDifferentialData, setGlobalDifferentialData] = useState([])
  const [globalDifferentialHistoryData, setGlobalDifferentialHistoryData] = useState([])
  const [noDataFound, setNoDataFound] = useState(false)
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false)
  const [createAccountPopup, setCreateAccountPopup] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const { isWebView } = useResizeHandler()
  const [currentItem, setCurrentItem] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    init()

    if (userDetails) {
      updateUser()
    }
    if (mockData) {
      setGlobalDifferentialData(mockData)
      setNoDataFound(mockEmptyState)
      setIsDialogModalOpen(mockDialogVisibility)
      setCreateAccountPopup(mockAccountCreationPopup)
      setSelectedCountry(mockSelectedCountry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedCountry) {
      getGlobalDifferentialByCountry(selectedCountry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      showBoundary('unAuthorized')
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY
      const element = document.getElementsByClassName('global_differentials_headers')

      if (currentPosition > 2) {
        element[0].style.borderBottom = '1px solid lightGray'
      } else {
        element[0].style.borderBottom = 'none'
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const init = async () => {
    setLoading(true)
    await apiAdapterCoffeeWeb
      .getAllCountries()

      .then((response) => {
        const modifiedList = response.data
          ?.filter((ele) => ele.isCoffeeProducingCountry)
          .map((ele) => ({
            ...ele,
            flag: ele.flagsUrl,
            id: ele.id.toString(),
            name: ele.nicename
          }))
          .sort((a, b) => a.coffeeProducingOrderBy - b.coffeeProducingOrderBy)

        const defaultCountry = modifiedList?.find((ele) => ele.isDefault) ?? modifiedList?.find((ele) => ele.phonecode === '91')

        if (defaultCountry) {
          setCurrentItem(defaultCountry)
          handleSelectCountry(defaultCountry)
        }

        setCountryList(modifiedList)
        setErrorMessage(false)
      })
      .catch(() => {
        setErrorMessage(true)
        setLoading(false)
      })
  }

  const getGlobalDifferentialByCountry = async (countryId) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getGlobalDifferentialByCountryId(countryId)
      const { globalDifferentialCountryData } = data.returnLst

      setGlobalDifferentialData(globalDifferentialCountryData)
      setNoDataFound(false)

      if (!isUserLoggedIn || menuLock) {
        setCreateAccountPopup(true)
      }
      window.scrollTo({ top: 0 })
    } catch {
      setGlobalDifferentialData([])
      setNoDataFound(true)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectCountry = ({ id }) => setSelectedCountry(id)
  const handleShowAccordionPopup = ({ ...values }) => getGlobalDifferentialHistoryDataByCountryId({ ...values })

  const getGlobalDifferentialHistoryDataByCountryId = async ({ idCountry, qualityName, countryName, qualityCode }) => {
    setLoading(true)
    try {
      const encodedQualityName = encodeURIComponent(qualityName.replace(/\//g, '^'))
      const response = await apiAdapterCoffeeWeb.getGlobalDifferentialHistoryData(idCountry, encodedQualityName, countryName, encodeURIComponent(qualityCode))

      if (response?.data?.returnLst) {
        setGlobalDifferentialHistoryData(response?.data?.returnLst)
        setIsDialogModalOpen(true)
      } else {
        setGlobalDifferentialHistoryData([])
        showError()
      }
    } catch {
      setGlobalDifferentialHistoryData([])
      showError()
    } finally {
      setLoading(false)
    }
  }

  const currencyStyle = (value) => {
    if (!value) return {}

    const convertedToNumber = parseFloat(value.replace(/[$¢]/g, ''))

    return { color: convertedToNumber >= 0 ? 'green' : 'red' }
  }

  const getButtonAndInfo = () => {
    if (!userDetails) {
      return {
        buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
        infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
        infoMessage2: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'login-button',
        navigationPath: ROUTE_STRINGS.login,
        state: {}
      }
    }
    if (subType === 1) {
      return {
        buttonText: t('BUY_SUBSCRIPTION'),
        infoMessage: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }
    if (subType === 2) {
      return {
        buttonText: t('UPGRADE'),
        infoMessage: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }

    return {
      buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
      infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
      className: 'login-button',
      navigationPath: ROUTE_STRINGS.login,
      state: {}
    }
  }

  const { buttonText, infoMessage, className, navigationPath, state } = getButtonAndInfo()

  const showError = () => toast.current?.show({ severity: 'error', summary: 'Error', detail: t('FAILED_TO_GET_DATA'), life: 3000 })

  const handleCloseModal = () => {
    setIsDialogModalOpen(false)
    setGlobalDifferentialHistoryData([])
  }

  return (
    <div className="global_differentials_screens">
      {!userDetails && <MetaTagsComponent title={t('GLOBAL_DIFFERENTIAL_TITLE')} description={t('GLOBAL_DIFFERENTIAL_DESCRIPTION')} keywords={t('GLOBAL_DIFFERENTIAL_KEYWORDS')} url="https://coffeeweb.com/globaldifferentials" h1Content={t('GLOBAL_DIFFERENTIAL_H1_CONTENT')} />}
      {globalDifferentialHistoryData === null || globalDifferentialHistoryData.length === 0 ? <Toast ref={toast} /> : <div></div>}

      <div className="global_differentials_headers">
        <div className="title">{t('GLOBAL_DIFFERENTIALS')}</div>
        <div className="dropdown" data-testid="drop-down-container-in-global-differential">
          <span>{t('SELECT_ORIGIN')} :</span>
          <DropdownComponent isGlobalDifferential={true} handleSelectCountry={handleSelectCountry} countryList={countryList} currentItem={currentItem} setCurrentItem={setCurrentItem} errorState={errorMessage} width="100%" height="41px" testId="global-differential-country-dropdown" />
        </div>
      </div>
      <div className="global_differentials_body" id="global_differentials_background" data-testid="global-differential-main-container">
        {globalDifferentialData?.map((GDItem, index) => (
          <div key={index}>
            <div className="differential_table">
              <div className="table_data">
                <div className="year-web-view">
                  <div className="flag_and_cropyear web-view">
                    <div className="crop_year_flag" data-testid="global-differential-country-flag">
                      <div className="country-name-and-flag">
                        <img src={GDItem.flagUrl} alt="Flag" title="country-flag-images" />
                        <span>{GDItem?.country}</span>
                      </div>
                      <div className="cropyear" data-testid="testing-css-file">
                        {t('CROP_YEAR')} : {GDItem?.croyear}
                      </div>
                    </div>
                    <div className="update-header">
                      <div className="updated_on ">
                        {t('UPDATED_ON')} : {dateFormatBasedOnUser(GDItem?.upDated, 'spaceFormat')}
                      </div>
                    </div>

                    <div className="forex-type-and-value" data-testid="global-forex-currency-type">
                      {GDItem?.forexType} : {GDItem?.forexValue}
                    </div>
                  </div>
                </div>
                <div className="year-mobile-view">
                  <div className="flag_and_cropyear" data-testid="global-differential-country-flag-in-mobile-view">
                    <div className="crop_year_flag" data-testid="flag-and-name-container-in-mobile-view">
                      <img src={GDItem.flagUrl} alt="Flag" title="country-flag-images-in-mobile-view" /> {GDItem?.country}
                    </div>
                    <span data-testid="global-forex-currency-type-in-mobile-view">
                      {GDItem?.forexType} : {GDItem?.forexValue}
                    </span>
                  </div>
                  <div className="year-date-wrapper">
                    <div className="cropyear">
                      {t('CROP_YEAR')} : {GDItem?.croyear}
                    </div>
                    <div className="updated_on">
                      {t('UPDATED_ON')} : {dateFormatBasedOnUser(GDItem?.upDated, 'spaceFormat')}
                    </div>
                  </div>
                </div>
                {GDItem?.globalDifferentialAdminDTOs?.map((ele, index) => (
                  <React.Fragment key={index}>
                    {isWebView ? (
                      <table className="each_table" key={index}>
                        <thead className="differential_table_header">
                          <tr>
                            <th className="slNo blue-border">{index + 1}</th>
                            <th colSpan={1} className="quality-th-cell blue-border">
                              {t('QUALITY')}
                            </th>
                            <th className="screen-th-cell blue-border">{t('SCREEN')}</th>
                            <th className="blue-border" colSpan={1}>
                              {t('DIFFERENTIALS')} V/s
                            </th>
                            <th colSpan={1} className="blue-border" data-testid="global-differential-terminal">
                              {' '}
                              {ele?.terminal} : {ele?.terminalLevel}
                            </th>
                            <th className="delivery_port blue-border">{t('DIFFERENTIALS_BASED_ON_FOB')}</th>
                          </tr>
                          <tr>
                            <th className="serial-column">{t('SL')}</th>
                            <th data-testid="global-selected-country-name">{`${GDItem?.country?.toUpperCase()}  ${ele?.qualityGroupName}`}</th>
                            <th>{t('SIZE')}</th>
                            <th colSpan={2}>{t('BETWEEN')}</th>
                            <th>{t('FOB_PORT')}</th>
                          </tr>
                        </thead>
                        <tbody className="differential_table_body" data-testid="global-differential-table-body" style={checkForUserAccess(menuLock)}>
                          {ele?.globalDifferentialData?.map(({ idCountry, qualityName, qualityCode, rangeStart, rangeEnd }, index) => {
                            const rangeStartMatch = rangeStart.match(/(-?)([^0-9]*)([0-9]+)/)
                            const [, rangeStartsign, rangeStartCurrencySymbol, rangeStartValue] = rangeStartMatch
                            const rangeEndMatch = rangeEnd.match(/(-?)([^0-9]*)([0-9]+)/)
                            const [, rangeEndsign, rangeEndCurrencySymbol, rangeEndValue] = rangeEndMatch

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td onClick={() => userDetails && !menuLock && handleShowAccordionPopup({ idCountry, qualityName, countryName: GDItem?.country, qualityCode })} className="quality-group-name-td">
                                  <div className="quality-div">
                                    <p className="quality-name"> {qualityName} </p>
                                    <img className="popup-icon" src={popupIcon} alt="popup-icon" data-testid="global-history-popup-icon" />
                                  </div>
                                </td>
                                <td>{qualityCode}</td>
                                <td className="range-starts" style={currencyStyle(rangeStart)} colSpan={1}>
                                  <div className="currency-and-value">
                                    <div className={`${rangeStartCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                                      {rangeStartsign} {rangeStartCurrencySymbol}
                                    </div>
                                    <div className="value">{rangeStartValue || '-'}</div>
                                  </div>
                                </td>
                                <td className="range-ends" style={currencyStyle(rangeEnd)} colSpan={1}>
                                  <div className="currency-and-value">
                                    <div className={`${rangeEndCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                                      {rangeEndsign} {rangeEndCurrencySymbol}
                                    </div>
                                    <div className="value">{rangeEndValue || '-'}</div>
                                  </div>
                                </td>
                                {index === 0 && (
                                  <td className="fob-cell" rowSpan={ele?.globalDifferentialData.length}>
                                    {ele?.fobPort}
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <AccordianForMobile data={[ele]} GDItem={GDItem} handleShowAccordionPopup={handleShowAccordionPopup} checkForUserAccess={checkForUserAccess} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}

        {noDataFound && (
          <div className="no-data-found">
            <img src={NoData} alt="NoDataFound" className="no-data-found-image" title="no-data-image-in-global-differential" />
            <div className="all-data-fetched"> {t('NO_DATA_FOUND_FOR_THIS_COUNTRY')}</div>
          </div>
        )}
      </div>
      <div data-testid="global-differential-history-modal-popup">
        {createAccountPopup && (
          <div className={`differential-create-account-info-modal-popup ${isUserLoggedIn ? 'create-account-info-message' : 'guest-create-account-info-message'}`} data-testid="create-account-main-container">
            <CreateAccountInfoModal buttonText={buttonText} infoMessage={infoMessage} className={className} navigationPath={navigationPath} state={state} testId="create-account-popup-in-differential" />
          </div>
        )}
        <AccordionPopUp isDialogModalOpen={isDialogModalOpen} handleCloseModal={handleCloseModal} globalDifferentialHistoryData={globalDifferentialHistoryData} />
      </div>
    </div>
  )
}

export default GlobalDifferentials
