/*eslint-disable*/
import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { ROUTE_STRINGS } from 'Utils/Constants'
import CoffeeNewsFeeds from 'Screens/CoffeeNewsFeeds'
import PaymentGateWay from 'Screens/Subscription/Pages/PaymentGateway'
import SupportRequest from 'Screens/SupportRequest'
import PageNotFound from 'Screens/PageNotFound'
import Subscription from 'Screens/Subscription'
import OpenSharedNews from 'Screens/NewsFeedLink/Pages/OpenSharedNews'
import OpenNewsNotification from 'Screens/Notification/Pages/OpenNewsNotification'
import Notification from 'Screens/Notification'
import PaymentStatus from 'Screens/Subscription/Pages/PaymentStatus'
import Menubar from '../Menubar'
import Header from '../Header'
import FooterDashboard from '../Footer/FooterDashboard'
import CoffeeQuotes from 'Screens/CoffeeQuotes'
import GlobalDifferentials from 'Screens/GlobalDifferentials'
import AboutUs from 'Screens/AboutUs'
import GuestNewsFeed from 'Screens/CoffeeNewsFeeds/Components/GuestNewsFeed'
import NewsFeedLink from 'Screens/NewsFeedLink'
import AuthScreens from 'Screens/AuthScreens'
import FreeMenubar from '../FreeUserMenubar'
import { getLocalFreeMenus, getLocalUserDetails, setLocalWithFreeMenus } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import LayoutContainer from '../LayoutContainer'
import CoffeeStockReports from 'Screens/CoffeeReports'
import GlobalRawCoffeePrices from 'Screens/GlobalRawCoffeePrices'

const Routes = () => {
  const pageUrl = window.location.pathname
  const splitPageUrl = pageUrl.split('/')
  const currentUrl = splitPageUrl.pop()
  const { isLoading, isCustomLoading } = useContext(CoffeeWebContext)
  const userDetails = getLocalUserDetails()
  const freeMenusToDisplay = getLocalFreeMenus()

  const [guestUserMenus, setGuestUserMenus] = useState(freeMenusToDisplay)

  useEffect(() => {
    getAllFreeMenusToDisplay()
  }, [])

  const getAllFreeMenusToDisplay = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllFreeMenusToDisplay()

      if (response?.data?.returnLst?.length) {
        setLocalWithFreeMenus(response.data?.returnLst)
        setGuestUserMenus(response?.data?.returnLst)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  return (
    <div className="dashboard">
      <Header />
      {currentUrl !== 'login' && currentUrl !== 'create_account' && !userDetails && <FreeMenubar guestUserMenus={guestUserMenus} />}
      {userDetails && <Menubar />}
      <LayoutContainer isLoading={isLoading} isCustomLoading={isCustomLoading}>
        <Switch>
          {/* Open Routes */}
          <Route exact path={ROUTE_STRINGS.guestNewsFeed} component={GuestNewsFeed} />
          <Route exact path={ROUTE_STRINGS.guestGlobalDifferentials} component={GlobalDifferentials} />
          <Route exact path={ROUTE_STRINGS.supportRequest} component={SupportRequest} />
          <Route exact path={ROUTE_STRINGS.aboutUs} component={AboutUs} />
          <Route exact path={ROUTE_STRINGS.guestCoffeeQuotes} component={CoffeeQuotes} />
          <Route exact path={ROUTE_STRINGS.aboutMobileView} component={AboutUs} />
          <Route exact path={ROUTE_STRINGS.coffeeReportsGuest} component={CoffeeStockReports} />
          <Route exact path={ROUTE_STRINGS.guestglobalrawcoffeeprices} component={GlobalRawCoffeePrices} />

          <Route exact path="/share/News/:id">
            <NewsFeedLink />
          </Route>
          <Route exact path={ROUTE_STRINGS.login} component={AuthScreens} />
          <Route exact path={ROUTE_STRINGS.createAccount} component={AuthScreens} />
          {/* Protected Routes */}
          <Route exact path="/dashboard"></Route>
          <Route exact path={ROUTE_STRINGS.coffeequotes} component={CoffeeQuotes} />
          <Route exact path={ROUTE_STRINGS.coffeeNewsFeeds} component={CoffeeNewsFeeds} />
          <Route exact path={RouteStrings.notification} component={Notification} />
          <Route exact path={RouteStrings.opennewsnotification} component={OpenNewsNotification} />
          <Route exact path={RouteStrings.opensharednews} component={OpenSharedNews} />
          <Route exact path={ROUTE_STRINGS.subscription} component={Subscription} />
          <Route exact path={ROUTE_STRINGS.paymentgateway} component={PaymentGateWay} />
          <Route exact path={ROUTE_STRINGS.success} component={PaymentStatus} />
          <Route exact path={ROUTE_STRINGS.cancel} component={PaymentStatus} />
          <Route exact path={ROUTE_STRINGS.support} component={SupportRequest} />
          <Route exact path={ROUTE_STRINGS.globaldifferentials} component={GlobalDifferentials} />
          <Route exact path={ROUTE_STRINGS.globalrawcoffeeprices} component={GlobalRawCoffeePrices} />
          <Route exact path={ROUTE_STRINGS.aboutUsCoffeeWeb} component={AboutUs} />
          <Route exact path={ROUTE_STRINGS.coffeeReports} component={CoffeeStockReports} />
          <Route exact path={ROUTE_STRINGS.pnf} component={PageNotFound} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </LayoutContainer>
      {!window.location.pathname.includes('coffee_reports') && <FooterDashboard />}
    </div>
  )
}

export default Routes
