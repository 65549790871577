import * as Sentry from '@sentry/react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { configUrl } from 'Services/configuration/index'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { COFFEEQUOTES_STATIC_VALUES, ROUTE_STRINGS, WEATHER_API_URL } from './Constants'
import { getLocalAppLanguage, getLocalAppSettings, getLocalAuthToken, getLocalFCMToken, getLocalFreeMenus, getLocalSingleDeviceId, getLocalUserDetails, setLocalWithAppLanguage, setLocalWithAppSettings, setLocalWithAuthToken, setLocalWithFirebaseFCMToken, setLocalWithFreeMenus, setLocalWithLoggedInStatus } from './LocalStorage_Handler'

export const useResizeHandler = () => {
  const [isWebView, setIsWebView] = useState(window.innerWidth >= 767)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 767)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsWebView(false)
      setIsMobileView(true)
    } else {
      setIsWebView(true)
      setIsMobileView(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { isWebView, isMobileView }
}

export const commonMethods = {
  dateToLocal(args) {
    const date = new Date(args)

    return date.toLocaleDateString()
  },
  getGeoLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const { latitude, longitude } = coords

          resolve({ latitude, longitude })
        },
        (err) => {
          reject(err)
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000
        }
      )
    })
  },
  /* eslint-disable no-useless-catch */

  async searchLocation() {
    try {
      const { latitude, longitude } = await this.getGeoLocation()
      const queryString = `${latitude},${longitude}`
      const response = await fetch(`${WEATHER_API_URL.apiUrl}?q=${queryString}&key=${process.env.REACT_APP_WEATHER_API_KEY}`)
      const [{ country, region, name }] = await response.json()

      return { country, region, name }
    } catch (error) {
      throw error
    }
  },

  updateDateTime() {
    const currentDate = new Date()

    const day = currentDate.getDate()
    const formattedDay = String(day).padStart(2, '0')
    const month = currentDate.toLocaleString('default', { month: 'short' })
    const year = currentDate.getFullYear()

    const hours = currentDate.getHours()
    const minutes = currentDate.getMinutes()

    const formattedDate = `${formattedDay}-${month}-${year}`
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return { currentDate: formattedDate, currentTime: formattedTime }
  },

  previousDayColorLogic(arg1, arg2) {
    const argument1 = typeof arg1 === 'string' ? parseFloat(arg1) : arg1
    const argument2 = typeof arg2 === 'string' ? parseFloat(arg2) : arg2

    if (argument1 >= argument2) {
      return 'green-text'
    }

    return 'red-text'
  },

  getFontColor(arg1) {
    if (parseFloat(arg1 || 0) >= 0) {
      return 'green-text'
    }

    return 'red-text'
  },

  getFontColorWithBold(arg) {
    const argument = typeof arg === 'string' ? parseFloat(arg) : arg

    if (argument >= 0) {
      return 'bold_and_green'
    }

    return 'bold_and_red'
  },

  getSign(value) {
    const actualValue = typeof value === 'string' ? parseFloat(value) : value

    if (actualValue >= 0) {
      return '+'
    }

    return ''
  },

  differenceValue(arg1, arg2) {
    if (parseFloat(arg1 || 0) >= parseFloat(arg2 || 0)) {
      return parseFloat(arg1 || 0) - parseFloat(arg2 || 0)
    }

    return parseFloat(arg2 || 0) - parseFloat(arg1 || 0)
  },

  isMarketLive() {
    const appSettings = getLocalAppSettings()

    function convertTimeToComparableFormat(timeString) {
      const [hoursStr, minutesStr, secondsStr] = timeString?.split(':') || []
      const hours = parseInt(hoursStr, 10)
      const minutes = parseInt(minutesStr, 10)
      const seconds = parseInt(secondsStr, 10)

      return hours * 10000 + minutes * 100 + seconds // Convert to HHMMSS format for comparison
    }

    const userTime = new Date()

    const utcTime = userTime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: false })

    const currentUTCTime = convertTimeToComparableFormat(utcTime)

    const currentDay = userTime.getUTCDay()
    const isWeekend = currentDay === 0 || currentDay === 6 // Saturday(6) or Sunday(0)

    // Todo: Review Code to get data always before
    const marketOpenTime = convertTimeToComparableFormat(appSettings?.marketOnUtcFormat || '07:39:00 +00:000') // 1:09 (afternoon)
    const marketCloseTime = convertTimeToComparableFormat(appSettings?.marketOffUtcFormat || '19:39:00 +00:000') // 1:09 (midnight)

    const openTimeToday = convertTimeToComparableFormat('09:10:00') // 2:40 indian timing (afternoon)
    const closeTimeToday = convertTimeToComparableFormat('18:10:00') // 11:40 indian timing (night)

    const marketOpenHours = currentUTCTime >= marketOpenTime && currentUTCTime <= marketCloseTime
    const marketOpenTodayStatus = currentUTCTime >= openTimeToday && currentUTCTime <= closeTimeToday

    if (isWeekend) {
      return false
    }

    if (marketOpenHours) {
      return { isMarketActive: true, isMarketOpenToday: marketOpenTodayStatus }
    }

    return { isMarketActive: false, isMarketOpenToday: marketOpenTodayStatus }
  },

  getOptionExpiryColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  },

  getFirstNoticeDayColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndBlack') return 'bold_and_black'
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  },

  getCountryBasedTime(country, timeformat) {
    const options = {
      timeZone: country,
      hour12: timeformat === '12 Hr' ?? false,
      hour: 'numeric',
      minute: 'numeric'
    }

    const currentTime = new Date().toLocaleString('en-US', { country, ...options })

    return currentTime
  },

  async fetchIPDetails() {
    try {
      const responseData = await fetch('https://ipapi.co/json/')

      if (responseData) {
        const responseDataJSON = await responseData.json()

        const city = responseDataJSON?.city
        const timezone = responseDataJSON?.timezone
        const region = responseDataJSON?.region
        const countryName = responseDataJSON?.country_name
        const countryCode = responseDataJSON?.country_calling_code

        if (city && timezone && region && countryName && countryCode) {
          return { city, region, countryName, timezone, countryCode }
        }
      }

      throw new Error('IP API Request Limit Exceeded.')
    } catch (error) {
      throw error
    }
  },

  async getToken() {
    const userDetails = getLocalUserDetails()
    const currentAuthToken = getLocalAuthToken()

    if (userDetails) {
      return currentAuthToken
    }

    const generateNewAuthToken = async () => {
      try {
        const response = await axios.get(`${configUrl.baseUrl}/api/userservice/generateAuthToken`)
        const neweGeneratedToken = response?.data?.token

        if (neweGeneratedToken) {
          setLocalWithAuthToken(neweGeneratedToken)

          return neweGeneratedToken
        }
      } catch (error) {
        console.log('error')
      }
    }

    const checkForAuthTokenValidity = async () => {
      if (currentAuthToken) {
        const token = currentAuthToken?.split('.')[1]
        const currentTime = Date.now() / 1000

        const base64UrlDecode = () => {
          try {
            return JSON.parse(atob(token.replace(/-/g, '+').replace(/_/g, '/')))?.exp
          } catch (e) {
            return null
          }
        }

        if (base64UrlDecode() < currentTime) {
          // token expired
          const response = await generateNewAuthToken()
          const newelyGeneratedToken = response?.data?.token

          if (newelyGeneratedToken) {
            return newelyGeneratedToken
          }
        }

        return currentAuthToken
      }
    }

    if (!currentAuthToken) {
      return await generateNewAuthToken()
    }

    return await checkForAuthTokenValidity()
  },

  useScrollHandler() {
    const [scrollPos, setScrollPos] = useState(0)
    const [isHidden, setIsHidden] = useState(false)

    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY

        if (currentScrollPos > scrollPos) {
          if (currentScrollPos > 100) {
            setIsHidden(true)
          }
          setScrollPos(currentScrollPos)
        } else if (currentScrollPos < scrollPos - 100) {
          setIsHidden(false)
          setScrollPos(currentScrollPos)
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [scrollPos])

    return { isHidden }
  },

  setBodyOverflowToAuto() {
    const body = document.querySelector('body')

    body.style.overflow = 'auto'
  },

  handleStickyDates(scrollPositionForData, setShowStickyDate, setStickyDateList) {
    const objectDates = Array.from(document.getElementsByClassName('object-date'))
    const stickyDates = []

    objectDates.forEach((objectDate) => {
      const offsetTop = objectDate.offsetTop
      const deviceWidth = window.innerWidth

      if ((deviceWidth < 767 && scrollPositionForData + 112 >= offsetTop) || scrollPositionForData + 84 >= offsetTop) {
        const dataId = objectDate.getAttribute('data-id')

        stickyDates.push(dataId)
        setShowStickyDate(dataId)
      }
    })

    setStickyDateList(stickyDates)
  },

  handlePlay(index, currentPlayer, setCurrentPlayer, videoRefs) {
    const player = videoRefs.current[index]

    if (currentPlayer && currentPlayer !== player) {
      const previousPlayer = currentPlayer

      previousPlayer.pause()
      previousPlayer.currentTime = 0
    }

    player.play()
    setCurrentPlayer(player)
  },

  handleSearchClear(setSearchValue, setCoffeeNewsData, filteredCoffeeNewsData, setNewsFeedStatus, t) {
    setSearchValue('')
    setCoffeeNewsData(filteredCoffeeNewsData)
    if (filteredCoffeeNewsData?.length === 0) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))
    }
  },

  handleSearchInput(setShowSearchPopup, setShowSearchPopupForBack, setSearchValue) {
    setShowSearchPopup(true)
    setShowSearchPopupForBack(true)
    setSearchValue('')

    const body = document.querySelector('body')

    body.style.overflow = 'hidden'
  },

  handleGoToTop() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0
    } else if (document.body.scrollTop > 0) {
      document.body.scrollTop = 0
    }
  },

  getSubscriptionLabelColor(ele) {
    const colorCodes = ele?.subscriptionColor?.split(',')

    return `linear-gradient(100deg, ${colorCodes[0]}, ${colorCodes[1]}, ${colorCodes[2]})`
  },

  // logout function

  refreshLocalStorage() {
    const appSetting = getLocalAppSettings()
    const languageCode = getLocalAppLanguage()
    const singleDeviceId = getLocalSingleDeviceId()
    const freeMenusToDisplay = getLocalFreeMenus()
    const firebaseCloudMessagingToken = getLocalFCMToken()

    localStorage.clear()

    setLocalWithAppSettings(appSetting)
    setLocalWithAppLanguage(languageCode)
    setLocalWithFreeMenus(freeMenusToDisplay)
    setLocalWithLoggedInStatus(false)
    setLocalWithFirebaseFCMToken(firebaseCloudMessagingToken)

    return { appSetting, languageCode, freeMenusToDisplay, firebaseCloudMessagingToken, singleDeviceId }
  },

  async handleUserLogout() {
    try {
      const deviceId = getLocalSingleDeviceId()
      const userDetails = getLocalUserDetails()

      const payload = {
        idUser: userDetails.id,
        deviceId,
        fbToken: userDetails.fbToken
      }

      await apiAdapterCoffeeWeb.userLogout(payload)

      commonMethods.refreshLocalStorage()
    } catch (error) {
      // console.log(error)
    }
  },

  generateUniqueId() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''

    for (let i = 0; i < 24; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length)

      result += chars[randomIndex]
    }

    const timestamp = Date.now()

    return result + timestamp
  },
  timeInIST() {
    const now = new Date()

    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'Asia/Kolkata'
    }

    const timeStamp = now.toLocaleTimeString('en-IN', options)

    return timeStamp
  },

  sentryLog({ userId, timeStamp, amount, subscriptionId, paymentSource, paymentId, idOrder, description, errorIn, screenName, error, contractName, date }) {
    const sentryTags = { userId, timeStamp, amount, subscriptionId, paymentSource, paymentId, idOrder, description, errorIn, screenName, error, contractName, date }
    const logMessage = `For ${screenName} Page in ${errorIn}`

    Sentry.captureMessage(logMessage, {
      level: 'error',
      tags: sentryTags
    })
  },
  convertTimeToUnixTimestampIST(currentDate) {
    const date = new Date(currentDate)
    const utcTimestamp = date.getTime()
    const istOffset = 5.5 * 60 * 60 * 1000
    const istTimestamp = utcTimestamp + istOffset
    const unixTimestampIST = Math.floor(istTimestamp / 1000)

    return unixTimestampIST
  },

  checkForUserAccess(menuLock) {
    const userDetails = getLocalUserDetails()

    if (!userDetails || menuLock) {
      return { filter: 'blur(3px)' }
    }

    return {}
  },

  getButtonAndInfo() {
    const userDetails = getLocalUserDetails()
    const { sub: { subType } = {} } = userDetails || {}

    if (!userDetails) {
      return {
        buttonText: 'LOGIN_AND_CREATE_ACCOUNT',
        infoMessage: 'TO_ACCESS_MORE_FEATURE_CREATE_ACCOUNT',
        className: 'login-button',
        navigationPath: ROUTE_STRINGS.login
      }
    }
    if (subType === 1) {
      return {
        buttonText: 'BUY_SUBSCRIPTION',
        infoMessage: 'REGULAR_SUBSCRIPTION_UPDATE',
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription
      }
    }
    if (subType === 2) {
      return {
        buttonText: 'UPGRADE',
        infoMessage: 'GOLD_SUBSCRIPTION_UPDATE',
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription
      }
    }
    if (subType === 3) {
      return {
        buttonText: 'COMING_SOON',
        infoMessage: 'PLATINUM_SUBSCRIPTION_UPDATE',
        className: 'premium-button'
      }
    }

    return {
      buttonText: 'LOGIN_AND_CREATE_ACCOUNT',
      infoMessage: 'TO_ACCESS_MORE_FEATURE_CREATE_ACCOUNT',
      className: 'login-button',
      navigationPath: ROUTE_STRINGS.login
    }
  },
  async getUserDetailUsingOAuth(googleToken) {
    try {
      const res = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        headers: {
          Authorization: `Bearer ${googleToken}`
        }
      })

      const userDetails = await res.json()

      return userDetails
    } catch (error) {
      throw error
    }
  },

  // WORKBOOK CALCULATION FORMULAS
  handleDecimalValue(value, decimal) {
    return parseFloat(value).toFixed(decimal)
  },
  handleDecimal(arg1, decimal) {
    return parseFloat(arg1 || 0).toFixed(decimal)
  },
  sumTwoValues(arg1, arg2, decimal) {
    return (parseFloat(arg1 || 0) + parseFloat(arg2 || 0)).toFixed(decimal)
  },
  substractValues(arg1, arg2, decimal) {
    return (parseFloat(arg1 || 0) - parseFloat(arg2 || 0)).toFixed(decimal)
  },
  getValueWithoutDecimal(value) {
    return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2).replace(/\.?0+$/, '')
  },
  handleWorkbookGrossPrice(arg1, arg2, decimal) {
    return ((parseFloat(arg1 || 0) * parseFloat(arg2 || 0)) / 1000).toFixed(decimal)
  },
  handleWorkbookConvertTo(arg1, decimal) {
    return parseFloat((arg1 || 0) * 22.0462).toFixed(decimal)
  },
  handleWorkbookTotalForNetPrice(arg1, arg2, decimal) {
    // eslint-disable-next-line
    return arg2 != 0 ? ((arg1 * 1000) / arg2).toFixed(decimal) : 0
  },
  handleWorkbookTotalForCustomNetPrice(arg1, arg2, decimal, value) {
    return (((parseFloat(arg1 || 0) * 1000) / parseFloat(arg2 || 0)).toFixed(decimal) / value).toFixed(decimal)
  },
  dateFormatBasedOnUser(dateString, format) {
    const userDetails = getLocalUserDetails()
    const currentDateTime = dateString ? new Date(dateString) : new Date() // Based on dateString it shows the user date or current date
    const { monthNames } = COFFEEQUOTES_STATIC_VALUES

    const userYear = currentDateTime.getFullYear()
    const userMonth = monthNames[currentDateTime.getMonth()]
    const userDate = currentDateTime.getDate().toString().padStart(2, '0')

    const formattedUserDate = (() => {
      if (userDetails?.userDateFormat === 'DD-MMM-YYYY') return `${userDate}-${userMonth}-${userYear}`
      if (userDetails?.userDateFormat === 'MMM-DD-YYYY') return `${userMonth}-${userDate}-${userYear}`
      if (userDetails?.userDateFormat === 'YYYY-MMM-DD') return `${userYear}-${userMonth}-${userDate}`

      return `${userDate}-${userMonth}-${userYear}`
    })()

    return format ? formattedUserDate.replaceAll('-', ' ') : formattedUserDate // If user format change is required, it does else default hyphen separated
  }
}
