/* eslint-disable */
import './styles.scss'
import React, { useState, useEffect, useImperativeHandle, forwardRef, useContext } from 'react'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { robustaWorkbookDummyData, arabicaWorkbookDummyData } from 'Utils/coffeeQuotesDummyData'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'primereact/tooltip'
import { Dropdown } from 'primereact/dropdown'
import { SaveIcon, DeleteIcon, DragIcon, GooglePlay, AppleIcon } from 'Assets/Icons'
import { COFFEEQUOTES_STATIC_VALUES, coffeewebLogo } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import Forex from '../Forex'
import Button from 'Components/UIComponent/Button'
import { Dialog } from 'primereact/dialog'

const WorkBook = forwardRef((props, ref) => {
  const { showError, showSuccess, showConfirmation, dispatch, robustaRef, arabicaRef, workbookRobustaDataRef, workbookArabicaDataRef, setSearchContractList, fxCurrencyListRef, setSearchContractListForRobusta, setSearchContractListForArabica, selectedContract, seletectedWorkBookType, lastMessageReceivedAtRef, selectedCurrencyRobustaRef, selectedCurrencyArabicaRef, handleWorkbookDropDown, handleSelectContractDropdown, marquee, searchContractList, setSelectedContract, testId, mockWorkBook, mockWorkBookTabActive, mockShowModal } = props

  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)

  const userDetails = getLocalUserDetails()
  const { id: userId, countryId, isCoffeeQuotesAvailable: isTincapheUser, isWorkBookAvailable } = userDetails || {}
  const isIndianUser = userDetails?.countryId === 102 || userDetails?.userCountryDetails?.nicename === 'India'
  const isPlatinumUser = userDetails?.sub?.subType === 3

  const { convertToValue } = COFFEEQUOTES_STATIC_VALUES
  const { handleDecimalValue, handleDecimal, sumTwoValues, substractValues, getValueWithoutDecimal, handleWorkbookGrossPrice, handleWorkbookConvertTo, handleWorkbookTotalForNetPrice, handleWorkbookTotalForCustomNetPrice, generateUniqueId, getButtonAndInfo } = commonMethods

  const [isWorkBookTabActive, setIsWorkBookTabActive] = useState(true)
  const [draggableRow, setDraggableRow] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [defaultValueForRobustaWorkbook, setDefaultValueForRobustaWorkbook] = useState(false)
  const [defaultValueForArabicaWorkbook, setDefaultValueForArabicaWorkbook] = useState(false)
  const [hasDefaultValuesForRobusta, setHasDefaultValuesForRobusta] = useState(false)
  const [hasDefaultValuesForArabica, setHasDefaultValuesForArabica] = useState(false)

  useImperativeHandle(ref, () => ({
    filterWorkBookDataResponse: filterWorkBookDataResponse,
    addWorkBookRowToRespectiveTable: addWorkBookRowToRespectiveTable
  }))

  useEffect(() => {
    if (mockWorkBook === true) {
      setIsWorkBookTabActive(mockWorkBookTabActive)
      setShowModal(mockShowModal)
    }
  }, [])

  useEffect(() => {
    if (isWorkBookAvailable || (isPlatinumUser && isIndianUser)) {
      getWorkbookData()
      getTerminalDetails()
    } else {
      workbookRobustaDataRef.current = robustaWorkbookDummyData
      workbookArabicaDataRef.current = arabicaWorkbookDummyData
      dispatch({ payload: { workbookRobustaData: robustaWorkbookDummyData, workbookArabicaData: arabicaWorkbookDummyData } })
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const root = document.documentElement
      root.style.setProperty('--topProperty', `0px`)
      root.style.setProperty('--positionProperty', `fixed`)
      root.style.setProperty('--displayProperty', `none`)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (robustaRef?.current[0].optionExpiry !== 'DD-MMM-YYYY' && fxCurrencyListRef?.current && (defaultValueForRobustaWorkbook || defaultValueForArabicaWorkbook)) {
      const highlightRow = false
      let updatedArrayForRobusta = null
      let updatedArrayForArabica = null

      const createWorkbookArray = (marketId, label, highlightRow) => {
        return Array(4)
          .fill()
          .map(() => createEmptyWorkbookObject(marketId, marketId, label, highlightRow))
      }

      if (defaultValueForRobustaWorkbook) {
        updatedArrayForRobusta = createWorkbookArray(1, robustaRef?.current?.[0]?.contractName, highlightRow)
        workbookRobustaDataRef.current = updatedArrayForRobusta
        setDefaultValueForRobustaWorkbook(false)
      }

      if (defaultValueForArabicaWorkbook) {
        updatedArrayForArabica = createWorkbookArray(2, arabicaRef?.current?.[0]?.contractName, highlightRow)
        workbookArabicaDataRef.current = updatedArrayForArabica
        setDefaultValueForArabicaWorkbook(false)
      }

      dispatch({ payload: { workbookRobustaData: updatedArrayForRobusta, workbookArabicaData: updatedArrayForArabica } })

      filterWorkBookDataResponse(robustaRef?.current, arabicaRef?.current, fxCurrencyListRef?.current)
    }
  }, [robustaRef?.current, defaultValueForRobustaWorkbook, defaultValueForArabicaWorkbook])

  const getWorkbookData = async () => {
    await apiAdapterCoffeeWeb.getUsersCoffeeQuotes({ userId, countryId }).then((response) => {
      const data = response.data.returnLst
      const robustaWorkBookAvailable = data.usersWorkbookData?.some((ele) => ele.idMarket === 1)
      const arabicaWorkBookAvailable = data.usersWorkbookData?.some((ele) => ele.idMarket === 2)

      if (isIndianUser && isPlatinumUser) {
        if (!robustaWorkBookAvailable) {
          setDefaultValueForRobustaWorkbook(true)
          setHasDefaultValuesForRobusta(true)
        }
        if (!arabicaWorkBookAvailable) {
          setDefaultValueForArabicaWorkbook(true)
          setHasDefaultValuesForArabica(true)
        }
      }

      const workbookRobusta = data?.usersWorkbookData?.filter((item) => item.idMarket === 1)
      const modifiedWorkbookRobusta = workbookRobusta?.map((ele) => {
        const { workbookTotal, lastChng, netPrice, grossPrice } = ele
        const calculatedWorkbookTotal = handleDecimalValue(workbookTotal, 0)
        const customWorkbookDifferential = getValueWithoutDecimal(calculatedWorkbookTotal - lastChng)

        return {
          ...ele,
          customLastChg: handleDecimalValue(lastChng, 0),
          customNetPrice: handleDecimalValue(netPrice, 0),
          customWorkbookDifferential,
          grossPrice: handleDecimalValue(grossPrice, 2),
          netPrice: handleDecimalValue(netPrice, 2),
          customOutRate: calculatedWorkbookTotal
        }
      })

      const workbookArabica = data?.usersWorkbookData?.filter((item) => item.idMarket === 2)
      const modifiedWorkbookArabica = workbookArabica?.map((ele) => {
        const { workbookTotal, lastChng, netPrice, grossPrice, convertTo } = ele
        const calculatedWorkbookTotal = handleDecimalValue(workbookTotal, 2)
        const customWorkbookDifferential = getValueWithoutDecimal(calculatedWorkbookTotal - lastChng)

        return {
          ...ele,
          customLastChg: handleDecimalValue(lastChng, 2),
          workbookTotal: calculatedWorkbookTotal,
          customNetPrice: handleDecimalValue(netPrice, 2),
          customWorkbookDifferential,
          grossPrice: handleDecimalValue(grossPrice, 2),
          netPrice,
          outRate: handleDecimalValue(convertTo, 0),
          customOutRate: handleDecimalValue(convertTo, 0)
        }
      })

      const userCurrencyTypeRobusta = modifiedWorkbookRobusta?.find((ele) => ele.idMarket === 1)
      const userCurrencyTypeForArabica = modifiedWorkbookArabica?.find((ele) => ele.idMarket === 2)

      workbookRobustaDataRef.current = modifiedWorkbookRobusta
      workbookArabicaDataRef.current = modifiedWorkbookArabica
      selectedCurrencyRobustaRef.current = userCurrencyTypeRobusta?.fxcurrencyType || 0
      selectedCurrencyArabicaRef.current = userCurrencyTypeForArabica?.fxcurrencyType || 0

      dispatch({ payload: { workbookRobustaData: modifiedWorkbookRobusta, workbookArabicaData: modifiedWorkbookArabica, selectedCurrencyRobusta: userCurrencyTypeRobusta?.fxcurrencyType || 0, selectedCurrencyArabica: userCurrencyTypeForArabica?.fxcurrencyType || 0 } })
    })
  }

  const getTerminalDetails = async () => {
    await apiAdapterCoffeeWeb.getTerminalDetails().then((response) => {
      const robustaTerminal = response.data.returnLst.filter((ele) => ele.idMarket === 1 && ele.isActive)
      const arabicaTerminal = response.data.returnLst.filter((ele) => ele.idMarket === 2 && ele.isActive)

      setSearchContractList(robustaTerminal)
      setSearchContractListForRobusta(robustaTerminal)
      setSearchContractListForArabica(arabicaTerminal)
    })
  }

  const showDeleteButtonForRobusta = workbookRobustaDataRef?.current?.some((ele) => ele?.isChecked)
  const showDeleteButtonForArabica = workbookArabicaDataRef?.current?.some((ele) => ele?.isChecked)
  const workbookRobustaAllChecked = workbookRobustaDataRef?.current ? workbookRobustaDataRef?.current?.every((ele) => ele?.isChecked) : false
  const workbookArabicaAllChecked = workbookArabicaDataRef?.current ? workbookArabicaDataRef?.current?.every((ele) => ele?.isChecked) : false

  const getTableRowClassName = (ele, prefix) => {
    if (ele?.isHighlight === 'newelyAdded') return `newely_added_bg_color smoothScrollFor${prefix}`
    if (ele?.isHighlight === 'activeFocus') return `active_focus_bg_color smoothScrollFor${prefix}`

    return `normal_white_bg_color smoothScrollFor${prefix}`
  }

  const getWorkbookInputFieldTextColor = (chngColorCondition, ele) => {
    if (ele?.isLastValueCustom) {
      return 'bold_and_dark_gray'
    } else {
      if (chngColorCondition > 0) {
        return 'bold_and_green'
      }
      if (chngColorCondition < 0) {
        return 'bold_and_red'
      }

      return ''
    }
  }

  const filterWorkBookDataResponse = (robustaData, arabicaData, currencyList) => {
    const robustaWorkbook = workbookRobustaDataRef?.current
    const arabicaWorkBook = workbookArabicaDataRef?.current

    const fxCurrencyForRobusta = currencyList.find((ele) => selectedCurrencyRobustaRef?.current === ele.fxCurrencyType) || currencyList[0]
    const fxCurrencyForArabica = currencyList.find((ele) => selectedCurrencyArabicaRef?.current === ele.fxCurrencyType) || currencyList[0]
    const workbookRobusta = robustaWorkbook?.map((ele) => {
      const { workbookType, workbookTotal, workbookDifferential, lastChng, chng, fxpredis, fxcurrency, grossPrice, profit, contractName, isLastValueCustom, isNetPriceValueCustom, isOutRateValueCustom } = ele

      if (workbookType !== 0 && !isLastValueCustom) {
        const elementFound = robustaData?.find((element) => element?.contractName === contractName)

        const calculatedLastChng = elementFound ? elementFound?.lastChng.toFixed(0) : lastChng
        const calculatedWorkbookTotal = sumTwoValues(calculatedLastChng, workbookDifferential, 0)
        const calculatedFxnet = sumTwoValues(fxcurrency || fxCurrencyForRobusta?.last, fxpredis, 2)
        const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxnet, calculatedWorkbookTotal, 2)
        const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)

        const modifiedElement = {
          ...ele,
          lastChng: calculatedLastChng,
          chngColorCondition: elementFound ? elementFound?.chng : chng,
          workbookDifferential,
          workbookTotal: calculatedWorkbookTotal,
          grossPrice: calculatedGrossPrice,
          fxnet: calculatedFxnet,
          netPrice: calculatedNetPrice,
          fxcurrency: fxCurrencyForRobusta?.last
        }

        const workbookTotalForNetPrice = handleWorkbookTotalForNetPrice(grossPrice, calculatedFxnet, 0)
        const modifiedElementForNetPrice = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: workbookTotalForNetPrice - calculatedLastChng,
          chngColorCondition: elementFound ? elementFound?.chng : chng,
          fxcurrency: fxCurrencyForRobusta?.last,
          fxnet: calculatedFxnet,
          workbookTotal: workbookTotalForNetPrice
        }

        const modifiedDataForOutRate = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: workbookTotal - calculatedLastChng
        }

        return isNetPriceValueCustom ? modifiedElementForNetPrice : isOutRateValueCustom ? modifiedDataForOutRate : modifiedElement
      }

      return ele
    })

    const workbookArabica = arabicaWorkBook?.map((ele) => {
      const { workbookType, workbookTotal, workbookDifferential, lastChng, chng, fxpredis, fxcurrency, grossPrice, profit, contractName, isLastValueCustom, isNetPriceValueCustom, isOutRateValueCustom } = ele

      if (workbookType !== 0 && !isLastValueCustom) {
        const elementFound = arabicaData?.find((element) => element.contractName === contractName)
        const calculatedLastChng = elementFound ? elementFound?.lastChng.toFixed(2) : typeof lastChng === 'string' ? lastChng : lastChng?.toFixed(2)
        const calculatedWorkbookTotal = sumTwoValues(calculatedLastChng, workbookDifferential, 2)
        const calculatedFxnet = sumTwoValues(fxcurrency || fxCurrencyForArabica?.last, fxpredis, 2)
        const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxnet, (calculatedWorkbookTotal * convertToValue).toFixed(0), 2)
        const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
        const convertTo = handleWorkbookConvertTo(calculatedWorkbookTotal, 0)

        const modifiedElement = {
          ...ele,
          lastChng: calculatedLastChng,
          chngColorCondition: elementFound ? elementFound?.chng : chng,
          workbookDifferential,
          workbookTotal: calculatedWorkbookTotal,
          grossPrice: calculatedGrossPrice,
          fxnet: calculatedFxnet,
          netPrice: calculatedNetPrice,
          convertTo,
          outRate: convertTo,
          fxcurrency: fxCurrencyForArabica?.last
        }
        const convertToForNetPrice = handleWorkbookTotalForNetPrice(grossPrice, calculatedFxnet, 0)
        const workbookTotalForNetPrice = (convertToForNetPrice / convertToValue).toFixed(2)
        const modifiedElementForNetPrice = {
          ...ele,
          lastChng: lastChng,
          customWorkbookDifferential: getValueWithoutDecimal(workbookTotal - lastChng),
          chngColorCondition: elementFound ? elementFound?.chng : chng,
          fxcurrency: fxCurrencyForArabica?.last,
          fxnet: calculatedFxnet,
          convertTo: convertToForNetPrice,
          workbookTotal: workbookTotalForNetPrice,
          outRate: convertToForNetPrice
        }

        const calculatedCustomWorkbookDifferential = substractValues(workbookTotal, calculatedLastChng, 2)
        const modifiedDataForOutRate = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: calculatedCustomWorkbookDifferential
        }

        return isNetPriceValueCustom ? modifiedElementForNetPrice : isOutRateValueCustom ? modifiedDataForOutRate : modifiedElement
      }

      return ele
    })

    robustaRef.current = robustaData
    arabicaRef.current = arabicaData
    workbookRobustaDataRef.current = workbookRobusta
    workbookArabicaDataRef.current = workbookArabica
    fxCurrencyListRef.current = currencyList
    lastMessageReceivedAtRef.current = Date.now()

    dispatch({ payload: { robustaData, arabicaData, workbookRobustaData: workbookRobusta || [], workbookArabicaData: workbookArabica || [], fxCurrencyList: currencyList, lastMessageReceivedAt: Date.now() } })
  }

  const createEmptyWorkbookObject = (idMarket, actualIdMarketType, contractName, highlightRow) => {
    const newObject = {
      idWorkbook: generateUniqueId(),
      createdBy: 0,
      createdOn: '2023-03-03T12:21:38.873',
      updatedBy: 0,
      updatedDtms: '2023-04-10T17:37:10.873',
      idUser: userId,
      idMarket: actualIdMarketType,
      contractName,
      workbookType: idMarket || 0,
      outRate: 0,
      marketNetPrice: 0,
      grade: '',
      chng: 0,
      lastChng: 0,
      workbookDifferential: 0,
      workbookTotal: 0,
      grandTotal: 0,
      convertTo: 0,
      expenseIn: 0,
      fxcurrencyType: 0,
      fxcurrency: 0,
      fxpredis: 0,
      fxnet: 0,
      grossPrice: 0,
      netPrice: 0,
      profit: 0,
      isHighlight: highlightRow ? 'newelyAdded' : '',
      orderBy: 0,
      isLastValueCustom: false,
      isNetPriceValueCustom: false,
      customWorkbookDifferential: 0,
      isOutRateValueCustom: false
    }

    return newObject
  }

  const addWorkBookRowToRespectiveTable = async () => {
    if ((seletectedWorkBookType === 1 && selectedContract?.idMarket === 1) || seletectedWorkBookType === 3) {
      addNewRowInWorkbook('robustaWorkbook', workbookRobustaDataRef?.current)
    } else if ((seletectedWorkBookType === 2 && selectedContract?.idMarket === 2) || seletectedWorkBookType === 4) {
      addNewRowInWorkbook('arabicaWorkbook', workbookArabicaDataRef?.current)
    }
    function addNewRowInWorkbook(marketType, state) {
      const isRobustaWorkbook = marketType === 'robustaWorkbook' ? true : false
      const { idMarket, contractName } = selectedContract
      const actualIdMarketType = idMarket === 1 || idMarket === 2 ? idMarket : seletectedWorkBookType === 3 ? 1 : 2
      const highlightRow = true
      const updatedArray = state ? [...state, createEmptyWorkbookObject(idMarket, actualIdMarketType, contractName, highlightRow)] : [createEmptyWorkbookObject(idMarket, actualIdMarketType, contractName, highlightRow)]

      if (isRobustaWorkbook) {
        workbookRobustaDataRef.current = updatedArray
        dispatch({ payload: { workbookRobustaData: updatedArray } })
      } else {
        workbookArabicaDataRef.current = updatedArray
        dispatch({ payload: { workbookArabicaData: updatedArray } })
      }

      filterWorkBookDataResponse(robustaRef?.current, arabicaRef?.current, fxCurrencyListRef?.current)
      const scrollIndex = updatedArray?.findIndex((ele) => ele?.isHighlight == 'newelyAdded')
      const targetDiv = document.querySelector(`.smoothScrollForArabica:nth-child(${scrollIndex})`)

      if (targetDiv) {
        const scrollToPosition = targetDiv.getBoundingClientRect().top + window.pageYOffset - 100

        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
      }

      let resetTimer

      clearTimeout(resetTimer)
      resetTimer = setTimeout(() => {
        if (isRobustaWorkbook) {
          const workbookRobustaData = workbookRobustaDataRef?.current?.map((ele) => (ele?.isHighlight === 'newelyAdded' ? { ...ele, isHighlight: 'normal_white_bg_color' } : ele))

          workbookRobustaDataRef.current = workbookRobustaData
          dispatch({ payload: { workbookRobustaData } })
        } else {
          const workbookArabicaData = workbookArabicaDataRef?.current?.map((ele) => (ele?.isHighlight === 'newelyAdded' ? { ...ele, isHighlight: 'normal_white_bg_color' } : ele))

          workbookArabicaDataRef.current = workbookArabicaData
          dispatch({ payload: { workbookArabicaData } })
        }
      }, 5000)
    }
  }

  const handleChange = (event, data, index) => {
    const { value, name } = event.target

    if (/^-?\d*\.?\d{0,2}$/.test(value) || value === '' || Number.isNaN(Number(value))) {
      const isRobustaWorkbook = data?.workbookType === 1
      const numberOfDecimal = isRobustaWorkbook ? 0 : 2
      const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
      const updatedArray = workbookArray.map((ele, ind) => {
        if (ind === index) {
          const modifiedElement = {
            ...ele,
            [name]: value
          }
          const { isNetPriceValueCustom, isLastValueCustom, isOutRateValueCustom, fxnet, customLastChg, lastChng, customNetPrice, workbookDifferential, fxcurrency, fxpredis, profit, customWorkbookDifferential } = modifiedElement
          const actualLastChng = isLastValueCustom ? customLastChg : lastChng
          const calculatedFxNet = sumTwoValues(fxcurrency, fxpredis, 2)

          if (isNetPriceValueCustom) {
            const calculatedNetPrice = parseFloat(customNetPrice || 0).toFixed(2)
            const calculatedGrossPrice = substractValues(calculatedNetPrice, profit, 2)
            const convertedToValue = isRobustaWorkbook ? 1 : convertToValue
            const calculatedWorkbookTotal = handleWorkbookTotalForCustomNetPrice(calculatedGrossPrice, calculatedFxNet, numberOfDecimal, convertedToValue)
            const calculatedConvertTo = parseFloat(calculatedWorkbookTotal * convertToValue).toFixed(0)
            const calculatedCustomWorkbookDifferential = substractValues(calculatedWorkbookTotal, actualLastChng, numberOfDecimal)

            const calculatedObject = {
              ...modifiedElement,
              netPrice: calculatedNetPrice,
              grossPrice: calculatedGrossPrice,
              fxnet: calculatedFxNet,
              workbookTotal: calculatedWorkbookTotal,
              customWorkbookDifferential: calculatedCustomWorkbookDifferential
            }

            if (!isRobustaWorkbook) {
              calculatedObject.convertTo = calculatedConvertTo
            }

            return calculatedObject
          } else {
            const actualWorkbookDifferential = isOutRateValueCustom ? customWorkbookDifferential : workbookDifferential
            const calculatedWorkbookTotal = sumTwoValues(actualLastChng, actualWorkbookDifferential, numberOfDecimal)
            const calculatedConvertTo = handleWorkbookConvertTo(calculatedWorkbookTotal, 0)
            const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxNet, isRobustaWorkbook ? calculatedWorkbookTotal : calculatedConvertTo, 2)
            const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
            const calculatedObject = {
              ...modifiedElement,
              workbookTotal: calculatedWorkbookTotal,
              fxnet: calculatedFxNet,
              grossPrice: calculatedGrossPrice,
              netPrice: calculatedNetPrice
            }

            if (!isRobustaWorkbook) {
              calculatedObject.convertTo = calculatedConvertTo
            }

            const actualOutRate = isRobustaWorkbook ? value : parseFloat(value / convertToValue).toFixed(numberOfDecimal)
            const calculatedGrossPriceForOutRate = handleWorkbookGrossPrice(value, fxnet, 2)
            const calculatedNetPriceForOutRate = sumTwoValues(calculatedGrossPriceForOutRate, profit, 2)
            const calculatedWorkbookTotalForOutRate = substractValues(actualOutRate, lastChng, numberOfDecimal)
            const actualConvertedTo = value
            const isOutRateCustomValue = name === 'customOutRate'
            const calculatedObjectForOutRate = {
              ...modifiedElement,
              customWorkbookDifferential: calculatedWorkbookTotalForOutRate,
              workbookTotal: actualOutRate,
              grossPrice: calculatedGrossPriceForOutRate,
              netPrice: calculatedNetPriceForOutRate
            }

            if (!isRobustaWorkbook) {
              calculatedObjectForOutRate.convertTo = actualConvertedTo
            }

            return isOutRateCustomValue ? calculatedObjectForOutRate : calculatedObject
          }
        }

        return ele
      })

      if (isRobustaWorkbook) {
        workbookRobustaDataRef.current = updatedArray
        dispatch({ payload: { workbookRobustaData: updatedArray } })
      } else {
        workbookArabicaDataRef.current = updatedArray
        dispatch({ payload: { workbookArabicaData: updatedArray } })
      }
    }
  }

  const handleChangeForDivider = (event, data, index) => {
    handleCellChange(data, index, 'contractName', event.target.value)
  }

  const handleFocus = (data, index) => {
    handleCellChange(data, index, 'isHighlight', 'activeFocus')
  }

  const handleBlur = (data, index) => {
    handleCellChange(data, index, 'isHighlight', 'normal_white_bg_color')
  }

  const handleWorkBookForRobustaAndArabica = (isRobustaWorkbook, updatedArray) => {
    if (isRobustaWorkbook) {
      workbookRobustaDataRef.current = updatedArray
      dispatch({ payload: { workbookRobustaData: updatedArray } })
    } else {
      workbookArabicaDataRef.current = updatedArray
      dispatch({ payload: { workbookArabicaData: updatedArray } })
    }
  }

  const handleCellChange = (data, index, key, value) => {
    const isRobustaWorkbook = data?.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const updatedArray = workbookArray?.map((ele, ind) => (index === ind ? { ...ele, [key]: value } : ele))

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const handleSaveWorkbook = async (type, newList) => {
    const isRobustaWorkbook = type === 'robusta'
    const workbookData = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const currencyType = isRobustaWorkbook ? selectedCurrencyRobustaRef?.current : selectedCurrencyArabicaRef?.current
    const actualWorkbookData = newList || workbookData
    const hasDuplicateDividers = (data) => data?.some((item) => !item.contractName)

    if (actualWorkbookData?.length > 0) {
      if (!hasDuplicateDividers(actualWorkbookData)) {
        setLoading(true)
        const postData = actualWorkbookData?.map((ele, index) => {
          const { idMarket, idWorkbook, contractName, workbookType, outRate, marketNetPrice, grade, isLastValueCustom, customLastChg, lastChng, workbookDifferential, workbookTotal, convertTo, fxpredis, expenseIn, grandTotal, fxcurrency, fxnet, grossPrice, netPrice, profit, isNetPriceValueCustom, customWorkbookDifferential, isOutRateValueCustom } = ele
          const lastChngValue = isLastValueCustom ? customLastChg : lastChng

          const workBookObject = {
            idWorkbook: typeof idWorkbook === 'string' ? 0 : idWorkbook,
            createdBy: 0,
            createdOn: '2023-08-23T06:58:59.778Z',
            updatedBy: 0,
            updatedDtms: '2023-08-23T06:58:59.778Z',
            idUser: userId,
            idMarket,
            contractName,
            workbookType,
            outRate,
            marketNetPrice,
            grade,
            chng: 0,
            lastChng: lastChngValue,
            lastChg: lastChngValue,
            workbookDifferential: workbookDifferential || 0,
            workbookTotal,
            convertTo,
            expenseIn,
            grandTotal: parseFloat(grandTotal),
            fxcurrencyType: currencyType,
            fxcurrency,
            fxpredis: fxpredis || 0,
            fxnet: parseFloat(fxnet),
            grossPrice: parseFloat(grossPrice),
            netPrice: parseFloat(netPrice),
            profit: profit || 0,
            orderBy: index,
            isLastValueCustom,
            isNetPriceValueCustom,
            customWorkbookDifferential,
            isOutRateValueCustom
          }

          return workBookObject
        })

        await apiAdapterCoffeeWeb
          .addWorkbook(postData)
          .then((response) => {
            const updatedArray = actualWorkbookData?.map((ele, index) => ({ ...ele, idWorkbook: response.data?.returnLst[index].idWorkbook }))
            const uncheckAll = updatedArray?.map((ele) => ({ ...ele, isChecked: false }))

            if (isRobustaWorkbook) {
              setHasDefaultValuesForRobusta(false)
            } else {
              setHasDefaultValuesForArabica(false)
            }
            handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)

            setTimeout(() => {
              handleWorkBookForRobustaAndArabica(isRobustaWorkbook, uncheckAll)
            }, 700)

            if (newList === null) {
              showSuccess({
                title: t('SUCCESS'),
                message: t('CHANGES_UPDATED_SUCCESSFULLY'),
                buttonLabel: t('OK')
              })
            }
          })
          .catch(() => {
            showError({
              title: t('ERROR_TITLE'),
              message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
              buttonLabel: t('OK')
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        showError({
          title: t('ERROR_TITLE'),
          message: t('PLEASE_FILL_DIVIDER_NAME'),
          buttonLabel: t('OK')
        })
      }
    }
  }

  const handleFxCurrencySelectForRobusta = (value) => {
    // value and item.last is string in some case
    const tergetedIndex = fxCurrencyListRef?.current?.findIndex((item) => item.last == value)

    const updatePrice = workbookRobustaDataRef?.current?.map((item) => {
      const fxNet = sumTwoValues(value, item?.fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxNet, item?.workbookTotal, 2)
      const netPrice = sumTwoValues(grossPrice, item?.profit, 2)

      return {
        ...item,
        fxcurrency: parseFloat(value),
        fxnet: fxNet,
        grossPrice,
        netPrice
      }
    })

    workbookRobustaDataRef.current = updatePrice
    selectedCurrencyRobustaRef.current = tergetedIndex
    dispatch({ payload: { workbookRobustaData: updatePrice, selectedCurrencyRobusta: tergetedIndex } })
  }

  const handleFxCurrencySelectForArabica = (value) => {
    // value and item.last is string in some condition
    const tergetedIndex = fxCurrencyListRef?.current?.findIndex((item) => item.last == value)

    const updatePrice = workbookArabicaDataRef?.current.map((item) => {
      const fxNet = sumTwoValues(value, item.fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxNet, item.workbookTotal * convertToValue, 2)
      const netPrice = sumTwoValues(grossPrice, item?.profit, 2)

      return {
        ...item,
        fxcurrency: value,
        fxnet: fxNet,
        grossPrice,
        netPrice
      }
    })

    workbookArabicaDataRef.current = updatePrice
    selectedCurrencyArabicaRef.current = tergetedIndex
    dispatch({ payload: { workbookArabicaData: updatePrice, selectedCurrencyArabica: tergetedIndex } })
  }

  const handleClickDeleteWorkBookWorkBook = async (type) => {
    const isRobustaWorkbook = type === 'robusta'
    if (hasDefaultValuesForRobusta && isRobustaWorkbook) {
      await handleSaveWorkbook(type, workbookRobustaDataRef?.current)
    }
    if (hasDefaultValuesForArabica && !isRobustaWorkbook) {
      await handleSaveWorkbook(isRobustaWorkbook, workbookArabicaDataRef?.current)
    }
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const workbookIds = workbookArray?.filter((ele) => ele?.isChecked).map((ele) => ele?.idWorkbook)
    const workbookIdsForDelete = workbookIds?.filter((ele) => typeof ele !== 'string')

    if (workbookIdsForDelete?.length) {
      showConfirmation({
        title: t('DELETE_RECORD'),
        message: `<p>${t('YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS')}</p><p>${t('WORKBOOK_DELETE_CONFIRM')}</p>`,
        buttonLabel: t('YES_DELETE_IT'),
        dismissButtonLabel: t('CANCEL'),
        buttonAction: 'danger-action',
        onConfirm: async () => {
          setLoading(true)
          try {
            const response = await apiAdapterCoffeeWeb.deleteMarketWorkbookInBulk(workbookIdsForDelete?.toString())

            if (response.status === 200) {
              showSuccess({
                title: t('SUCCESS'),
                message: t('WORKBOOK_DELETED_SUCCESSFULLY'),
                buttonLabel: t('OK')
              })
              filterWorkbooksOnDelete(isRobustaWorkbook, workbookIds)
            }
          } catch (error) {
            showError({
              title: t('ERROR_TITLE'),
              message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
              buttonLabel: t('OK')
            })
          } finally {
            setLoading(false)
          }
        }
      })
    } else if (workbookIds) {
      filterWorkbooksOnDelete(isRobustaWorkbook, workbookIds)
    }
  }

  const filterWorkbooksOnDelete = (isRobustaWorkbook, workbookIds) => {
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const updatedArray = workbookArray?.filter((ele) => !workbookIds.includes(ele.idWorkbook) && { ...ele, isHighlight: 'normal_white_bg_color' })

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const onDragStartRobusta = (event, id) => {
    event.dataTransfer.setData('text/plain', id)
    setDraggableRow('robusta')
  }

  const onDragStartArabica = (event, id) => {
    event.dataTransfer.setData('text/plain', id)
    setDraggableRow('arabica')
  }

  const onDropworkbook = (event, index) => {
    const isRobustaWorkbook = draggableRow === 'robusta'
    const workbookList = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current

    event.preventDefault()
    const anyCheckedRows = workbookList?.some(({ isChecked }) => isChecked)

    if (anyCheckedRows) {
      const draggedDivs = workbookList?.filter(({ isChecked }) => isChecked)
      const updatedArray = workbookList?.filter(({ isChecked }) => !isChecked)

      updatedArray.splice(index, 0, ...draggedDivs)

      handleSaveWorkbook(draggableRow, updatedArray)
    } else {
      const draggedId = event.dataTransfer.getData('text/plain')
      const draggedDiv = workbookList?.find(({ idWorkbook }) => idWorkbook == draggedId)
      const updatedArray = workbookList?.filter(({ idWorkbook }) => idWorkbook != draggedId)

      updatedArray.splice(index, 0, draggedDiv)

      handleSaveWorkbook(draggableRow, updatedArray)
    }
  }

  const checkForDragableoption = ({ idMarket, isChecked }) => {
    const workbookList = idMarket === 1 ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const anyOfRowChecked = workbookList?.some(({ isChecked }) => isChecked)

    return !anyOfRowChecked || isChecked
  }

  const swapLastChg = (ele, index, type) => {
    const isRobustaWorkbook = type === 'workbookRobusta'
    const { lastChng, customLastChg, fxcurrency, fxpredis, isLastValueCustom, isNetPriceValueCustom, workbookDifferential, customWorkbookDifferential } = ele
    const workbookList = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const calculatedLastChng = customLastChg === 0 ? lastChng : customLastChg
    const calculatedWorkbookTotal = sumTwoValues(isLastValueCustom ? lastChng : lastChng, isNetPriceValueCustom ? customWorkbookDifferential : workbookDifferential, isRobustaWorkbook ? 0 : 2)
    const calculatedFxNet = sumTwoValues(fxcurrency, fxpredis, 2)
    const grossPrice = handleWorkbookGrossPrice(calculatedFxNet, isRobustaWorkbook ? calculatedWorkbookTotal : calculatedWorkbookTotal * convertToValue, 2)
    const netPrice = sumTwoValues(grossPrice, ele?.profit, 2)
    const convertTo = handleWorkbookConvertTo(calculatedWorkbookTotal, 0)
    const customNetPrice = parseFloat(netPrice).toFixed(0)

    const updatedArray = workbookList.map((element, ind) => {
      if (ind === index) {
        const modifiedElement = {
          ...element,
          customLastChg: calculatedLastChng,
          isLastValueCustom: !element.isLastValueCustom,
          workbookTotal: calculatedWorkbookTotal,
          grandTotal: netPrice,
          grossPrice,
          netPrice,
          customNetPrice,
          isOutRateValueCustom: false
        }

        if (!isRobustaWorkbook) {
          modifiedElement.convertTo = convertTo
          modifiedElement.outRate = convertTo
        }

        return modifiedElement
      }

      return element
    })

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const swapNetPrice = (ele, index, type) => {
    const isRobustaWorkbook = type === 'workbookRobusta'
    const workbookList = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const { isNetPriceValueCustom, isLastValueCustom, fxpredis, fxcurrency, customLastChg, workbookDifferential, customNetPrice, profit, fxnet, lastChng } = ele

    if (!isNetPriceValueCustom && customNetPrice) {
      const netPrice = parseFloat(customNetPrice).toFixed(2)
      const grossPrice = (customNetPrice - profit).toFixed(2)
      const calculatedWorkbookTotal = ((grossPrice * 1000) / fxnet).toFixed(0)
      const customWorkbookDifferential = parseInt(calculatedWorkbookTotal, 10) - lastChng
      const updatedArray = workbookList.map((item, ind) => {
        if (ind === index) {
          const modifiedElement = {
            ...item,
            isNetPriceValueCustom: !item.isNetPriceValueCustom,
            netPrice,
            grossPrice,
            workbookTotal: calculatedWorkbookTotal,
            customWorkbookDifferential,
            isOutRateValueCustom: false
          }

          if (!isRobustaWorkbook) {
            modifiedElement.convertTo = calculatedWorkbookTotal
            modifiedElement.workbookTotal = (calculatedWorkbookTotal / convertToValue).toFixed(2)
            modifiedElement.customWorkbookDifferential = ((calculatedWorkbookTotal / convertToValue).toFixed(2) - lastChng).toFixed(2)
          }

          return modifiedElement
        }

        return item
      })

      handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
    } else {
      const calculatedWorkbookTotal = sumTwoValues(isLastValueCustom ? customLastChg : lastChng, workbookDifferential, isRobustaWorkbook ? 0 : 2)
      const fxnet = sumTwoValues(fxcurrency, fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxnet, isRobustaWorkbook ? calculatedWorkbookTotal : (calculatedWorkbookTotal * convertToValue).toFixed(0), 2)
      const netPrice = sumTwoValues(grossPrice, profit, 2)
      const updatedArray = workbookList.map((item, ind) => {
        if (ind === index) {
          const modifiedElement = {
            ...item,
            isNetPriceValueCustom: !item.isNetPriceValueCustom,
            workbookTotal: calculatedWorkbookTotal,
            fxnet,
            grossPrice,
            netPrice
          }

          if (!isRobustaWorkbook) {
            modifiedElement.convertTo = (calculatedWorkbookTotal * convertToValue).toFixed(0)
          }

          return modifiedElement
        }

        return item
      })

      handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
    }
  }

  const swapOutRate = (ele, index) => {
    const { idMarket, customOutRate, profit, lastChng, fxcurrency, fxpredis } = ele
    const customOutRateValue = customOutRate || 0
    const isRobustaWorkbook = idMarket === 1
    const numberOfDecimal = isRobustaWorkbook ? 0 : 2
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const calculatedFxNet = sumTwoValues(fxcurrency, fxpredis, 2)
    const actualOutRate = isRobustaWorkbook ? customOutRateValue : (customOutRateValue / convertToValue).toFixed(2)
    const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxNet, customOutRateValue, 2)
    const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
    const customWorkbookDifferential = substractValues(actualOutRate, lastChng, numberOfDecimal)
    const updatedArray = workbookArray.map((item, ind) => {
      if (ind === index) {
        const modifiedElement = {
          ...item,
          isOutRateValueCustom: !item?.isOutRateValueCustom,
          isNetPriceValueCustom: false,
          isLastValueCustom: false,
          workbookTotal: actualOutRate,
          grossPrice: calculatedGrossPrice,
          netPrice: calculatedNetPrice,
          customWorkbookDifferential
        }

        if (!isRobustaWorkbook) {
          modifiedElement.convertTo = customOutRateValue
        }

        return modifiedElement
      }

      return item
    })

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const selectAllCheckBoxForDivider = (ele, index) => {
    const isRobustaWorkbook = ele.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const updatedArray = [...workbookArray]

    updatedArray[index].isChecked = !updatedArray[index].isChecked

    for (let i = index + 1; i < workbookArray.length; i++) {
      if (workbookArray[i].workbookType === 0) {
        break
      }
      updatedArray[i].isChecked = updatedArray[index]?.isChecked
    }

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const handleSelectWorkbookCheckBox = (ele, index) => {
    const isRobustaWorkbook = ele.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const updatedArray = workbookArray?.map((ele, ind) => (index === ind ? { ...ele, isChecked: !ele?.isChecked } : ele))

    let firstParentIndex = -1

    for (let i = index - 1; i >= 0; i--) {
      if (updatedArray[i]?.workbookType === 0) {
        firstParentIndex = i
        break
      }
    }

    let lastParentIndex = updatedArray.length

    for (let i = index + 1; i < updatedArray.length; i++) {
      if (updatedArray[i]?.workbookType === 0) {
        lastParentIndex = i
        break
      }
    }

    const areAllChecked = updatedArray?.slice(firstParentIndex + 1, lastParentIndex).every((ele) => ele?.isChecked)

    if (firstParentIndex !== -1) {
      updatedArray[firstParentIndex].isChecked = areAllChecked
    }

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const selectAllWorkbook = (type) => {
    const isRobustaWorkbook = type === 'robusta'
    const workbookArray = isRobustaWorkbook ? workbookRobustaDataRef?.current : workbookArabicaDataRef?.current
    const state = isRobustaWorkbook ? workbookRobustaAllChecked : workbookArabicaAllChecked
    const updatedArray = workbookArray?.map((ele) => ({ ...ele, isChecked: !state }))

    handleWorkBookForRobustaAndArabica(isRobustaWorkbook, updatedArray)
  }

  const { buttonText, infoMessage, className, navigationPath } = getButtonAndInfo()

  const handleUserClick = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const headerContent = (
    <div onClick={handleClose} className="user-guide-header-content">
      <i className="pi pi-times-circle"></i>
    </div>
  )

  const footerContent = (
    <div className="user-guide-dialog-footer">
      <div className="social-buttons-container">
        <div className="social-buttons">
          <div className="flex">
            <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
              <AppleIcon fill={'#fff'} className="social-images" />
              <p>
                {t('DOWNLOAD_ON_THE')}
                <br /> <span className="big-txt">{t('APP_STORE')}</span>
              </p>
            </a>
            <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
              <img alt="GooglePlay" src={GooglePlay} className="social-images" />
              <p>
                {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )

  const handleClickDropdown = (event) => {
    const dropdown = event.target.closest('.drop_down, .contract-select-drop-down')
    if (dropdown) {
      const rect = dropdown.getBoundingClientRect()
      setTimeout(() => {
        const root = document.documentElement
        root.style.setProperty('--topProperty', `${rect.top + 35}px`)
        root.style.setProperty('--positionProperty', `fixed`)
        root.style.setProperty('--displayProperty', `block`)
      }, 0)
    }
  }

  return (
    <div className="workbook-component-wrapper" data-testid={testId}>
      <div className="workbook_and_forex_tab-container" data-testid="work-book-tab-container">
        <div className={isWorkBookAvailable || (isPlatinumUser && isIndianUser) ? 'user-guide-button' : 'user-availability-guide-button'} data-testid="user-guide-button">
          <Button buttonType="CUSTOM" buttonLabel={'User Guide'} onButtonClick={handleUserClick} buttonSize="medium" />
        </div>
        <div className="workbox-and-forex-content" data-testid="workbook-forex-content">
          <div className={`${isWorkBookTabActive ? 'active_tab' : 'in_active_tab'}`} onClick={() => setIsWorkBookTabActive(true)}>
            WorkBook
          </div>
          <div className={`${isWorkBookTabActive ? 'in_active_tab' : 'active_tab'}`} onClick={() => setIsWorkBookTabActive(false)}>
            Forex
          </div>
        </div>
        <div className="dropdown-workbook-container">
          {isWorkBookAvailable || (isPlatinumUser && isIndianUser) ? (
            <div className="search_and_add">
              <div className="contract-select-drop-down" data-testid="contract-select-drop-down">
                <Dropdown onClick={(e) => handleClickDropdown(e)} value={seletectedWorkBookType} options={handleWorkbookDropDown} optionLabel="contractType" onChange={handleSelectContractDropdown} panelClassName="coffeequotes-workbook-dropdowns" disabled={!(isWorkBookAvailable || (isPlatinumUser && isIndianUser))} />
              </div>
              <div className="drop_down_for_add">
                <div className="drop_down_div">
                  {seletectedWorkBookType === 1 || seletectedWorkBookType === 2 ? (
                    <div className="drop_down" data-testid="add-contarct-dropdown">
                      <Dropdown onClick={(e) => handleClickDropdown(e)} value={selectedContract} options={searchContractList} optionLabel="contractName" onChange={(event) => setSelectedContract(event.target.value)} placeholder={t('ADD_CONTRACT')} className="contract-selection" panelClassName="coffeequotes-workbook-dropdowns" filter />
                    </div>
                  ) : (
                    <div className="dividerDropDownDiv">
                      {seletectedWorkBookType === 3 ? <>{t('ROBUSTA_DIVIDER')}</> : <>{t('ARABICA_DIVIDER')}</>}
                      <DragIcon />
                    </div>
                  )}
                </div>
              </div>
              <button onClick={addWorkBookRowToRespectiveTable} className="add_btn" data-testid="add-button">
                {t('ADD')}
              </button>
            </div>
          ) : (
            <div className="empty-div">{/* This div should be empty */}</div>
          )}
        </div>
      </div>

      <div className="workbook_forex_createmodal" data-testid="workbook-forex-create-modal">
        <div className={isWorkBookAvailable || (isPlatinumUser && isIndianUser) ? '' : 'workbook_forex_blur_bg'}>
          {isWorkBookTabActive ? (
            <>
              {/* workbook arabica and arabica table starts */}
              <table className="worbook_table" data-testid="workbook-table">
                <tbody className="workbook_body" data-testid="workbook-body">
                  <tr className="table_head smoothScrollForRobusta">
                    <td className="contract_names" data-testid="contract-names">
                      <span>{t('ROBUSTA_WORKBOOK')}</span>
                    </td>
                    <td data-testid="robusta-workbook-grade-heading">{t('GRADE')}</td>
                    <td data-testid="robusta-workbook-netPrice-heading">{t('NET_PRICE')}</td>
                    <td data-testid="robusta-workbook-out-rate-heading">{t('OUT_RATE')}</td>
                    <td data-testid="robusta-workbook-last-heading">{t('LAST')}</td>
                    <td data-testid="robusta-workbook-differential-heading">{t('DIFFERENTIAL')}</td>
                    <td colSpan={2} data-testid="robusta-workbook-total-heading">
                      {t('TOTAL')}
                    </td>
                    <td>
                      <div className="fx_currency" data-testid="fx-currency">
                        {t('FX')}-
                        <select className="fxcurrency_select_dropdown" data-testid="robusta-fx-currency-dropdown" onChange={(e) => handleFxCurrencySelectForRobusta(e.target.value, 'robusta')} value={fxCurrencyListRef?.current?.[selectedCurrencyRobustaRef?.current]?.last}>
                          {fxCurrencyListRef?.current?.map((item, index) => (
                            <option value={item.last} className="" key={index}>
                              {item.currencyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td data-testid="robusta-workbook-FX-Pre-Dis">{t('FX_PRE_DIS')}</td>
                    <td data-testid="robust-workbook-Fx-net">{t('FX_NET')}</td>
                    <td data-testid="robusta-workbook-gross-price">{t('GROSS_PRICE')}</td>
                    <td data-testid="robusta-workbook-profit">{t('PROFIT')}</td>
                    <td data-testid="robusta-workbook-net-price">{t('NET_PRICE')}</td>
                    <td>
                      <Tooltip target=".robusta-save-icon" position="top" content={t('SAVE_ALL_CHANGES')} className="coffee-quotes-custom-tooltip" />
                      <div className="robusta-save-icon" data-testid="robusta-save-icon-container">
                        <SaveIcon className="workBookIcons" data-testid="robusta-save-icon" fontSize="small" stroke={workbookRobustaDataRef?.current?.length > 0 ? 'green' : 'gray'} onClick={() => handleSaveWorkbook('robusta', null)} />
                      </div>
                    </td>
                    <td>
                      <Tooltip target=".robusta-check-box" position="left" content={t('SELECT_ALL_ROBUSTA_WORKBOOK')} className="coffee-quotes-custom-tooltip" />
                      <div className="robusta-check-box" data-testid="robusta-workbook-input-container">
                        <input className="delete-checkbox" data-testid="robusta-workbook-delete-checkbox" type="checkbox" onChange={() => selectAllWorkbook('robusta')} checked={workbookRobustaAllChecked && workbookRobustaDataRef?.current?.length !== 0} />
                      </div>
                    </td>
                    <td>
                      <Tooltip target=".workbook-delete-icon" position="left" content={t('DELETE_SELECTED_WORKBOOK_DATA')} className="coffee-quotes-custom-tooltip" />
                      <div className="workbook-delete-icon" data-testid="robusta-workbook-delete-icon-container">
                        <DeleteIcon className="workBookIcons" data-testid="robusta-workbook-delete-icon" onClick={showDeleteButtonForRobusta ? () => handleClickDeleteWorkBookWorkBook('robusta') : null} fill={showDeleteButtonForRobusta ? '#FF0000' : '#6c757d'} />
                      </div>
                    </td>
                  </tr>
                  {workbookRobustaDataRef?.current &&
                    workbookRobustaDataRef?.current?.map((ele, index) => (
                      <tr id={ele?.isChecked && 'selected-workbook-row'} draggable={checkForDragableoption(ele)} onDragStart={(event) => onDragStartRobusta(event, ele?.idWorkbook)} onDragOver={(event) => event.preventDefault()} onDrop={(event) => onDropworkbook(event, index)} key={index} className={getTableRowClassName(ele, 'Robusta')}>
                        {ele?.workbookType !== 0 && (
                          <>
                            <td className="contract_names" data-title={t('ROBUSTA')}>
                              {ele?.contractName}
                            </td>
                            <td data-title={t('GRADE')}>
                              <div className="input_div">
                                <input type="text" className="input_field grade" name="grade" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.grade} placeholder="Grade" autoComplete="off" />
                              </div>
                            </td>
                            <td data-title={t('NET_PRICE')} className="custom-netPrice">
                              <div>
                                {ele?.isNetPriceValueCustom ? <input type="number" className="input_field custom-input" name="customNetPrice" onChange={(event) => handleChange(event, ele, index)} value={ele?.customNetPrice} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition)}>{handleDecimal(ele?.netPrice, 0)}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapNetPrice(ele, index, 'workbookRobusta')} />
                              </div>
                            </td>
                            <td data-title={t('OUT_RATE')} className="custom-lastChg">
                              <div>
                                {ele?.isOutRateValueCustom ? <input type="number" className="input_field custom-input" name="customOutRate" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customOutRate} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition, ele)}>{ele?.workbookTotal}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapOutRate(ele, index, 'workbookRobusta')} />
                              </div>
                            </td>
                            <td data-title={t('LAST')} className="custom-lastChg">
                              <div>
                                {ele?.isLastValueCustom ? <input type="number" className="input_field custom-input" name="customLastChg" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customLastChg} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition)}>{ele?.lastChng}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapLastChg(ele, index, 'workbookRobusta')} />
                              </div>
                            </td>
                            <td data-title={t('DIFFERENTIAL')}>
                              <div className="input_div">
                                {/* here ele?.workbookDifferential is string */}
                                {ele?.isNetPriceValueCustom || ele.isOutRateValueCustom ? <span className="bold_and_dark_gray">{ele.customWorkbookDifferential}</span> : <input type="number" className="input_field" name="workbookDifferential" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.workbookDifferential != 0 && ele?.workbookDifferential} placeholder="0" />}
                              </div>
                            </td>
                            <td data-title={t('TOTAL')} colSpan={2}>
                              {ele?.workbookTotal}
                            </td>
                            <td data-title="FX Currency" className="fxCurrency">
                              {handleDecimal(ele?.fxcurrency, 2)}
                            </td>
                            <td data-title="FX-Pre/Dis">
                              <div className="input_div">
                                {/* here ele?.fxpredis is string */}
                                <input type="number" className="input_field" name="fxpredis" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.fxpredis != 0 && ele?.fxpredis} placeholder="0" />
                              </div>
                            </td>
                            <td data-title="FX Net">{ele?.fxnet}</td>
                            <td data-title="Gross Price">{ele?.grossPrice}</td>
                            <td data-title="Profit">
                              <div className="input_div">
                                {/* here ele?.profit is string */}
                                <input type="number" className="input_field" name="profit" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.profit != 0 && ele?.profit} placeholder="0" />
                              </div>
                            </td>
                            <td data-title="Net Price">
                              <span className="span_net_price">{ele?.netPrice}</span>
                            </td>
                            <td>{/* This td should be empty */}</td>
                            <td>
                              <input className="delete-checkbox" type="checkbox" onChange={() => handleSelectWorkbookCheckBox(ele, index)} checked={ele?.isChecked} />
                            </td>
                            <td>
                              <DragIcon />
                            </td>
                          </>
                        )}
                        {ele?.workbookType === 0 && (
                          <>
                            <td className="workbookDividerTd" colSpan={14}>
                              <input className="dividerInput" placeholder="Divider name" value={ele?.contractName} onChange={(event) => handleChangeForDivider(event, ele, index)} />
                            </td>
                            <td className="workbookDividerTd"></td>
                            <td className="workbookDividerTd">
                              <input className="delete-checkbox" type="checkbox" onChange={() => selectAllCheckBoxForDivider(ele, index)} checked={ele?.isChecked ? true : false} />
                            </td>
                            <td className="workbookDividerTd">
                              <DragIcon />
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
                <tbody className="workbook_body">
                  <tr className="table_head smoothScrollForArabica" data-testid="arabica-workbook-scroll-container">
                    <td className="contract_names">
                      <span>{t('ARABICA_WORKBOOK')}</span>
                    </td>
                    <td data-testid="arabica-workbook-grade-heading">{t('GRADE')}</td>
                    <td data-testid="arabica-workbook-netPrice-heading">{t('NET_PRICE')}</td>
                    <td data-testid="arabica-workbook-out-rate-heading">{t('OUT_RATE')}</td>
                    <td data-testid="arabica-workbook-last-heading">{t('LAST')}</td>
                    <td data-testid="arabica-workbook-differential-heading">{t('DIFFERENTIAL')}</td>
                    <td data-testid="arabica-workbook-total-heading">{t('TOTAL')}</td>
                    <td data-testid="arabica-workbook-convertTo-heading">{t('CONVTO')}-$</td>
                    <td>
                      <div className="fx_currency" data-testid="fx-currency-container">
                        {t('FX')}-
                        <select onChange={(e) => handleFxCurrencySelectForArabica(e.target.value)} className="fxcurrency_select_dropdown" value={fxCurrencyListRef?.current?.[selectedCurrencyArabicaRef?.current]?.last}>
                          {fxCurrencyListRef?.current?.map((item, index) => (
                            <option value={item.last} className="" key={index}>
                              {item.currencyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td data-testid="arabica-workbook-fx-pre-dis">{t('FX_PRE_DIS')}</td>
                    <td data-testid="arabica-workbook-fx-net">{t('FX_NET')}</td>
                    <td data-testid="arabica-workbook-gross-price-heading">{t('GROSS_PRICE')}</td>
                    <td data-testid="arabica-work-book-profit-heading">{t('PROFIT')}</td>
                    <td>{t('NET_PRICE')}</td>
                    <td>
                      <Tooltip target=".arabica-save-icon" position="top" content={t('SAVE_ALL_CHANGES')} className="coffee-quotes-custom-tooltip" />
                      <div className="arabica-save-icon" data-testid="arabica-save-icon-container">
                        <SaveIcon className="workBookIcons" data-testid="arabica-save-icon" fontSize="small" stroke={workbookArabicaDataRef?.current?.length > 0 ? 'green' : 'gray'} onClick={() => handleSaveWorkbook('arabica', null)} />
                      </div>
                    </td>
                    <td>
                      <Tooltip target=".arabica-check-box" position="left" content={t('SELECT_ALL_ARABICA_WORKBOOK')} className="coffee-quotes-custom-tooltip" />
                      <div className="arabica-check-box" data-testid="arabica-check-box">
                        <input className="delete-checkbox" data-testid="arabica-delete-check-box" type="checkbox" onChange={() => selectAllWorkbook('arabica')} checked={workbookArabicaAllChecked && workbookArabicaDataRef?.current?.length !== 0} />
                      </div>
                    </td>
                    <td>
                      <Tooltip target=".arabica-delete-icon" position="left" content={t('DELETE_SELECTED_WORKBOOK_DATA')} className="coffee-quotes-custom-tooltip" />
                      <div className="arabica-delete-icon" data-testid="arabica-delete-icon-container">
                        <DeleteIcon className="workBookIcons" onClick={showDeleteButtonForArabica ? () => handleClickDeleteWorkBookWorkBook('arabica') : null} fill={showDeleteButtonForArabica ? '#FF0000' : '#6c757d'} />
                      </div>
                    </td>
                  </tr>
                  {workbookArabicaDataRef?.current &&
                    workbookArabicaDataRef?.current?.map((ele, index) => (
                      <tr id={ele?.isChecked && 'selected-workbook-row'} draggable={checkForDragableoption(ele)} onDragStart={(event) => onDragStartArabica(event, ele?.idWorkbook)} onDragOver={(event) => event.preventDefault()} onDrop={(event) => onDropworkbook(event, index)} key={index} className={getTableRowClassName(ele, 'Arabica')}>
                        {ele.workbookType !== 0 && (
                          <>
                            <td className="contract_names" data-title="Arabica">
                              {ele?.contractName}
                            </td>
                            <td data-title="Grade">
                              <div className="input_div">
                                <input type="text" className="input_field grade" name="grade" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.grade} placeholder="Grade" autoComplete="off" />
                              </div>
                            </td>

                            <td data-title="Net Price" className="custom-netPrice">
                              <div>
                                {ele?.isNetPriceValueCustom ? <input type="number" className="input_field custom-input" name="customNetPrice" onChange={(event) => handleChange(event, ele, index)} value={ele?.customNetPrice} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition)}>{handleDecimal(ele?.netPrice, 0)}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapNetPrice(ele, index, 'workbookArabica')} />
                              </div>
                            </td>

                            <td data-title={t('OUT_RATE')} className="custom-netPrice">
                              <div>
                                {ele?.isOutRateValueCustom ? <input type="number" className="input_field custom-input" name="customOutRate" onChange={(event) => handleChange(event, ele, index)} value={ele?.customOutRate} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition, ele)}>{ele?.convertTo}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapOutRate(ele, index, 'workbookArabica')} />
                              </div>
                            </td>
                            <td data-title={t('LAST')} className="custom-lastChg">
                              <div>
                                {ele?.isLastValueCustom ? <input type="number" className="input_field custom-input" name="customLastChg" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customLastChg} placeholder="0" autoComplete="off" /> : <span className={getWorkbookInputFieldTextColor(ele?.chngColorCondition)}>{ele?.lastChng}</span>}
                                <i className="pi pi-sort-alt sort-alt-icon" onClick={() => swapLastChg(ele, index, 'workbookArabica')} />
                              </div>
                            </td>

                            <td data-title="Differential">
                              <div className="input_div">
                                {/* here ele?.workbookDifferential is string */}
                                {ele?.isNetPriceValueCustom || ele.isOutRateValueCustom ? <span className="bold_and_dark_gray">{ele.customWorkbookDifferential}</span> : <input type="number" className="input_field" name="workbookDifferential" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.workbookDifferential != 0 && ele?.workbookDifferential} placeholder="0" />}
                              </div>
                            </td>
                            <td data-title="Total">{ele?.workbookTotal}</td>
                            <td data-title="Conv.to $">{ele?.convertTo}</td>
                            <td data-title="FX Currency" className="fxCurrency fx_currency_title">
                              {handleDecimal(ele?.fxcurrency, 2)}
                            </td>
                            <td data-title="FX-Pre/Dis">
                              <div className="input_div">
                                {/* here ele?.fxpredis is string */}
                                <input type="number" className="input_field" name="fxpredis" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.fxpredis != 0 && ele?.fxpredis} placeholder="0" />
                              </div>
                            </td>
                            <td data-title="FX Net">{ele?.fxnet}</td>
                            <td data-title="Gross Price">{ele?.grossPrice}</td>
                            <td data-title="Profit">
                              <div className="input_div">
                                {/* here ele?.profit is string */}
                                <input type="number" className="input_field" name="profit" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.profit != 0 && ele?.profit} placeholder="0" />
                              </div>
                            </td>
                            <td data-title="Net Price">
                              <span>{ele?.netPrice}</span>
                            </td>
                            <td>{/* This td should be empty */}</td>
                            <td>
                              <input className="delete-checkbox" type="checkbox" onChange={() => handleSelectWorkbookCheckBox(ele, index)} checked={ele?.isChecked} />
                            </td>
                            <td>
                              <DragIcon />
                            </td>
                          </>
                        )}
                        {ele?.workbookType === 0 && (
                          <>
                            <td className="workbookDividerTd" colSpan={14}>
                              <input className="dividerInput" placeholder="Divider name" value={ele?.contractName} onChange={(event) => handleChangeForDivider(event, ele, index)} />
                            </td>
                            <td className="workbookDividerTd"></td>
                            <td className="workbookDividerTd">
                              <input className="delete-checkbox" type="checkbox" onChange={() => selectAllCheckBoxForDivider(ele, index)} checked={ele?.isChecked ? true : false} />
                            </td>
                            <td className="workbookDividerTd">
                              <DragIcon />
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <Forex />
          )}
        </div>
        {!isWorkBookAvailable && !(isPlatinumUser && isIndianUser) && isWorkBookTabActive && (
          <div className="create-account-info-card">
            <CreateAccountInfoModal buttonText={t(`${buttonText}`)} infoMessage={t(`${infoMessage}`)} className={className} navigationPath={navigationPath} state={{ subscriptionId: 3 }} />
          </div>
        )}

        {!isWorkBookTabActive && (
          <div className="create-account-info-card">
            <CreateAccountInfoModal buttonText={t(`COMING_SOON`)} infoMessage={t('PLATINUM_SUBSCRIPTION_UPDATE')} className={className} />
          </div>
        )}
      </div>
      <div>
        <Dialog visible={showModal} closable={false} header={headerContent} footer={footerContent} className="user-guide-dialog">
          <div className="user-guide-dialog-container" data-testid="user-guide-dialog-container">
            <div className="logo-container" data-testid="logo-container">
              <img data-testid="coffeeWeb-logo" src={coffeewebLogo.coloredLogo} alt="coffeeweb-logo" className="logo-image" />
            </div>
            <div className="coming-soon-div">
              <p className="coming-soon-text">Coming Soon...</p>
            </div>{' '}
            <div className="description-div">
              <div className="description-text" data-testid="dialog-modal-description">
                {t('PLATINUM_SUBSCRIPTION_UPDATE')}
              </div>
            </div>{' '}
          </div>
        </Dialog>
      </div>
    </div>
  )
})

export default WorkBook
