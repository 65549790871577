import { useContext, useEffect } from 'react'
import './styles.scss'
import { useLocation } from 'react-router-dom'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { updateUserDetails, getLocalUserDetails, getLocalSubscriptionNewsId, getStripeDataFromLocal, getPayPalDataFromLocal, setLocalWithSubscriptionNewsId, setLocalWithPayPalData, setLocalWithStripeData } from 'Utils/LocalStorage_Handler'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useTranslation } from 'react-i18next'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

function PaymentStatus({ showError, showSuccess, testPaymentStatusFlag, testLocationFlag }) {
  const location = useLocation()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const history = useHistory()
  const userDetails = getLocalUserDetails()
  const newsId = getLocalSubscriptionNewsId()
  const stripeData = getStripeDataFromLocal()
  const payPalData = getPayPalDataFromLocal()
  const { sentryLog } = commonMethods
  const { setIsCustomLoading } = useContext(CoffeeWebContext)
  let gstDetails

  useEffect(() => {
    getGstDetails()

    if (testPaymentStatusFlag) {
      paymentStatusUpdate()
    }
  }, [])

  const paymentStatusUpdate = (gstDetails) => {
    if (location.pathname === ROUTE_STRINGS.success || (testPaymentStatusFlag && testLocationFlag)) {
      if (stripeData) {
        stripeOrderUpdate(gstDetails)
      } else if (payPalData) {
        captureOrderPaypal(gstDetails)
      } else {
        setIsCustomLoading('')
        showSuccess({
          title: t('SUCCESS'),
          message: `<p>${t('THANK_YOU')}</p><p>${t('PAYMENT_SUCCESS')}</p>`,
          buttonLabel: t('OK'),
          onConfirm: () => {
            backButtonFromSuccessFullPage()
          },
          onCancel: () => {
            backButtonFromSuccessFullPage()
          }
        })
      }
    } else {
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('PAYMENT_CANCELED_MESSAGE')}</p><p>${t('CONTACT_SUPPORT_TEAM')}</p>`,
        buttonLabel: t('OK'),
        onConfirm: () => {
          backButtonFromErrorPage()
        },
        onCancel: () => {
          backButtonFromErrorPage()
        }
      })
      resetLocalStorage()
    }
  }

  const getGstDetails = async () => {
    setIsCustomLoading('WE_ARE_UPGRADING_KINDLY_WAIT')
    try {
      const response = await apiAdapterCoffeeWeb.getUserGSTDetailByList(userDetails.id)

      if (response.data?.statusCode === 201) {
        const userActiveData = response.data?.returnLst?.filter((item) => item.lastUsed === true)

        gstDetails = {
          organistationAddress: userActiveData[0]?.organistationAddress,
          organistationName: userActiveData[0]?.organistationName,
          userName: userActiveData[0]?.userName === '' ? userDetails.firstName : userActiveData[0]?.userName,
          email: userActiveData[0]?.email === '' ? userDetails.email : userActiveData[0]?.email
        }

        paymentStatusUpdate(gstDetails)
      } else {
        paymentStatusUpdate()
      }
    } catch (error) {
      paymentStatusUpdate()
    }
  }

  // Stripe Order Update
  const stripeOrderUpdate = async (gstDetails) => {
    setIsCustomLoading('WE_ARE_UPGRADING_KINDLY_WAIT')
    const getOrDefault = (value, defaultValue) => value || defaultValue
    const { orderId: idOrder, paymentIntent, amount, subName: subscriptionId, paymentId, paymentSource } = stripeData

    const updateOrder = {
      updateOrderDTO: {
        idOrder,
        payOrderId: paymentIntent,
        subscriptionId,
        facilitatorAccessToken: paymentId,
        paymentSource,
        paymentStatus: 'Success',
        orderSatus: 2
      },
      userGSTDetails: {
        idUserGstdetails: 0,
        userId: 0,
        organistationName: getOrDefault(gstDetails?.organistationName || 'NA'),
        organistationAddress: getOrDefault(gstDetails?.organistationAddress || 'NA'),
        stateId: 0,
        gstno: 'NA',
        entryDate: '2023-05-25T12:19:02.839Z',
        lastUsed: false,
        lastUsedDtm: '2023-05-25T12:19:02.839Z',
        userName: getOrDefault(gstDetails?.userName || 'NA'),
        email: getOrDefault(gstDetails?.email || 'NA')
      }
    }

    try {
      const response = await apiAdapterCoffeeWeb.UpdateOrder(updateOrder)

      if (response.data.statusCode === 201) {
        await orderUpdateSuccess('payment')
      }
    } catch (error) {
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p><p>${t('PAYMENT_DEDUCTED_MESSAGE')}</p>`,
        buttonLabel: t('OK'),
        onConfirm: () => {
          backButtonFromErrorPage()
        },
        onCancel: () => {
          backButtonFromErrorPage()
        }
      })
      sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, paymentSource, subscriptionId, idOrder, paymentId, errorIn: 'Stripe Order Update Failed', description: 'Payment Failed in Stripe' })
    } finally {
      setIsCustomLoading('')
    }
  }

  // Validate Paypal Order
  const captureOrderPaypal = async (gstDetails) => {
    setIsCustomLoading('WE_ARE_UPGRADING_KINDLY_WAIT')
    const { orderId: idOrder, paymentSource, subName: subscriptionId, amount } = payPalData

    try {
      const response = await apiAdapterCoffeeWeb.captureOrder({
        paymentId: payPalData.paymentId,
        uuid: payPalData.uuid
      })

      if (response.data.returnLst?.id === null) {
        setIsCustomLoading('')
        setLocalWithPayPalData(null)

        sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, paymentSource, subscriptionId, idOrder, paymentId: null, errorIn: 'Paypal', description: 'Payment Failed in Paypal' })
        showError({
          title: t('ERROR_TITLE'),
          message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p><p>${t('PAYMENT_DEDUCTED_MESSAGE')}</p>`,
          buttonLabel: t('OK'),
          onConfirm: () => {
            backButtonFromErrorPage()
          },
          onCancel: () => {
            backButtonFromErrorPage()
          }
        })
      } else {
        await orderUpdatePayPal(response.data.returnLst?.id, gstDetails)
      }
    } catch (error) {
      setIsCustomLoading('')
      sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, paymentSource, subscriptionId, idOrder, paymentId: null, errorIn: 'Paypal Capture Order Failed', description: 'Failed in capture order API PayPal' })
    }
  }

  // PayPal Order Update
  const orderUpdatePayPal = async (payOrderId, gstDetails) => {
    const { orderId: idOrder, paymentSource, subName: subscriptionId, facilitatorAccessToken, amount } = payPalData

    const getOrDefault = (value, defaultValue) => value || defaultValue
    const updateOrderDTO = {
      idOrder,
      payOrderId,
      subscriptionId,
      facilitatorAccessToken,
      paymentSource,
      paymentStatus: 'Success',
      orderSatus: 2
    }

    const userGSTDetails = {
      idUserGstdetails: 0,
      userId: 0,
      organistationName: getOrDefault(gstDetails?.organistationName || 'NA'),
      organistationAddress: getOrDefault(gstDetails?.organistationAddress || 'NA'),
      stateId: 0,
      gstno: 'NA',
      entryDate: '2023-05-25T12:19:02.839Z',
      lastUsed: false,
      lastUsedDtm: '2023-05-25T12:19:02.839Z',
      userName: getOrDefault(gstDetails?.userName || 'NA'),
      email: getOrDefault(gstDetails?.email || 'NA')
    }

    setIsCustomLoading('WE_ARE_UPGRADING_KINDLY_WAIT')

    try {
      const response = await apiAdapterCoffeeWeb.UpdateOrder({
        updateOrderDTO,
        userGSTDetails
      })

      if (response.data.statusCode === 201) {
        await orderUpdateSuccess('paypalDetails')
      }
    } catch {
      setIsCustomLoading('')
      sentryLog({ userId: userDetails?.email || userDetails?.phone, screenName: 'Payment', amount, paymentSource, subscriptionId, idOrder, paymentId: payOrderId, errorIn: 'PayPal order update failed', description: 'Failed in update order API PayPal' })
    }
  }

  const orderUpdateSuccess = async (keyName) => {
    try {
      await updateUserDetails()

      // coffeewebSetLocal(keyName === 'paypalDetails' ? coffeewebStorageKeys.paypalDetails : coffeewebStorageKeys.payment, null)
      keyName === 'paypalDetails' ? setLocalWithPayPalData(null) : setLocalWithStripeData(null)
      showSuccess({
        title: t('SUCCESS'),
        message: `<p>${t('THANK_YOU')}</p><p>${t('PAYMENT_SUCCESS')}</p>`,
        buttonLabel: t('OK'),
        onConfirm: () => {
          backButtonFromSuccessFullPage()
        },
        onCancel: () => {
          backButtonFromSuccessFullPage()
        }
      })
    } finally {
      setIsCustomLoading('')
    }
  }

  const resetLocalStorage = () => {
    setLocalWithPayPalData(null)
    setLocalWithStripeData(null)
  }

  const backButtonFromSuccessFullPage = () => {
    if (newsId) {
      params.set('newsId', newsId)
      history.push({ pathname: ROUTE_STRINGS.coffeequotes, search: params.toString() })
      setLocalWithSubscriptionNewsId(null)
    } else {
      history.push({ pathname: `/dashboard/${userDetails?.userLandingPageUrl}` })
    }
  }

  const backButtonFromErrorPage = () => {
    history.push({ pathname: ROUTE_STRINGS.subscription })
  }
}

export default ErrorMessageModal(PaymentStatus)
