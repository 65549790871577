import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { useHistory } from 'react-router-dom'
import { setDoc, doc } from 'firebase/firestore'
import { db } from 'firebase.js'
import { useTranslation } from 'react-i18next'
import { coffeewebLogo, ROUTE_STRINGS } from 'Utils/Constants'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import Footer from 'Components/LayoutComponents/Footer'
import { getLocalSingleDeviceId, getLocalFCMToken, getLocalShareNewsId, getLocalNewsForSubscription, setLocalWithSingleDeviceId, setLocalWithUserDetails, setLocalWithAuthToken, setLocalWithLanguageIdForNews, setLocalWithLoggedInStatus, setLocalWithAppSettings } from 'Utils/LocalStorage_Handler'
import MetaTagsComponent from 'Components/MetaTagsComponent'
import { AppleIcon, devEnvLogo, GooglePlay } from '../../Assets/Icons'
import SignUpComponent from './Components/SignupComponent'
import LoginComponent from './Components/LoginComponent'
import apiAdapterCoffeeWeb from '../../Services/apiAdapter_CoffeeWeb'

const LoginScreen = ({ handleApiResponse, showError }) => {
  const { t } = useTranslation()
  const { generateUniqueId, sentryLog } = commonMethods
  const { setLoading } = useContext(CoffeeWebContext)
  const history = useHistory()
  const params = new URLSearchParams()

  const pageUrl = window.location.pathname
  const splitPageUrl = pageUrl.split('/')
  const currentUrl = splitPageUrl.pop()

  const singleDeviceId = getLocalSingleDeviceId()
  const firebaseFcmToken = getLocalFCMToken()
  const [activeTab, setActiveTab] = useState(currentUrl)
  const [activeTabIndex, setActiveTabIndex] = useState(currentUrl === 'login' ? 0 : 1)
  const [appSettingsState, setAppSettingsState] = useState({})
  const [newsDataOnClick, setNewsDataOnClick] = useState({ encryptedId: '', newsForSubscription: '', newsId: 0, forGuestUser: '' })
  const sharedNewsId = history.location.state?.encryptedId || getLocalShareNewsId()
  const newsForSubscription = history.location.state?.newsForSubscription || getLocalNewsForSubscription()
  const newsId = history.location.state?.newsId
  const forGuestUser = history.location.state?.forGuestUser

  useEffect(() => {
    getApiSettings()
    if (!singleDeviceId) {
      setLocalWithSingleDeviceId(generateUniqueId())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApiSettings = async () => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.appSetting()

      if (response?.data?.id) {
        setAppSettingsState(response?.data)
        setLocalWithAppSettings(response?.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleTabSelection = (tab) => {
    const { index } = tab

    if (index === 0 || tab === 'login') {
      setActiveTab('login')
      setActiveTabIndex(0)
    } else if (index === 1 || tab === 'create_account') {
      setActiveTab('create_account')
      setActiveTabIndex(1)
    }

    const redirectParams = index === 1 || tab === 'create_account' ? { pathname: ROUTE_STRINGS.createAccount, state: newsDataOnClick } : { pathname: ROUTE_STRINGS.login, state: newsDataOnClick }

    history.replace(redirectParams)
  }

  const addUserInFirebase = async (googleAuthToken, email, userChatId, displayNameForChat, emailForChat) => {
    const setUser = setDoc(doc(db, 'users', userChatId), {
      uid: userChatId,
      displayNameForChat,
      emailForChat
    })
    const setUserChats = setDoc(doc(db, 'userChats', userChatId), {})

    try {
      await Promise.all([setUser, setUserChats])
      authenticateForDashboard(googleAuthToken, email)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const authenticateForDashboard = async (token, email) => {
    setLoading(true)

    const postData = {
      username: email || 'NA',
      password: 'NA',
      includeMenu: true,
      isMobile: false,
      deviceId: singleDeviceId,
      fbToken: firebaseFcmToken || 'NA',
      isAndroidDevice: false,
      byPassOldDevice: true,
      googleToken: token || 'NA'
    }

    try {
      const response = await apiAdapterCoffeeWeb.authenticateWithFB(postData)

      if (response.status === 200 && response?.data?.token) {
        const {
          token,
          languageId,
          sub: { subType }
        } = response.data

        setLocalWithUserDetails(response.data)
        setLocalWithAuthToken(token)
        setLocalWithLanguageIdForNews(languageId)
        setLocalWithLoggedInStatus(true)

        setLoading(false)

        const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
        const isUserSubscribed = newsSubscriptionType?.includes(subType.toString())

        if (newsId && forGuestUser) {
          if (isUserSubscribed) {
            params.set('newsId', newsId)
            history.push({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
          } else {
            history.push(ROUTE_STRINGS.subscription)
          }
        } else if (sharedNewsId) {
          if (isUserSubscribed) {
            history.push({ pathname: ROUTE_STRINGS.opensharednews, state: { encryptedId: sharedNewsId } })
          } else {
            history.push(ROUTE_STRINGS.subscription)
          }
        } else if (response.data?.userLandingPageUrl) {
          history.push(`${ROUTE_STRINGS.dashboard}/${response?.data?.userLandingPageUrl}`)
        } else {
          history.push(ROUTE_STRINGS.coffeeNewsFeeds)
        }
      } else {
        handleApiResponse({ status: null, message: t('SORRY_UNABLE_TO_LOGIN'), onRetry: () => authenticateForDashboard() })
        sentryLog({ userId: response?.data?.email || response?.data?.phone, screenName: activeTab, description: 'Null Token received', errorIn: 'AuthenticatewithFB API' })
      }
    } catch (error) {
      setLoading(false)
      sentryLog({ userId: email, screenName: activeTab, description: `After create user API with status ${error?.response?.status}`, errorIn: 'AuthenticatewithFB API' })
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="login-screen authScreen-wrapper">
        <MetaTagsComponent title="" description="" keywords="" url="" h1Content="" />
        <div className="container authScreen-container">
          <div className="row authScreen-main-container">
            <div className="col-md-6 m-auto d-none d-md-block authScreen-logo-container">
              <img src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeewebLogo.transparentLogo : devEnvLogo} alt="coffee-web-logo" className="w-100" />
            </div>
            <div className="col-md-6 mx-auto p-0 authScreen-form-container">
              <div className="login-card card authScreen-login-sign-up">
                <div className="box authScreen-shadow-container">
                  <div className="login-card-container authScreen-login-sign-up-shadow">
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => handleTabSelection(e)} className="tab-view">
                      <TabPanel header="LOGIN">{activeTab === 'login' && <LoginComponent setNewsDataOnClick={setNewsDataOnClick} singleDeviceId={singleDeviceId} appSettingsState={appSettingsState} handleTabSelection={handleTabSelection} addUserInFirebase={addUserInFirebase} />}</TabPanel>
                      <TabPanel header="CREATE ACCOUNT">
                        <div className="otp-screen">{activeTab === 'create_account' && <SignUpComponent setNewsDataOnClick={setNewsDataOnClick} singleDeviceId={singleDeviceId} handleTabSelection={handleTabSelection} addUserInFirebase={addUserInFirebase} authenticateForDashboard={authenticateForDashboard} handleApiResponse={handleApiResponse} />}</div>
                      </TabPanel>
                    </TabView>
                    <div className="social-buttons-container">
                      <div className="social-buttons">
                        <div className="flex">
                          <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                            <AppleIcon fill={'#fff'} className="social-images" />
                            <p>
                              {t('DOWNLOAD_ON_THE')}
                              <br /> <span className="big-txt">{t('APP_STORE')}</span>
                            </p>
                          </a>
                          <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                            <img alt="GooglePlay" src={GooglePlay} className="social-images" />
                            <p>
                              {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ErrorMessageModal(LoginScreen)
