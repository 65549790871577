import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { popupIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

export default function Columbia({ swapReport, setLoading, mockColumbiaData, mockColumbiaModal, mockModalType, createMonthRange, checkForUserAccess }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const { columbiaCountryWiseExportsReport } = coffeeReportJsonLinks
  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }
  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    fetchData()

    if (mockColumbiaData) {
      setFetchedData(mockColumbiaData)
      setShowModal(mockColumbiaModal)
      setModalType(mockModalType)
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)

    const reportName = columbiaCountryWiseExportsReport

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      EXPORTS: 0
    }

    countryCertifiedData.forEach(({ EXPORTS }) => {
      totals.EXPORTS += Number(EXPORTS) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const valueForBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }

    return valueInMT || ''
  }

  const convertToMillion = (value) => {
    if (value === '' || Number.isNaN(Number(value))) {
      return ''
    }

    const result = value / 60000

    return result.toFixed(4)
  }

  const displayValue = (value) => {
    if (swapReport === 'Metric Tons') {
      return valueForBags(Math.round(value)).toLocaleString()
    }

    if (swapReport === '60 KG Bags') {
      return convertMTToBags(value).toLocaleString()
    }

    if (swapReport === 'Millions Bags') {
      return convertToMillion(value)
    }

    return valueForBags(value).toLocaleString()
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      EXPORTS: 0,
      MONTH: ''
    }

    data.forEach(({ EXPORTS, MONTH }) => {
      totals.EXPORTS += Number(EXPORTS) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')
    setShowModal(true)

    if (currentYearData?.EXPORTS !== '' && previousYearData?.EXPORTS !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }

    if (currentYearData.EXPORTS && previousYearData.EXPORTS) {
      setShowModal(true)
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.EXPORTS)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const difference = {
      export: Number(currentData.EXPORTS) - Number(previousData.EXPORTS) || 0
    }

    const percentageDifference = {
      export: previousData.EXPORTS ? ((difference.export / Number(previousData.EXPORTS)) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `Upto ${currentMonthYear} To ${previousMonthYear} Comparison` : `${currentMonthYear} To ${previousMonthYear} Comparison`

    return (
      <div className="comparison-table" data-testid="columbia-comparison-dialog-modal">
        <div className="content-title" data-testid="comparison-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th>{t('MONTH')}</th>
              <th>{t('EXPORTS')}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="empty-table-row"></tr>
            <tr>
              <td data-testid="current-data-month"> {modalType === 'total' ? createMonthRange(currentData.MONTH) : currentData.MONTH}</td>
              <td data-testid="current-data-exports">{displayValue(currentData.EXPORTS)}</td>
            </tr>
            <tr>
              {modalType === 'total' ? <td data-testid="previous-data-month">{createMonthRange(previousData ? previousData.MONTH : '')}</td> : <td data-testid="previous-data-month">{previousData ? previousData.MONTH : ''}</td>}
              <td data-testid="previous-data-exports">{displayValue(previousData.EXPORTS)}</td>
            </tr>
            <tr className="empty-table-row"></tr>
            <tr className="difference-row" data-testid="difference-row-container">
              <td className="table-border">{t('DIFFERENCE')}</td>
              <td className={`${getTextColor(difference.export)} table-border`} data-testid="difference-row-value">
                {displayValue(difference.export)}
              </td>
            </tr>
            <tr className="percentage-row" data-testid="percentage-row-container">
              <td className="table-border">{t('PERCENTAGE_WISE')}</td>
              <td className={`${getTextColor(percentageDifference.export)} table-border`} data-testid="percentage-row-value">
                {percentageDifference.export !== 'N/A' ? `${percentageDifference.export}%` : 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report" data-testid="columbia-country-wise-data-container">
      {fetchedData?.map(({ report, year }, index) => {
        const { EXPORTS } = calculateTotals(report)

        return (
          <div key={index} className="table-wrapper" data-testid="columbia-table-wrapper">
            <table>
              <thead>
                <tr className="year-row" data-testid="columbia-year-row">
                  <td colSpan={6}>
                    <div>{year}</div>
                  </td>
                </tr>
                <tr>
                  <th className="month">{t('MONTH')}</th>
                  <th>EXPORTS</th>
                </tr>
                <tr className="table-row-empty"></tr>
              </thead>
              <tbody style={checkForUserAccess(menuLock)}>
                {report.map(({ MONTH, EXPORTS }, index) => (
                  <tr key={index}>
                    <td>
                      <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showMonthValueModal(index, year)} data-testid="popup-wrapper-container">
                        <div data-testid="month-container">{MONTH}</div>
                        <div className="icon-wrapper">
                          <img src={popupIcon} alt={MONTH} className="popup-icon" title="popup-icon-image" />
                        </div>
                      </div>
                    </td>
                    <td data-testid="exports-container">{displayValue(EXPORTS)}</td>
                  </tr>
                ))}

                <tr className="no-data-row"></tr>

                <tr className="tr-color" data-testid="columbia-total-row">
                  <td>
                    <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showTotalValueModal(year)} data-testid="total-popup-wrapper">
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" title="total-popup-icon-image" />
                      </div>
                    </div>
                  </td>
                  <td data-testid="total-exports-container">{displayValue(EXPORTS)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" testId="columbia-country" />
    </div>
  )
}
