import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import Header from 'Components/LayoutComponents/Header'
import { GooglePlay, AppleIcon } from 'Assets/Icons'
import { STATIC_ROUTE_STRINGS, ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalUserDetails, getLocalAuthToken, setLocalWithSharedNewsId, setLocalWithNewsForSubscription } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import NewsReadMore from 'Screens/CoffeeNewsFeeds/Components/NewsReadMore'
import { commonMethods } from 'Utils/commonMethods'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const NewsFeedLink = ({ ...props }) => {
  // Props for Testing
  const { mockHiddenNews, mockData, renderNewsReadMore = true } = props

  const { id } = useParams()
  const history = useHistory()
  const params = new URLSearchParams()
  const location = useLocation()
  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [coffeeNewsDataForImage, setCoffeeNewsDataForImage] = useState([])
  const [hideNewsFeed, setHideNewsFeed] = useState(false)

  const { dateFormatBasedOnUser } = commonMethods

  useEffect(() => {
    handleSharedNewsLink()
    if (!userDetails) setLocalWithSharedNewsId(null)
    redirectToMobileApp()
    setHideNewsFeed(mockHiddenNews)
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    setCoffeeNewsData(mockData)
  }, [])

  useEffect(() => {
    if (!location.search) {
      commonMethods.setBodyOverflowToAuto()
    }
  }, [location.search])
  function redirectToMobileApp() {
    let originalUrl = window.location.href
    const removedPart = '/share'

    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      if (/SamsungBrowser/i.test(userAgent)) {
        window.location.href = originalUrl.replace(removedPart, '')
      } else if (/Opera/i.test(userAgent)) {
        window.location.href = originalUrl.replace(removedPart, '')
      } else {
        window.location.href = originalUrl.replace(removedPart, '')
      }
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      if (originalUrl.includes('www')) {
        originalUrl = originalUrl.replace('www.', '')
      }

      window.location.href = originalUrl.replace(removedPart, '')
    }
  }

  const handleSharedNewsLink = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getNewsByEncryptedId(id)
      const newsData = response.data.returnLst

      setLocalWithNewsForSubscription(newsData[0]?.newsForSubscription)
      if (newsData.length > 0) {
        setCoffeeNewsData(newsData)
        setCoffeeNewsDataForImage(newsData[0].nwsFeedMedia[0]?.pathOfMedia || '')
      }
      setLoading(false)
    } catch (err) {
      // console.log('Error fetching shared news:', err)
      setLoading(false)
    }
  }

  const handleSharedNewsReadMore = ({ id: newsId, newsForSubscription }) => {
    const isFullFreeNews = coffeeNewsData.length > 0 && coffeeNewsData[0].showFullfreeNews

    if (isFullFreeNews && !userDetails && !accessToken) {
      params.set('share', true)
      params.set('newsId', newsId)
      history.push({ pathname: ROUTE_STRINGS.guestNewsFeed, search: params.toString() })
    } else if (userDetails && accessToken) {
      const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
      const isUserSubscribed = newsSubscriptionType.includes(userDetails?.sub?.subType.toString())

      if (isUserSubscribed) {
        setLocalWithSharedNewsId(id)
        history.push(ROUTE_STRINGS.opensharednews)
      } else {
        history.push(ROUTE_STRINGS.subscription)
      }
    } else {
      setHideNewsFeed(true)
      setLocalWithSharedNewsId(id)
    }
  }

  return (
    <>
      <Header />
      <div>
        <div className="coffee_news_feeds_forShare content-wrapper restrictEverything">
          {!hideNewsFeed ? (
            <div className="feeds_container" data-testid="news-container">
              <div className="news_feeds_card" data-testid="news-feed-card">
                <div className=" outer-card">
                  <div>
                    {coffeeNewsData?.length > 0 && (
                      <div className="paginated">
                        {coffeeNewsData?.map((item, index) => (
                          <>
                            <div className="design" key={index} data-testid="full-news-feed-design">
                              <div className="subSecForImage" data-testid="image-section">
                                <div className="forMobileDeviceIndetail" style={{ padding: '4px 9px 2px' }} data-testid="mobile-ui-container">
                                  <div className="calendar-icon-container" data-testid="calendar-icon-container">
                                    <i className="pi pi-calendar icon-container calendar-icon" data-testid="calendar-icon" />
                                    {dateFormatBasedOnUser(item?.newsDate, 'spaceFormat')}
                                  </div>
                                  <div className="time-icon-container" data-testid="time-icon-container">
                                    <i className="pi pi-clock icon-container time-icon" data-testid="time-icon" />
                                    {moment(item?.saApproveDtm).format(' hh:mm a')}
                                  </div>
                                </div>

                                {item.nwsFeedMedia.length > 0 ? (
                                  <div className="news_feeds_div" data-testid="image-container">
                                    <img alt="" src={coffeeNewsDataForImage} className="news_feeds_img" />
                                  </div>
                                ) : (
                                  <div className="news_feeds_div">
                                    <img alt="" src={item?.coffeeNewsDefaultImage} className="news_feeds_img_logo" />
                                  </div>
                                )}
                                <div className="forMobileDeviceIndetail" style={{ padding: '4px 9px 2px' }}>
                                  <div className="user-account-icon-container">
                                    <div className="user-account-icon">
                                      <i className="pi pi-user account-icon" />
                                    </div>

                                    <span className="author-text">{item?.author}</span>
                                  </div>

                                  <div className="visibility-icon-container">
                                    <div className="view-icon-container">
                                      <i className="pi pi-eye icon-container visibility-icon" /> {item?.views}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                              <div className="subSecForNews">
                                <div>
                                  <div className="d-flex justify-content-between ">
                                    <div className="newsHeading" onClick={() => handleSharedNewsReadMore(item)} data-testid="news-heading">
                                      {ReactHtmlParser(item.subject)}
                                    </div>
                                  </div>

                                  <div className="newsHtml" data-testid="share-text">
                                    {ReactHtmlParser(item.shareText)}
                                  </div>
                                </div>
                                <div className="newsDetails" data-testid="news-details">
                                  <div className="details">
                                    <div className="calendar-icon-container" data-testid="web-calendar-icon-container">
                                      <i className="pi pi-calendar icon-container calendar-icon" data-testid="web-calendar-icon" />
                                      {dateFormatBasedOnUser(item?.newsDate, 'spaceFormat')}
                                      {/* {moment(item?.newsDate).format('D MMM YYYY')} */}
                                    </div>
                                    <div className="time-icon-container" data-testid="web-time-icon-container">
                                      <i className="pi pi-clock icon-container time-icon" data-testid="web-time-icon" />
                                      {moment(item?.saApproveDtm).format(' hh:mm a').toUpperCase()}
                                    </div>
                                    <div className="visibility-icon-container" data-testid="views-icon-container">
                                      <i className="pi pi-eye icon-container" data-testid="views-icon" />
                                      {item?.views}
                                    </div>
                                    <div className="user-account-icon-container" data-testid="author-icon-container">
                                      <i className="pi pi-user  account-icon" data-testid="author-icon" />

                                      {item?.author?.substr(0, 15)}
                                      {item?.author?.length > 15 && <span>...</span>}
                                    </div>
                                  </div>
                                  <div className="read_more_button_div">
                                    <div>
                                      <Button buttonType={'CUSTOM'} buttonSize={'x-small'} buttonLabel={t('READ_MORE')} buttonWidth={'full'} onButtonClick={() => handleSharedNewsReadMore(item)} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="fullstop_style">.</div>
          )}
          {!hideNewsFeed && (
            <div className="social_btns" data-testid="social-button-container">
              <div className="flex">
                <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer" data-testid="apple-icon">
                  <AppleIcon fill={'#fff'} className="social_imgs" />
                  <p>
                    {t('DOWNLOAD_ON_THE')}
                    <br /> <span className="big-txt">{t('APP_STORE')}</span>
                  </p>
                </a>
                <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer" data-testid="google-play-icon">
                  <img alt="" src={GooglePlay} className="social_imgs" data-testid="google-play-image" />
                  <p>
                    {t('GET_IT_ON')} <br /> <span className="big-txt">{t('Google Play')}</span>
                  </p>
                </a>
              </div>
              <div className="flex">
                <a className="app-btn blu flex vert coffee_web_link_div" href={STATIC_ROUTE_STRINGS.home}>
                  <div className="globe-icon-container">
                    <i className="pi pi-globe coffee-news-link-globe-icon" data-testid="globe-icon" />
                  </div>

                  <p>
                    <span className="coffee_news_link">www.coffeeweb.com</span>
                  </p>
                </a>
              </div>
            </div>
          )}
          {hideNewsFeed && (
            <div className="social_btns">
              <div className="flex flex_div">
                <div className="social_medial_links_div">{t('DOWNLOAD_ON_PLAY_STORE')}</div>
                <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                  <img alt="" src={GooglePlay} className="social_media_google_play_img" />
                  <p>
                    {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                  </p>
                </a>
                <div className="social_medial_links_div">{t('DOWNLOAD_ON_APP_STORE')}</div>
                <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                  <AppleIcon fill={'#fff'} className="social_media_apple_img" />
                  <p>
                    {t('DOWNLOAD_ON_THE')}
                    <br /> <span className="big-txt">{t('APP_STORE')}</span>
                  </p>
                </a>
              </div>
              <div className="website_link">
                <div className="social_medial_links_div">{t('VISIT_OUR_WEBSITE')}</div>
                <a className="app-btn blu flex vert coffee_web_link_div" href={STATIC_ROUTE_STRINGS.home}>
                  <i className="pi pi-globe coffee-news-link-globe-icon" />
                  <p>
                    <span className="coffee_news_link">www.coffeeweb.com</span>
                  </p>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {renderNewsReadMore && location.search && <NewsReadMore />}
    </>
  )
}

export default NewsFeedLink
