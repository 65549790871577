import './styles.scss'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PickList } from 'primereact/picklist'
import { Dialog } from 'primereact/dialog'
import { SelectButton } from 'primereact/selectbutton'
import { Divider } from 'primereact/divider'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { getLocalClockDisplay, getLocalTimeFormat, getLocalUserDetails, setLocalWithClockTab, setLocalWithClockType, setLocalWithTimeFormat } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const ClockSettings = ({ visible, setPage, setVisible, setDisplayTab, pickListSourceData, setPickListSourceData, setFavoriteCountryClock, setClockTab, pickListTargetData, setPickListTargetData, showError }) => {
  const { t } = useTranslation()
  const userDetails = getLocalUserDetails()
  const { setLoading } = useContext(CoffeeWebContext)

  const clockType = ['Analog', 'Digital']
  const timeFormat = ['12-hour', '24-hour']

  const [selectedClockType, setSelectedClockType] = useState(() => getLocalClockDisplay('selectedClockDisplay') || 'Analog')
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(() => getLocalTimeFormat('selectedTimeFormat') || '12-hour')
  const [currentPickListTargetData, setCurrentPickListTargetData] = useState(pickListTargetData)
  const [currentPickListSourceData, setCurrentPickListSourceData] = useState(pickListSourceData)
  const [filteredSourceData, setFilteredSourceData] = useState(pickListSourceData)
  const [filteredTargetData, setFilteredTargetData] = useState(pickListTargetData)
  const [picklistMovementType, setPicklistMovementType] = useState(null)

  useEffect(() => {
    if (pickListSourceData.length === 0) {
      setFilteredSourceData([{ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') }])
    } else {
      setFilteredSourceData(pickListSourceData)
    }
  }, [pickListSourceData])

  useEffect(() => {
    if (pickListTargetData.length === 0) {
      setFilteredTargetData([{ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') }])
    } else {
      setFilteredTargetData(pickListTargetData)
    }
  }, [pickListTargetData])

  const selectedCountryId = pickListTargetData?.map((country) => country?.countryId)

  const postFavoriteCountry = async () => {
    setLoading(true)

    setCurrentPickListTargetData(pickListTargetData)
    setCurrentPickListSourceData(pickListSourceData)
    const { id } = userDetails

    const postData = {
      userId: id,
      favouriteCountries: selectedCountryId.toString()
    }

    try {
      const {
        data: { statusCode, returnLst }
      } = await apiAdapterCoffeeWeb.makeFavoriteCountryClock(postData)

      if (statusCode === 200 || statusCode === 201) {
        const tabVisible = returnLst.some((ele) => ele.isFavorite)
        const favoriteCountries = returnLst.filter((country) => country.isFavorite).sort((a, b) => a.favoriteIndex - b.favoriteIndex)
        const nonFavoriteCountries = returnLst.filter((country) => !country.isFavorite)
        const favoriteCountriesFilter = returnLst.filter((country) => country.isFavorite)

        if (tabVisible) {
          setClockTab('My Favourite')
          setLocalWithClockTab('My Favourite')
        } else {
          setClockTab('All Clock')
          setLocalWithClockTab('All Clock')
        }

        const displayButton = returnLst.some((ele) => ele.isFavorite)

        setDisplayTab(displayButton)
        setVisible(false)
        setFavoriteCountryClock(favoriteCountries)
        setPickListSourceData(nonFavoriteCountries)
        setPickListTargetData(favoriteCountriesFilter)

        setPage(0)
      }
    } catch (error) {
      setVisible(true)
      showError({
        title: t('ERROR_TITLE'),
        message: `<p>${t('SOMETHING_WENT_WRONG')}</p><p>${t('TRY_AGAIN_MESSAGE')}</p>`,
        buttonLabel: t('OK')
      })
    } finally {
      setLoading(false)
    }
  }

  const pickListItemTemplate = (item) => (
    <>
      {item && item.countryId !== 'noData' ? (
        <div className="country-picker-item">
          <div className="country-picker-content">
            <div className="country-picker-flag-container">
              <img className="country-picker-flag" src={`${item?.flagURL}`} alt={item?.countryName} />
            </div>
            <div className="country-picker-name-container">
              <span className="country-picker-name">{item?.countryName}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="country-picker-item no-select">
          <div className="country-picker-content">
            <div className="country-picker-flag-container">
              <span className="no-country-available">{item?.countryName}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )

  const onChange = (event) => {
    let source = event.source
    let target = event.target

    if (target.length === 0) {
      target.push({ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') })
    } else {
      target = target.filter((item) => item && item.countryId !== 'noData')
    }

    if (source.length === 0) {
      source.push({ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') })
    } else {
      source = source.filter((item) => item && item.countryId !== 'noData')
    }

    setTimeout(() => {
      setPicklistMovementType((prev) => {
        const targetedId = target.map((ele) => ele.countryId)
        const sourceId = source.map((ele) => ele.countryId)

        const sourceList = pickListSourceData.filter((ele) => !targetedId.includes(ele.countryId))
        const targetList = pickListTargetData.filter((ele) => !sourceId.includes(ele.countryId))

        if (prev === 0) {
          setPickListSourceData(sourceList)
          setPickListTargetData(target)
        } else if (prev === 1) {
          setPickListSourceData(source)
          setPickListTargetData(targetList)
        } else {
          setPickListSourceData(source)
          setPickListTargetData(target)
        }

        return null
      })
    }, 100)
    setFilteredSourceData(source)
    setFilteredTargetData(target)
  }

  const clearLists = (type) => {
    const inputs = document.querySelectorAll('.p-picklist-filter input.p-inputtext')

    inputs.forEach((input) => {
      const inputField = input

      inputField.value = null
    })

    setPicklistMovementType(type === 'target' ? 0 : 1)
  }

  const noCountryAvailable = filteredSourceData.length === 1 && filteredSourceData[0].countryId === 'noData'

  const targetHasData = filteredTargetData.length > 0
  const sourceHasData = filteredSourceData.length > 1
  const moveButtonsDisabled = (noCountryAvailable && !targetHasData) || (filteredTargetData.length === 1 && filteredTargetData[0].countryId === 'noData' && !sourceHasData)

  const ptProps = {
    moveAllToTargetButton: {
      root: { className: 'country-picking-button', disabled: noCountryAvailable || !sourceHasData, onClick: () => clearLists('target') }
    },
    moveAllToSourceButton: {
      root: { className: 'country-picking-button', disabled: moveButtonsDisabled || (filteredTargetData.length === 1 && filteredTargetData[0].countryId === 'noData'), onClick: () => clearLists('source') }
    },
    moveToTargetButton: {
      root: { className: 'country-picking-button', disabled: noCountryAvailable, onClick: () => clearLists('target') }
    },
    moveToSourceButton: {
      root: { className: 'country-picking-button', disabled: moveButtonsDisabled || (filteredTargetData.length === 1 && filteredTargetData[0].countryId === 'noData'), onClick: () => clearLists('source') }
    },
    moveUpButton: {
      root: { disabled: moveButtonsDisabled || filteredTargetData.length === 1 }
    },
    moveDownButton: {
      root: { disabled: moveButtonsDisabled || filteredTargetData.length === 1 }
    },
    moveTopButton: {
      root: { disabled: moveButtonsDisabled || filteredTargetData.length === 1 }
    },
    moveBottomButton: {
      root: { disabled: moveButtonsDisabled || filteredTargetData.length === 1 }
    }
  }

  const onClockTypeChange = (event) => {
    const selectedClockDisplay = event.target.value

    if (selectedClockDisplay === 'Analog') {
      setVisible(false)
      setLocalWithClockType(selectedClockDisplay)
    } else {
      setLocalWithClockType(selectedClockDisplay)
      setLocalWithTimeFormat('12-hour')
      setSelectedTimeFormat('12-hour')
    }
    setSelectedClockType(selectedClockDisplay)
  }

  const onClockFormatChange = (event) => {
    const clockTimeFormat = event.target.value

    setVisible(false)
    setSelectedTimeFormat(clockTimeFormat)
    setLocalWithClockType('Digital')
    setLocalWithTimeFormat(clockTimeFormat)
  }

  const handleClose = () => {
    setVisible(false)
    setPickListSourceData(currentPickListSourceData)
    setPickListTargetData(currentPickListTargetData)
    setFilteredTargetData(currentPickListTargetData)
  }

  const handleSourceFilterChange = (event) => {
    const filterValue = event.value.toLowerCase()
    let filteredData

    if (filterValue.trim() === '') {
      filteredData = pickListSourceData
    } else {
      filteredData = pickListSourceData.filter((item) => item.countryName.toLowerCase().includes(filterValue))
    }

    if (filteredData.length === 0 && pickListSourceData.length > 0) {
      filteredData.push({ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') })
    }

    setFilteredSourceData(filteredData)
  }

  const handleTargetFilterChange = (event) => {
    const filterValue = event.value.toLowerCase()
    let filteredData

    if (filterValue.trim() === '') {
      filteredData = pickListTargetData
    } else {
      filteredData = pickListTargetData.filter((item) => item.countryName.toLowerCase().includes(filterValue))
    }

    if (filteredData.length === 0 && pickListTargetData.length > 0) {
      filteredData.push({ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') })
    }

    setFilteredTargetData(filteredData)
  }

  const footerContent = (
    <>
      <Divider className="divider-style" />
      <div className="dialog-button-container">
        <Button buttonType="CLOSE" onButtonClick={handleClose} />
        <Button buttonType="SAVE" onButtonClick={postFavoriteCountry} />
      </div>
    </>
  )

  const headerElement = (
    <div className="dialog-header-container">
      <span className="clock-heading  white-space-nowrap">{t('CLOCK_SETTINGS')}</span>
      <div className="close-button-wrapper">
        <i className="pi pi-times-circle close-icon" onClick={() => setVisible(false)} />
      </div>
    </div>
  )

  return (
    <div className="clock-settings-container" data-testid="clock-setting-main-container">
      <Dialog visible={visible} modal header={headerElement} closable={false} footer={footerContent} onHide={handleClose} className="clock-settings-dialog" data-testid="dialog-modal">
        <Divider className="divider-header" />

        <div className="clock-type-wrapper">
          <div className="clock-type-title">{t('SELECT_TYPE')}</div>
          <div className="select-button-wrapper">
            <SelectButton value={selectedClockType} onChange={onClockTypeChange} options={clockType} className="select-button" allowEmpty={false} data-testid="analog-select-button" />
          </div>

          {selectedClockType === 'Digital' && (
            <div className="time-format-toggle">
              <div className="time-format-label">{t('SELECT_TIME_FORMAT')}</div>
              <div className="time-format-select-button">
                <SelectButton value={selectedTimeFormat} onChange={onClockFormatChange} options={timeFormat} className="select-button" allowEmpty={false} data-testid="digital-select-button" />
              </div>
            </div>
          )}
        </div>
        <div className="my-favorite-wrapper">
          <div className="country-pick-list-title">{t('FAVORITE_CLOCK')}</div>
          <div className="country-pick-list-container">
            <PickList dataKey="countryId" source={filteredSourceData.length > 0 ? filteredSourceData : [{ countryId: 'noData', countryName: t('NO_COUNTRY_AVAILABLE') }]} target={filteredTargetData} showSourceControls={false} showTargetControls={true} onChange={onChange} itemTemplate={pickListItemTemplate} filter filterBy="countryName" sourceHeader={t('AVAILABLE_COUNTRIES')} targetHeader={t('SELECTED_COUNTRIES')} sourceFilterPlaceholder={t('SEARCH_BY_NAME')} targetFilterPlaceholder={t('SEARCH_BY_NAME')} onSourceFilterChange={handleSourceFilterChange} onTargetFilterChange={handleTargetFilterChange} pt={ptProps} data-testid="clock-setting-pickList" />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ErrorMessageModal(ClockSettings)
