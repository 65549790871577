import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

const TraderWiseComparison = ({ selectedMarket, leftDivRef, rightDivRef, scrollFunction, setLoading, mockState, mockFetchedData }) => {
  const { t } = useTranslation()
  const { robustaTraderWiseComparison, arabicaTraderWiseComparison } = coffeeReportJsonLinks
  const { dateFormatBasedOnUser, checkForUserAccess } = commonMethods
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [fetchedData, setFetchedData] = useState([])

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)
  // testing purpose

  useEffect(() => {
    if (mockState) {
      setFetchedData(mockFetchedData)
    }
  }, [mockState])

  useEffect(() => {
    fetchData()
  }, [selectedMarket])

  useEffect(() => {
    const cleanUp = scrollFunction() || (() => {})

    return cleanUp
  }, [])
  const fetchData = async () => {
    setLoading(true)

    const reportName = selectedMarket === 'Robusta' ? robustaTraderWiseComparison : arabicaTraderWiseComparison

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredRowIndex(null)
  }

  return (
    <div className="table-content" id="trader-wise-content" data-testid="trade-wise-table-content">
      <div className="table-content-left" ref={leftDivRef} data-testid="table-content-left-data">
        <table>
          <thead>
            <tr className="empty-header-row" data-testid="empty-header-row">
              <th colSpan={4} style={{ background: 'white' }}></th>
            </tr>
            <tr className="table-content-left-header" data-testid="table-content-left-header">
              <th>{t('DATES_UPPERCASE')}</th>
              <th>{t('TERMINAL_LEVEL_UPPERCASE')}</th>
            </tr>

            <tr className="blank-row"></tr>

            <tr className="empty-row" style={checkForUserAccess(menuLock)}>
              <th colSpan={4}></th>
            </tr>

            <tr className="blank-row"></tr>
          </thead>

          <tbody style={checkForUserAccess(menuLock)}>
            {fetchedData?.terminalDetails?.map((item, index) => (
              <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                <td data-testid="date">{dateFormatBasedOnUser(item?.date)}</td>
                <td data-testid="terminal-level">{item?.terminalLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-content-right" ref={rightDivRef} data-testid="table-right-content">
        <table>
          <thead>
            <tr className="header-row-top" data-testid="header-row">
              <th colSpan={4} style={{ background: 'white' }}></th>
            </tr>

            <tr className="table-header-main" data-testid="table-header">
              <th>{t('TOTAL_TRADERS').toUpperCase()}</th>
              <th>{t('NET_CHANGE').toUpperCase()}</th>
            </tr>
            <tr className="blank-row"></tr>

            <tr className="header-row-bottom" style={checkForUserAccess(menuLock)}>
              <th colSpan={4}></th>
            </tr>
            <tr className="blank-row"></tr>
          </thead>

          <tbody style={checkForUserAccess(menuLock)}>
            {fetchedData?.terminalDetails?.map((item, index) => (
              <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                <td data-testid="total-traders" className="light-purple">
                  {item?.totalTraders.toLocaleString()}
                </td>
                <td className={`${item?.netChange >= 0 ? 'positive-color' : 'negative-color'}`} data-testid="net-change">
                  {item?.netChange?.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {fetchedData?.details?.map((data, index) => (
          <table key={index}>
            <thead>
              <tr className="table-title-row" data-testid="table-title">
                <th data-testid="data-label" style={{ background: 'white' }} colSpan={data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS' ? 7 : 6}>
                  {data?.label}
                </th>
              </tr>
              <tr className="table-content-title-row" data-testid="arabica-table-content-title-row">
                <th className="positive-label">{t('LONG')}</th>
                <th className="negative-label">{t('SHORT')}</th>
                <th>{t('TOTAL').toUpperCase()}</th>
                <th className="positive-label">{t('PERCENTAGE_LONG')}</th>
                <th className="negative-label">{t('PERCENTAGE_SHORT')}</th>
                <th>{t('NET')}</th>
                {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <th className="gold-label">{t('SPREADING')}</th>}
              </tr>
              <tr className="blank-row"></tr>

              <tr style={checkForUserAccess(menuLock)}>
                <th className="positive-label" data-testid="positive-label">
                  {data?.long?.toLocaleString()}
                </th>
                <th className="negative-label" data-testid="negative-labels">
                  {data?.short?.toLocaleString()}
                </th>
                <th data-testid="total-data">{data?.total?.toLocaleString()}</th>
                <th className="positive-label" data-testid="long-percentage">
                  {data?.percentLong?.toFixed(2)}
                </th>
                <th className="negative-label" data-testid="short-percentage">
                  {data?.percentShort?.toFixed(2)}
                </th>
                <th data-testid="net-data">{data?.net}</th>
                {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <th className="gold-label">{data?.spreading?.toLocaleString()}</th>}
              </tr>

              <tr className="blank-row"></tr>
            </thead>
            <tbody style={checkForUserAccess(menuLock)}>
              {data?.data?.map((item, index) => (
                <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === index ? 'highlighted' : ''}>
                  <td className="positive-label" data-testid="long-item">
                    {item?.long.toLocaleString()}
                  </td>
                  <td className="negative-label" data-testid="short-item">
                    {item?.short.toLocaleString()}
                  </td>
                  <td>{item?.total.toLocaleString()}</td>
                  <td className="positive-label">{item?.percentLong?.toFixed(2)}</td>
                  <td className="negative-label">{item?.percentShort?.toFixed(2)}</td>
                  <td data-testid="net-item">{item?.net.toLocaleString()}</td>
                  {(data?.label === 'FUND BUYERS' || data?.label === 'SWAP DEALERS' || data?.label === 'OTHER REPORTABLE' || data?.label === 'FUND BUYERS') && <td className="gold-label">{item?.spreading.toLocaleString()}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  )
}

export default TraderWiseComparison
